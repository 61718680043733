import React, { useEffect } from "react"
import store from "../store"
import { updateUserPermissions } from "../actions/login"
import { getUserPermissions } from "../utilities/api/admin-api"
import { getUserInfo } from "../utilities/api/userInfo"
import { ProgressCircle } from "@adobe/react-spectrum"

const LoginCallback = () => {
    useEffect(() => {
        getUserInfo()
            .then((res) => {
                if (res.userid) {
                    getUserPermissions(res).then((response) => {
                        store.dispatch(updateUserPermissions(response))
                        return (location.href = "/menu")
                    })
                }
            })
            .catch((err) => {
                console.log("Error from call ", err)
            })
    }, [])

    return (
        <div
            style={{
                paddingTop: "15%",
                paddingLeft: "45%",
                position: "absolute",
                zIndex: "10",
            }}
        >
            <ProgressCircle aria-label="Loading…" isIndeterminate size="L" />
        </div>
    )
}

export default LoginCallback
