import React, { useState, useRef } from "react";
import {
    Provider,
    lightTheme,
    Dialog,
    ActionButton,
    DialogTrigger,
    Content,
    Flex,
    View,
    Button,
    ProgressCircle
} from "@adobe/react-spectrum";

// import {Draw,FileText} from '@spectrum-icons/workflow';
import FileExcel from '@spectrum-icons/workflow/FileExcel';
import Delete from '@spectrum-icons/workflow/Delete';

import UploadToCloud from '@spectrum-icons/workflow/UploadToCloud';

const UploadFilesXlsx = (props) => {
    const [dragActive, setDragActive] = useState(false);
    const [selectedFile, setSelectedFile] = useState();
    const [isLoading, setIsLoading] = useState(false);
    // ref
    const inputRef = useRef(null);

    // handle drag events
    const handleDrag = function (e) {
        e.preventDefault()
        e.stopPropagation()
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true)
        } else if (e.type === "dragleave") {
            setDragActive(false)
        }
    }

    // triggers when file is dropped
    const handleDrop = function (e) {
        e.preventDefault()
        e.stopPropagation()
        setDragActive(false)
        if (e.dataTransfer.files && e.dataTransfer.files[0] && e.dataTransfer.files[0].name.includes(".xlsx")) {
            const [file] = e.dataTransfer.files;
            console.log(file.name);
            setSelectedFile(file);
        }
    }

    // triggers when file is selected with click
    const handleChange = function (e) {
        e.preventDefault();
        if (inputRef.current.files && inputRef.current.files[0]) {
            const [file] = inputRef.current.files;
            setSelectedFile(file);
        }
    }

    const onCloseHandler = () => {
        console.log('close Handler');
        props.handleCloseDialog(true);
    }
    // triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click();
    }

    const humanFileSize = (size) => {
      var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
      return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
  }

  const sendFile = () => {
    setIsLoading(true);
    props.handleFileUpload(true, selectedFile);
  };

  const onDownloadTemplateClick = () => {
    console.log("inside download");
    props.handleDownloadTemplate();


  }
    return (
        <Provider theme={lightTheme}>
            <div>
                <DialogTrigger
                    type="modal"
                    mobileType="modal"
                    isOpen={props.isDialogOpened}
                    defaultOpen={false}
                >
                    <ActionButton isHidden isQuiet></ActionButton>
                    <Dialog size="L">
                        <Content>
                            <form 
                                id="form-file-upload"
                                onDragEnter={handleDrag}
                                onSubmit={(e) => e.preventDefault()}
                            >
                                <input
                                    ref={inputRef}
                                    accept=".xlsx"
                                    type="file"
                                    id="input-file-upload"
                                    multiple={false}
                                    onChange={handleChange}
                                />
                            {selectedFile && (
                              <>
                              <Flex direction={'row'}  UNSAFE_className="dotttedBox">
                              <Flex direction={'row'} width={'60%'}>
                                <View>
                                <FileExcel aria-label="XXL Beaker" size="XL" />
                                </View>
                                <View>
                                  <Flex direction={'column'} alignItems={'start'}>
                                    <View>
                                    <strong>{selectedFile.name}</strong>
                                    </View>
                                    <View>
                                    {humanFileSize(selectedFile.size)}
                                    </View>
                                  </Flex>
                                </View>
                              </Flex>
                              <Flex direction={'row'} gap={'size-100'} justifyContent={'end'}>
                                {/* <Button onPress={()=>{sendFile()}}><UploadToCloud /></Button> */}
                                <Button onPress={()=>{setSelectedFile()}} variant="negative" style="fill"><Delete /></Button>
                              </Flex>
                              </Flex>
                              </>
                              )}
                                {! selectedFile && (
                                  <label
                                    id="label-file-upload"
                                    htmlFor="input-file-upload"
                                    className={dragActive ? "drag-active" : ""}
                                >
                                    <div>
                                        <p>Drag and drop your file here or</p>
                                        <button
                                            className="upload-button"
                                            onClick={onButtonClick}
                                        >
                                            Upload a file
                                        </button>
                                        <span> Or </span>
                                        <button
                                            className="upload-button"
                                            onClick={onDownloadTemplateClick}
                                        >
                                            Download Template
                                        </button>
                                    </div>
                                    </label>
                                )}
                                
                                {dragActive && (
                                    <div
                                        id="drag-file-element"
                                        onDragEnter={handleDrag}
                                        onDragLeave={handleDrag}
                                        onDragOver={handleDrag}
                                        onDrop={handleDrop}
                                    ></div>
                                )}
                            </form>
                            <Flex marginTop={'size-100'} justifyContent={'end'}>
                              <Button variant="secondary" onPress={onCloseHandler}>Cancel</Button>
                              <Button isDisabled={!selectedFile || isLoading} onPress={()=>{sendFile()}}><UploadToCloud /> Upload {isLoading && (<ProgressCircle size='S' aria-label="Loading…" isIndeterminate />)}</Button>
                            </Flex>
                        </Content>
                    </Dialog>
                </DialogTrigger>
            </div>
        </Provider>
    )
}

export default UploadFilesXlsx
