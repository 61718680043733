import React from 'react';
import {
    ComboBox, TableView, TableHeader, Column, TableBody, Cell,
    Row, lightTheme, Provider, View, Flex
} from "@adobe/react-spectrum";

const AlertServiceSearch = () => {

    return (
        <Flex direction="column">
            <View UNSAFE_style={{ padding: '12px 0' }} >
                <ComboBox
                    label="Queue DropDown"
                    isQuiet
                >
                </ComboBox>
            </View>
            <div style={{ textAlign: 'center', fontSize: '20px', fontWeight: '700', marginBottom: '20px' }} >Alert - Services</div>
            <Provider theme={lightTheme}>
                <TableView aria-label="spam contacts">
                    <TableHeader>
                        <Column>Name</Column>
                        <Column>Type</Column>
                        <Column align="end">Date Modified</Column>
                    </TableHeader>
                    <TableBody>
                        <Row>
                            <Cell>Games</Cell>
                            <Cell>File folder</Cell>
                            <Cell>6/7/2020</Cell>
                        </Row>
                        <Row>
                            <Cell>Program Files</Cell>
                            <Cell>File folder</Cell>
                            <Cell>4/7/2021</Cell>
                        </Row>
                    </TableBody>
                </TableView>
            </Provider>
        </Flex>
    )
}

export default AlertServiceSearch;