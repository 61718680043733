import React, { useState, useEffect } from 'react';
import {
    Provider, lightTheme, Dialog, Button, ActionButton, DialogTrigger, Divider,
    Heading, Content, ButtonGroup, Form, TextField, ListView, Item, View, ActionMenu, Text
} from '@adobe/react-spectrum';
import { getRoles } from '../utilities/api/admin-api';

const AddUserDialog = (props) => {
    const [roleList, setRoleList] = useState([])
    const [username, setUsername] = useState('');
    const [user, setUser] = useState('');
    const [roleIds, setRoleIds] = useState(new Set());

    const getAllRoles = () => {
        getRoles()
            .then((res) => {
                if (res.length > 0) {
                    var roles = [];
                    res.forEach((role) => {
                        roles.push({
                            id: role.id,
                            name: role.name,
                            permissions: role.permissions
                        });

                    });
                    setRoleList(roles);
                }
            })
            .catch((error) => {
                console.log('No roles found ' + error);
            });
    }

    useEffect(() => {
        getAllRoles();
        if (props.editData) {
            props.editData.roles.forEach(role => {
                setRoleIds(prev => new Set(prev.add(role.id)))
            });

            setUsername(props.editData.username);
            setUser(props.editData);
        }
    }, []);

    const getPermissions = (permissions) => {
        const items = [(<Item key="permissions" textValue="permissions">
            <Text><b>Permissions</b></Text>
        </Item>)]

        permissions.forEach(perm => {
            items.push(<Item key={perm} textValue={perm}>
                <Text>{perm}</Text>
            </Item>)
        }
        );

        return items;
    }


    const onCloseHandler = () => {
        props.handleCloseDialog(false, null, false);
    }


    const onSaveHandler = () => {

        if (props.operation == "Add User"
            && props.allUsers.filter(u => u.username.trim() == username.trim()).length > 0) {
            props.handleCloseDialog(false, null, true);
        }
        else {
            var roleIdArr = [];
            roleIds.forEach((roleId) => {
                roleIdArr.push(roleId);
            });

            var urlencoded = new URLSearchParams();
            urlencoded.append("id", user.id);
            urlencoded.append("username", username);
            urlencoded.append("updatedRoleIds", roleIdArr);

            props.handleCloseDialog(true, urlencoded, false);
        }
    }


    return (

        <Provider theme={lightTheme}>
            <div>
                <DialogTrigger
                    type="modal"
                    mobileType="modal"
                    isOpen={props.isDialogOpened}
                    defaultOpen={false}>
                    <ActionButton isHidden isQuiet></ActionButton>
                    <Dialog size="S">
                        <Heading>{props.operation}</Heading>
                        <Divider />
                        <Content>

                            <Form isRequired>
                                <View>Userame</View>
                                <TextField autoFocus
                                    value={username}
                                    onChange={setUsername}
                                    isQuiet
                                />

                                <div style={{ marginTop: '30px' }}></div>
                                {roleList && (
                                    <>
                                        <View>Roles</View>
                                        <ListView
                                            items={roleList}
                                            defaultSelectedKeys={roleIds}
                                            selectedKeys={roleIds}
                                            selectionMode="multiple"
                                            density="compact"
                                            onSelectionChange={setRoleIds}
                                            isQuiet
                                        >
                                            {(item) => (
                                                <Item key={item.id} textValue={item.name}>
                                                    <Text>{item.name}</Text>
                                                    <ActionMenu>
                                                        {getPermissions(item.permissions)}
                                                    </ActionMenu>
                                                </Item>
                                            )}
                                        </ListView>
                                    </>
                                )}

                            </Form>
                        </Content>
                        <ButtonGroup>
                            <Button variant="warning" onPress={onCloseHandler}>Cancel</Button>
                            <Button variant="primary" onPress={onSaveHandler} isDisabled={username.length === 0
                                || roleIds.size === 0}>Save</Button>
                        </ButtonGroup>
                    </Dialog>
                </DialogTrigger>
            </div>
        </Provider>
    );

};

export default AddUserDialog;