import React, { useEffect, useState, useRef } from 'react';
import Delete from '@spectrum-icons/workflow/Delete';
import Draw from '@spectrum-icons/workflow/Draw';
import 'react-schedule-job/dist/index.css'
import '../index.css';
import {
    Flex, SearchField, ActionGroup, Item, Text,
    Content, IllustratedMessage, Badge, Heading, TableView, TableHeader,
    Column, TableBody, Cell, Row
} from '@adobe/react-spectrum';
import DeleteDialog from './deleteDialog';
import { deleteRoles, getRoles, isRoleActive, saveRole } from '../utilities/api/admin-api';
import AddRoleDialog from './AddRoleDialog';
import { connect } from 'react-redux';
import ErrorDialog from './ErrorDialog';
import { notify } from '../constants/constants';
import { renderEmptyState } from '../utilities/components/CommonUseComponents';

function mapStateToProps(state) {
    return {
        user: state.login.userInfo,
    };
}

const RoleManagement = (props) => {
    const [allRoles, setAllRoles] = useState([]);
    const [filteredRoles, setFilteredRoles] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [currentText, setCurrentText] = useState('');
    const [selectedRoles, setSelectedRoles] = useState(new Set());
    const [operation, setOperation] = useState('');
    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
    const [isdeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [showError, setShowError] = useState(false);

    const columns = [
        { name: 'Name', key: 'name' },
        { name: 'Permissions', key: 'permissions' },
        { name: 'Organizations', key: 'organizations' },
        { name: 'Divisions', key: 'divisions' },
        { name: 'Description', key: 'description' },
        { name: 'Actions', key: 'actions' }
    ];

    const currentRoleSelected = useRef('');
    const editData = useRef(false);
    const isLoading = useRef(false);

    function getAllRolesData() {
        isLoading.current = true;
        getRoles()
            .then((roles) => {
                isLoading.current = false;
                setAllRoles(roles);
                setFilteredRoles(roles)

            }).catch((error) => {
                isLoading.current = false;
                console.log(error)
                setErrorMessage('Error occured!');
            })
    }


    useEffect(() => {
        getAllRolesData();
    }, []);

    const getPermissionBadge = (permissionList) => {
        const badges = [];
        permissionList.forEach(element => badges.push((<Badge aria-label={element} key={element} variant="neutral">{element}</Badge>)))
        return badges;
    }

    const getOrganizationsBadge = (orgs) => {
        const orgBadges = [];
        orgs.forEach(element => orgBadges.push((<Badge aria-label={element.name} key={element.name} variant={element.badgeVariant}>{element.name}</Badge>)));
        return orgBadges;
    }

    const getDivisionsBadge = (divs, orgs) => {
        const divisionBadges = [];
        divs.forEach(division => {
            const divBadgecolor = orgs.filter(org => org.id == division.orgId).map(org => org.badgeVariant)
            divisionBadges.push((<Badge aria-label={division.name} key={division.name} variant={divBadgecolor}>{division.name}</Badge>))
        });
        return divisionBadges;
    }

    const getPermissions = (permissionList) => {
        return permissionList.join(',');
    }


    const getOrganizations = (orgs) => {
        const orgNames = [];
        orgs.forEach(element => {
            orgNames.push(element.name);
        });
        return orgNames.join(',');
    }

    const getDivisions = (divs) => {
        const divNames = [];
        divs.forEach(element => {
            divNames.push(element.name);
        });
        return divNames.join(',');
    }

    const filterFunction = (value) => {
        if(allRoles.length){
            const filteredRoles = allRoles.filter(role =>
                role.name.toLowerCase().includes(value.toLowerCase()) ||
                getOrganizations(role.orgs).toLowerCase().includes(value.toLowerCase()) ||
                getDivisions(role.divisions).toLowerCase().includes(value.toLowerCase()) ||
                getPermissions(role.permissions).toLowerCase().includes(value.toLowerCase()) ||
                role.description.toLowerCase().includes(value.toLowerCase())
            )
            setFilteredRoles(filteredRoles);
        }
    }

    useEffect(()=>{
        if(!filteredRoles.length)
        setFilteredRoles(allRoles)
        console.log({filteredRoles});
    },[filteredRoles])

    useEffect(()=>{
        const timeOut = setTimeout(() => {
            if(currentText)
            filterFunction(currentText);
            else
            setFilteredRoles(allRoles);
        }, 500);
        return (()=>clearTimeout(timeOut));
    },[currentText])

    const filterRoles = (value) => {
        setCurrentText(value);
    }


    const performAction = (value, role) => {
        switch (value) {
            case 'add':
                editData.current = null;
                setOperation("Add Role");
                setIsAddDialogOpen(true);
                break;
            case 'edit':
                editData.current = allRoles.find((r) => r.name == role.name);
                setOperation("Edit Role");
                setIsAddDialogOpen(true);
                break;
            case 'delete':
                currentRoleSelected.current = role.id;
                setIsDeleteDialogOpen(true);
                break;
            case 'bulkDelete':
                currentRoleSelected.current = role;
                setIsDeleteDialogOpen(true);
                break;
        }
    }

    function deleteSelectedRoles(rolesToDelete) {
        isLoading.current = true;
        deleteRoles(rolesToDelete, props.user.userid)
            .then((res) => {
                isLoading.current = false;
                if (res.error)
                    setErrorMessage(res.error);
                else
                    getAllRolesData();
                setSelectedRoles(new Set());

            }).catch((error) => {
                isLoading.current = false;
                console.log(error)
                setErrorMessage('Error occured!');
            })
    }

    function isSelectedRoleActive(rolesToDelete) {
        isLoading.current = true;
        isRoleActive(rolesToDelete)
            .then((res) => {
                if (res === true) {
                    isLoading.current = false;
                    setErrorMessage('Selected Role is active');
                    window.scrollTo(0, document.body.scrollHeight);
                } else {
                    deleteSelectedRoles(rolesToDelete);
                }
            }).catch((error) => {
                isLoading.current = false;
                console.log(error)
                setErrorMessage('Error occured!');
                window.scrollTo(0, document.body.scrollHeight);
            })

    }

    const closeDeleteDialog = (isDeletedConfirmed) => {
        setIsDeleteDialogOpen(false);
        if (isDeletedConfirmed) {
            let rolesToDelete = [];
            if (currentRoleSelected.current instanceof Set) {
                currentRoleSelected.current.forEach((roleId) => rolesToDelete.push(roleId));
            }
            else {
                rolesToDelete = [currentRoleSelected.current];
            }
            if (selectedRoles == 'all') {
                rolesToDelete = allRoles.map(role => role.name)
            }

            isSelectedRoleActive(rolesToDelete);
        }
    }


 

    const closeAddRoleDialog = (isAddConfirmed, roleData,error) => {
        setIsAddDialogOpen(false);
        setShowError(error);
        if (isAddConfirmed) {
            isLoading.current = true;

            saveRole(roleData, props.user.userid)
                .then((res) => {
                    isLoading.current = false;
                    if (res) {
                        notify('Permissions updated successfully','success');
                        getAllRolesData();
                    }
                }).catch((error) => {
                    isLoading.current = false;
                    notify('error', 'Error occured');
                    console.log("add error", error)
                })
        }
    }

    const getCell = (columnKey, item) => {
        switch (columnKey) {
            case 'actions':
                return (
                    <Cell>
                        <ActionGroup onAction={(value) => performAction(value, item)}>
                            <Item key="edit">
                                <Draw />
                            </Item>
                            {(item.id != '1' && item?.name !== 'Amazon_Connect_Supervisor') && (
                                <Item key="delete">
                                    <Delete />
                                </Item>
                            )}
                        </ActionGroup>
                    </Cell>
                );
            case 'permissions':
                return (
                    <Cell>
                        <Flex direction="row" maxWidth={500} gap={8} wrap="wrap">
                            {getPermissionBadge(item['permissions'])}
                        </Flex>
                    </Cell>
                )
            case 'organizations':
                return (
                    <Cell>
                        <Flex direction="row" maxWidth={500} gap={8} wrap="wrap">
                            {getOrganizationsBadge(item['orgs'])}
                        </Flex>
                    </Cell>
                )
            case 'divisions':
                return (
                    <Cell>
                        <Flex direction="row" maxWidth={500} gap={8} wrap="wrap">
                            {getDivisionsBadge(item['divisions'], item['orgs'])}
                        </Flex>
                    </Cell>
                )
            default:
                return (
                    <Cell>{item[columnKey]}</Cell>
                );
        }
    }

    const closeErrorDialog = () => {
        setShowError(false);
    }

    return (
        <>
            <div align="center">
                <Heading variant="pageTitle">Care Roles Details</Heading>
            </div>

            <div
                style={{
                    padding: '25px',
                    border: '0.8px solid',
                    borderRadius: '0.7em',
                    marginTop: '30px',
                }}
            >
                    <Flex direction="row" gap="size-100" UNSAFE_className="searchMobile">
                        <SearchField isQuiet
                            onClear={() => setCurrentText('')}
                            onChange={filterRoles}
                            aria-label="Search"
                            value={currentText}
                            width={'20%'}
                        />
                        <ActionGroup width={'80%'} alignItems="end" onAction={(value) => performAction(value, selectedRoles)} disabledKeys={(selectedRoles.size || selectedRoles == 'all') ? [] : ['bulkDelete']}>
                            <Item key="add">+ Add</Item>
                            <Item key="bulkDelete" ><Delete /><Text>Delete</Text></Item>
                        </ActionGroup>


                    </Flex>

                    <TableView aria-label="Care Roles"
                        selectionMode="multiple"
                        selectedKeys={selectedRoles}
                        onSelectionChange={setSelectedRoles}
                        height={'45vh'}
                        renderEmptyState={renderEmptyState}
                        density="spacious"
                        overflowMode="wrap"
                        disabledKeys={['1']}
                    >

                        <TableHeader columns={columns}>
                            {(column) => (
                                <Column key={column.key} align={column.key === 'actions' ? 'end' : 'start'}
                                    minWidth={['permissions'].indexOf(column.key) > -1 ? '40%' : '10%'}>
                                    {column.name}
                                </Column>
                            )}
                        </TableHeader>
                        <TableBody items={filteredRoles} loadingState={isLoading.current ? 'loading' : ''}>
                            {item => (
                                <Row key={item.id}>
                                    {(columnKey) => getCell(columnKey, item)}
                                </Row>
                            )}
                        </TableBody>
                    </TableView>
                {isdeleteDialogOpen && <DeleteDialog
                    isDialogOpened={isdeleteDialogOpen}
                    handleCloseDialog={(isDeletedConfirmed) => closeDeleteDialog(isDeletedConfirmed)}
                    entity="Role"
                />}
            </div>


            {errorMessage && (<div
                style={{
                    fontSize: 'large', color: 'red', marginTop: '1%',
                }}
                align="center"
            >
                {' '}
                <b> {errorMessage}</b>
            </div>)}
            {isAddDialogOpen && <AddRoleDialog
                isDialogOpened={isAddDialogOpen}
                editData={editData.current}
                handleCloseDialog={(isAddConfirmed, roleData, error) => closeAddRoleDialog(isAddConfirmed, roleData, error)}
                operation={operation}
                allRoles={allRoles}
            />
            }

            {showError &&
                <ErrorDialog
                    isDialogOpened={showError}
                    handleCloseDialog={() => closeErrorDialog()}
                    msg="Role already exists"
                />
            }
        </>
    );
}

export default connect(mapStateToProps)(RoleManagement);
