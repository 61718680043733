import React from "react"
import { Item, TabList, TabPanels, Tabs, Well } from "@adobe/react-spectrum"
import { connect } from "react-redux"
import ServiceTestList from "./ServiceTestData/ServiceTestList"
import SRSProductFamily from "./SRSProductFamily/SRSProductFamily"
import SRSRules from "./SRSRules/SRSRules"
import CaseHistory from "./CaseHistory/CaseHistory"
import { SRSRoles } from "../constants/constants"

function mapStateToProps(state) {
    return {
        user: state.login.userInfo,
        permissions:
            state.login.authorizedAccess != null
                ? state.login.authorizedAccess.permissions
                : [],
    }
}

const SRS = ({ user, permissions }) => {
    // const [agentList, setAgentList] = useState([]);

    // const getAgentGroupList = async (ldap) => {
    //     const response = await getAllAgentGroupList(ldap);
    //     const updatedAgentGroupStructure = response.map((agent)=>{
    //         const {agent_ldap} = agent;
    //         const agentGroupList = new Set(response.filter((subagent) => subagent.agent_ldap == agent_ldap).map((subagent)=>{return{groupName:subagent.agent_group_name, groupActiveStatus:subagent.is_agent_profile,first_name:subagent.first_name,last_name:subagent.last_name}}));
    //          return {agent_ldap, agentGroupList: Array.from(agentGroupList)}
    //     })
    //     const uniqueAgentList = [...new Map(updatedAgentGroupStructure.map(item => [item.agent_ldap, item])).values()];
    //     setAgentList(uniqueAgentList);
    // };

    // useEffect(()=>{
    //     getAgentGroupList(user.userid);
    //     console.log({permissions});
    // },[]);

    return (
        <Well
            UNSAFE_style={{
                minHeight: "75vh",
                marginRight: "1%",
                marginLeft: "1%",
            }}
        >
            <Tabs aria-label="Audit Reports">
              {permissions?.length &&
                <TabList>
                    {(permissions.includes(SRSRoles.caseHistory) ||
                        permissions.includes(SRSRoles.caseHistoryAdmin)) && (
                        <Item key={"caseHistory"}>CASE HISTORY</Item>
                    )}

                    {(permissions.includes(SRSRoles.srsRules) ||
                        permissions.includes(SRSRoles.srsRulesAdmin)) && (
                        <Item key="srsRule">SRS RULE</Item>
                    )}
                    {(permissions.includes(SRSRoles.srsProductFamily) ||
                        permissions.includes(
                            SRSRoles.srsProductFamilyAdmin
                        )) && <Item key="srsProd">SRS PRODUCT FAMILY</Item>}
                    {(permissions.includes(SRSRoles.systemTestData) ||
                        permissions.includes(SRSRoles.systemTestDataAdmin)) && (
                        <Item key={"systemTestData"}>SYSTEM TEST DATA</Item>
                    )}
                </TabList>}
                <TabPanels>
                    <Item key={"caseHistory"}>
                        <CaseHistory />
                    </Item>
                    <Item key="srsRule">
                        <SRSRules />
                    </Item>
                    <Item key={"srsProd"}>
                        <SRSProductFamily />
                    </Item>
                    <Item key={"systemTestData"}>
                        <ServiceTestList user={user.userid} />
                    </Item>
                </TabPanels>
            </Tabs>
        </Well>
    )
}

export default connect(mapStateToProps)(SRS)
