import React, {  } from 'react';
import { Item, 
     TabList, TabPanels, Tabs, Well  } from "@adobe/react-spectrum";
import AlertServiceSearch from './AlertServiceSearch';
import MonitorQueueList from './MonitorQueueList';
import EditMonitorQueueList from './EditMonitorQueue';
import AlertServiceList from '../AlertServiceNew/AlertServiceList';
import { connect } from 'react-redux';

function mapStateToProps(state) {
  return {
    user: state.login.userInfo,
    permissions: (state.login.authorizedAccess != null) ? state.login.authorizedAccess.permissions : []
  };
}

const AlertService = () => {
//   const QueueAlertItem = {
//     "queueId": "0635cfa1-a019-4055-92d2-ea1fedd3f501",
//     "queueName": "",
//     "alertTypes": {
//         "convWaitConditions": "60",
//         "convWait": true,
//         "voiceMail": true,
//         "onQueueUsers": true,
//         "callAbandoned": true,
//         "userThresholdSchedules": [
//             {
//                 "timezone": "IST",
//                 "days": "all",
//                 "startTime": "all",
//                 "endTime": "all",
//                 "onQueueUserThreshold": 0
//             }
//         ]
//     },
//     "slackWebhook": "",
//     "emailRecipients": "",
//     "updatedBy": "",
//     "updatedDate": ""
// };
  
    return (<>
        <Well
        style={{
          minHeight: '75vh',
          marginRight: '1%',
          marginLeft: '1%',
        }}
      >
        <Tabs aria-label="Complaince Reports">
          <TabList>
            <Item key="create">MONITORED QUEUES</Item>           
          </TabList>
          <TabPanels>
            <Item key="create">
                <AlertServiceList/>
            </Item>
            <Item key="search">
              <AlertServiceSearch />
            </Item>
            <Item key="form">
              <EditMonitorQueueList queue={{}}/>
            </Item>
          </TabPanels>
        </Tabs>
      </Well>
      </>
    )
}

export default connect(mapStateToProps)(AlertService);