import { Item, TabList, TabPanels, Tabs } from "@adobe/react-spectrum"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import store from "../store"
import AdminOperations from "../AdminConsole/AdminOperations"
import Config from "../purecloudReport/Config"
import AmazonConnect from "../Phoenix/AmazonConnect"
import AlertService from "../AlertService/AlertService"
import SRS from "../SRS/SRS"
import { updateUserInfo, updateUserPermissions } from "../actions/login"
import { getUserPermissions } from "../utilities/api/admin-api"
import { getUserInfo } from "../utilities/api/userInfo"
import { getLoggedInUserIsAdmin } from "../utilities/api/phoenix-api"

function mapStateToProps(state) {
    return {
        login: state.login,
        permissions: state?.login?.authorizedAccess?.permissions,
    }
}

const UtilComponent = (props) => {
    const SRSpermissions = [
        "Case History",
        "Case History Admin",
        "System Test Data Admin",
        "System Test Data",
        "SRS Product Family Data Admin",
        "SRS Product Family Data",
        "SRS Rules",
        "SRS Rules Admin",
    ]

    const pheonixPermissions = [
        "Agent Group Mapping",
        "Agent Group Mapping Admin",
        "Agent Group Config Mapping",
        "Agent Group Config Mapping Admin",
        "Default Config Mapping",
        "Default Config Mapping Admin",
        "Voice Compliance",
        "Voice Compliance Admin",
        "Attribute List Mapping",
        "Attribute List Mapping Admin",
        "Timeout",
        "Timeout Admin",
        "Ani Bypass",
        "Ani Bypass Admin",
        "Holiday Schedules",
        "Holiday Schedules Admin",
        "Emergency",
        "Emergency Admin",
        "Dx Prmopts",
        "Dx Prmopts Admin",
        "Dnis",
        "Dnis Admin",
        "Did Magento",
        "Did Magento Admin",
        "Srs Mock Data",
        "Srs Mock Data Admin",
        "Spam Callers",
        "Spam Callers Admin",
        "Operational Hours",
    ]

    const alertPermissions = ["Alert Service", "Alert Service Admin"]

    const adminPermissions = ["Admin"]

    const user = props?.login?.authorizedAccess?.username
    const [tab, setTab] = useState(localStorage.getItem("tab"))
    const [isAdminInEdit, setIsAdminInEdit] = useState(false)

    const handletabChange = (value) => {
        localStorage.setItem("tab", value)
        setTab(value)
    }

    const hasSRSpermissions = SRSpermissions.some((permission) =>
        props?.permissions?.includes(permission)
    )

    const hasPheonixPermissions = pheonixPermissions.some((permission) =>
        props?.permissions?.includes(permission)
    )

    const hasAlertPermissions = alertPermissions.some((permission) =>
        props?.permissions?.includes(permission)
    )

    const hasAdminPermissions = adminPermissions.some((permission) =>
        props?.permissions?.includes(permission)
    )

    useEffect(() => {
        if (user) {
            getLoggedInUserIsAdmin(user)
                .then((resp) => {
                    setIsAdminInEdit(resp)
                })
                .catch((err) => console.log(err))
        }
    }, [user])

    useEffect(() => {
        getUserInfo(false)
            .then((res) => {
                store.dispatch(updateUserInfo(res))
                if (res.userid) {
                    getUserPermissions(res).then((response) => {
                        store.dispatch(updateUserPermissions(response))
                    })
                }
            })
            .catch((err) => {
                location.replace("/")
                console.log("Error from call ", err)
            })
    }, [])

    return (
        <React.Fragment>
            {props?.permissions && (
                <Tabs
                    aria-label="Tabs"
                    UNSAFE_style={{ paddingLeft: "1em" }}
                    selectedKey={tab}
                    onSelectionChange={handletabChange}
                >
                    <TabList>
                        {!props.permissions.length && (
                            <Item key="alert_service">ALERT</Item>
                        )}
                        {hasPheonixPermissions && (
                            <Item key="amazon_connect">AMAZON CONNECT</Item>
                        )}
                        {hasSRSpermissions && <Item key="srs">SRS</Item>}
                        {hasAlertPermissions && (
                            <Item key="alert_service">ALERT</Item>
                        )}
                        {hasAdminPermissions && <Item key="admin">ADMIN</Item>}
                    </TabList>
                    <TabPanels>
                        <Item key="amazon_connect">
                            <AmazonConnect isAdminInEdit={isAdminInEdit} />
                        </Item>
                        <Item key="alert_service">
                            <AlertService />
                        </Item>
                        <Item key="srs">
                            <SRS />
                        </Item>

                        <Item key="admin">
                            <AdminOperations />
                        </Item>
                    </TabPanels>
                </Tabs>
            )}
        </React.Fragment>
    )
}

export default connect(mapStateToProps)(UtilComponent)