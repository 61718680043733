/**
 * Implements a facade for fetch, to make things simpler during execution.
 * @param {String} url URL to resource
 * @param {String} method HTTP Method
 * @param {Object} queryParams Query Parameters, as a json Key Value Pair
 * @param {String} username Username for authentication
 * @param {String} password Password for authentication
 * @param {Number} successStatus HTTP Status code on a successful http request
 * @param {Object} json JSON Object Body for the request.
 */
function customFetch({
    url,
    method,
    queryParams,
    username,
    password,
    successStatus,
    json,
    isJsonResponse,
    isTextResponse,
    reqContentType,
    isLogin
}) {
    const headers = {};

    if (method === 'GET') {
        headers['Accept'] = '*/*';
    }
    if (username && password)
        headers['Authorization'] = 'Basic ' + btoa(username + ':' + password);

    if (json) {
        headers['Content-Type'] = 'application/json';
    }

    if (method === 'POST') {
        
        headers['Content-Type'] = 'application/x-www-form-urlencoded';
    }
    if(reqContentType){
        headers['Content-Type'] = reqContentType;
    }
    if (queryParams) {
        const uSearchParams = new URLSearchParams();
        for (const key in queryParams) {
            if (queryParams[key]) uSearchParams.set(key, queryParams[key]);
        }
        url += '?' + uSearchParams.toString();
    }
    return fetch(url, {
        method,
        mode: 'cors',
        credentials: 'include',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        headers,
        body:
            method === 'POST' || method === 'PUT' || method === 'PATCH'
                ? json: undefined,
    })
        .then((response) => {
            if(response.status === 401 && !isLogin){
                location.replace("/")
            }
            if (
                (successStatus && response.status !== successStatus) ||
                !response.ok
            ) {
                throw response;
            }
            return response;
        })
        .then((response) => {
            if (
                response.headers.get('content-type') == 'application/json' ||
                isJsonResponse
            )
                return response.json();
            else if (isTextResponse) return response.text();
            return response;
        })
        .catch((error) => {
            console.log({error})
            throw error;
        });
}

/**
 * Convenience function to execute a POST request.
 * @param {String} url URL to resource
 * @param {Object} queryParams Query Parameters, as a json Key Value Pair
 * @param {String} username Username for authentication
 * @param {String} password Password for authentication
 * @param {Number} successStatus HTTP Status code on a successful http request
 * @param {Object} json JSON Object Body for the request.
 */
export function post({
    url,
    queryParams,
    username,
    password,
    successStatus,
    json,
    isJsonResponse = true,
    isTextResponse = false,
    reqContentType
}) {
    return customFetch({
        url,
        method: 'POST',
        queryParams,
        username,
        password,
        successStatus,
        json,
        isJsonResponse,
        isTextResponse,
        reqContentType
    });
}

/**
 * Convenience function to execute a PUT request.
 * @param {String} url URL to resource
 * @param {Object} queryParams Query Parameters, as a json Key Value Pair
 * @param {String} username Username for authentication
 * @param {String} password Password for authentication
 * @param {Number} successStatus HTTP Status code on a successful http request
 * @param {Object} json JSON Object Body for the request.
 */
export function put({
    url,
    queryParams,
    username,
    password,
    successStatus,
    json,
    isJsonResponse = true,
    isTextResponse = false,
    reqContentType
}) {
    return customFetch({
        url,
        method: 'PUT',
        queryParams,
        username,
        password,
        successStatus,
        json,
        isJsonResponse,
        isTextResponse,
        reqContentType
    });
}

/**
 * Convenience function to execute a GET request.
 * @param {String} url URL to resource
 * @param {Object} queryParams Query Parameters, as a json Key Value Pair
 * @param {String} username Username for authentication
 * @param {String} password Password for authentication
 * @param {Number} successStatus HTTP Status code on a successful http request
 */
export function get({
    url,
    queryParams,
    username,
    password,
    successStatus,
    isJsonResponse = true,
    isTextResponse = false,
    isLogin
}) {
    return customFetch({
        url,
        method: 'GET',
        queryParams,
        username,
        password,
        successStatus,
        isJsonResponse,
        isTextResponse,
        isLogin
    });
}



// Custom Post Method 
export function customPOSTXFormEncoded({
    url,
    queryParams,
    username,
    password,
    jsonData,
    isTextResponse
}){
    var formBody = [];
    for (var mediaType in jsonData) {
    var encodedKey = encodeURIComponent(mediaType);
    var encodedValue = encodeURIComponent(jsonData[mediaType]);
    formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    const auth = btoa(`${username}:${password}`);

    return fetch( `${url}?`+new URLSearchParams({
        ...queryParams
    }).toString(),{
        method: 'POST',
        mode:'cors',
        credentials:'include',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'Authorization': `Basic ${auth}`
        },
        body:formBody
    }).then((response) => (isTextResponse)?response:response.json()).catch((err)=>{ throw err;});

}


// Upload File
export function fileUploadMultipart({    
    url,
    queryParams,
    username,
    password,
    jsonData,
    isTextResponse}){
        
        const formData = new FormData();
        formData.append("file", jsonData);    
        const auth = btoa(`${username}:${password}`);
        console.log({formData, queryParams});
        return fetch( `${url}?`+new URLSearchParams({
            ...queryParams
        }).toString(),{
            method: 'POST',
            mode:'cors',
            credentials:'include',
            headers: {
                'Authorization': `Basic ${auth}`
            },
            body:formData
        }).then((response) => {
            console.log({response});
            return (isTextResponse)?response:response.json()
        }).catch((err)=>{ 
            console.info({err});
            throw err;
        });

    }
    
export const setLocalStore = (key,value) => {
    localStorage.setItem(key,value);
};

export const getLocalStoreValue = (key) => {
    return localStorage.getItem(key);
}
