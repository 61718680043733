import React from 'react';
import { Item, TabList, TabPanels, Tabs, Well } from '@adobe/react-spectrum'
import UserManagement from './UserManagement';
import RoleManagement from './RoleManagement';
import TimezoneManagement from './TimezoneManagement';
import { connect } from 'react-redux';
import Permissions from './Permissions';
// import ConnectionType from './ConnectionType';

function mapStateToProps(state) {
    return {
        login: state.login,
    };
}

class AdminOperations extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded: true,
        };
    }

    render() {
        return (
            <Well
                style={{
                    minHeight: '75vh',
                    marginRight: '1%',
                    marginLeft: '1%',
                }}
            >

                <Tabs aria-label="Admin Operations">
                    <TabList>
                        <Item key="users">CARE USERS</Item>
                        <Item key="roles">CARE ROLES</Item>
                        <Item key="timezone">TIMEZONES</Item>
                        <Item key="permissions">PERMISSIONS</Item>
                    </TabList>
                    <TabPanels>
                        {/* <Item key="connection">
                            <ConnectionType />
                        </Item> */}
                        <Item key="users">
                            <UserManagement />
                        </Item>
                        <Item key="roles">
                            <RoleManagement />
                        </Item>
                        <Item key="timezone">
                            <TimezoneManagement />
                        </Item>
                        <Item key="permissions">
                            <Permissions/>
                        </Item>
                    </TabPanels>
                </Tabs>
            </Well>
        );
    }
}

export default connect(mapStateToProps)(AdminOperations);