import React, { useEffect, useState, useRef } from "react"
import {
    getAllAniByPassAPI,
    addAniBypassAPI,
    deleteAniBypassAPI,
} from "../../../../utilities/api/phoenix-api"
import {
    Flex,
    lightTheme,
    Provider,
    SearchField,
    ActionGroup,
    Item,
    Text,
    Content,
    IllustratedMessage,
    Heading,
    TableView,
    TableHeader,
    Column,
    TableBody,
    Cell,
    Row,
    ProgressCircle,
} from "@adobe/react-spectrum"
import Delete from "@spectrum-icons/workflow/Delete"
import Draw from "@spectrum-icons/workflow/Draw"
import AddAniDialog from "./AddAniDialog"
import { connect } from "react-redux"
import { notify, PheonixRoles } from "../../../../constants/constants"
import { renderEmptyState } from "../../../../utilities/components/CommonUseComponents"

function mapStateToProps(state) {
    return {
        user: state.login.userInfo,
        permissions:
            state.login.authorizedAccess != null
                ? state.login.authorizedAccess.permissions
                : [],
    }
}

const AniByPass = (props) => {
    const [aniByPass, setAniByPass] = useState([])
    const [filteredAniByPass, setFilteredAniByPass] = useState([]);
    // const [errorMessage, setErrorMessage] = useState("")
    const [currentText, setCurrentText] = useState(null)
    const [selectedAni, setSelectedAni] = useState(new Set())
    const [operation, setOperation] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false)
    // const [isdeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    // const [showDuplicateError, setShowDuplicateError] = useState(false);
    const ldap = props.user.userid
    const isAdmin = props.permissions.includes(PheonixRoles.aniBypassAdmin)

    const columns = [
        { name: "ANI", key: "ani" },
        { name: "Bypass", key: "bypass" },
    ]

    const columnsAdmin = [
        { name: "ANI", key: "ani" },
        { name: "Bypass", key: "bypass" },
        { name: "Actions", key: "actions" },
    ]


    // const currentSelectedAni = useRef("")
    const editData = useRef(false)

    const getAllAniByPass = () => {
        setIsLoading(true)
        getAllAniByPassAPI()
            .then((res) => {
                setAniByPass(res)
                setFilteredAniByPass(res)
                setIsLoading(false)
            })
            .catch(() => {
                console.log("Error in getting Ani Bypass")
            })
    }

    const deleteAniBypass = (ldap, name, multi) => {
        if (multi === "multi") {
            setIsLoading(true)
            const formatedData = name.join()
            const urlencoded = new URLSearchParams()
            urlencoded.append("ani", formatedData)
            deleteAniBypassAPI(ldap, urlencoded)
                .then((res) => {
                    notify(res, "success")
                    getAllAniByPass()
                    setIsLoading(false)
                    setSelectedAni(new Set())
                })
                .catch(() => {
                    notify("Error in deleting ANI Bypass","error")
                    setIsLoading(false)
                })
        } else {
            setIsLoading(true)
            const urlencoded = new URLSearchParams()
            urlencoded.append("ani", name)
            deleteAniBypassAPI(ldap, urlencoded)
                .then((res) => {
                    notify(res, "success")
                    getAllAniByPass()
                    setIsLoading(false)
                })
                .catch(() => {
                    notify("Error in deleting ANI Bypass","error")
                    setIsLoading(false)
                })
        }
    }

    useEffect(() => {
        getAllAniByPass()
    }, [])

    const performAction = (value, item) => {
        switch (value) {
            case "add":
                editData.current = null
                setIsAddDialogOpen(true)
                setOperation("Add")
                break
            case "edit":
                editData.current = aniByPass.find((d) => d.ani == item)
                setIsAddDialogOpen(true)
                setOperation("Edit")
                break
            case "delete":
                deleteAniBypass(ldap, item)
                break
            case "bulkDelete":
                const selectedOptions = Array.from(selectedAni)
                deleteAniBypass(ldap, selectedOptions, "multi")
                break
        }
    }

    const getCell = (columnKey, item) => {
        switch (columnKey) {
            case "actions":
                return (
                    <Cell>
                        <ActionGroup
                            onAction={(value) => performAction(value, item.ani)}

                        >
                            <Item key="edit">
                                <Draw />
                            </Item>
                            <Item key="delete">
                                <Delete />
                            </Item>
                        </ActionGroup>
                    </Cell>
                )
            case "ani":
                return <Cell>{item[columnKey]}</Cell>
            case "bypass":
                return <Cell>{item[columnKey]}</Cell>
            default:
                return <Cell>{item[columnKey]}</Cell>
        }
    }

    const isDuplicateAniBypass = (ani) => {
        return aniByPass.some((ele) => ele.ani === ani);
    };

    const closeAddTimeOutDialog = (isAddConfirmed, ani, bypass) => {
        if(operation !== "Edit" && isDuplicateAniBypass(ani)){
            notify(`${ani} already exist`,"error");
            return;
        }
        setIsAddDialogOpen(false)
        if (isAddConfirmed) {
            setIsLoading(true)
            const urlencoded = new URLSearchParams()
            urlencoded.append("ani", ani)
            urlencoded.append("bypass", bypass ? "yes" : "no")
            addAniBypassAPI(ldap, urlencoded)
            .then((res) => {
                getAllAniByPass()
                setIsLoading(false)
                notify(res, "success")
            })
            .catch(() => {
                setIsLoading(false);
                notify("Error in Adding Ani", "error");
            })           
        }
    }
    
    const filterAniBypassList = (value) => {
        if(value === ""){
            setFilteredAniByPass(aniByPass)
        }else {
            const getFilteredData = filteredAniByPass.filter((info) =>
                info.ani.toString().includes(value)
            )
            setFilteredAniByPass(getFilteredData)
        } 
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if(currentText !== null){
                filterAniBypassList(currentText)
            }
        },500)
        return () => clearTimeout(timer)
    }, [currentText])

    const filterAniBypass = (value) => {
        setCurrentText(value)
    }

    return (
        <>
          { isLoading && <div
                className={isLoading ? "display-block" : "display-none"}
                style={{
                    paddingTop: "10%",
                    paddingLeft: "45%",
                    position: "absolute",
                    zIndex:"10"
                }}
            >
                
                    <ProgressCircle
                        aria-label="Loading…"
                        isIndeterminate
                        size="L"
                    />
                
            </div>}

            <div align="center">
                <Heading variant="pageTitle">ANI ByPass</Heading>
            </div>

            <div
                style={{
                    padding: "25px",
                    border: "0.8px solid",
                    borderRadius: "0.7em",
                    marginTop: "30px",
                    width: "43vw",
                    margin: "0 auto",
                }}
            >
                <Provider theme={lightTheme}>
                    <Flex
                        direction="row"
                        gap="size-100"
                        UNSAFE_className="searchMobile"
                    >
                       <SearchField
                            aria-label="search"
                            value={currentText}
                            onChange={filterAniBypass}
                            isQuiet
                            onClear={() => setCurrentText("")}
                            width={"30%"}
                        />

                        {
                             isAdmin &&
                            <ActionGroup
                                width={"80%"}
                                alignItems="end"
                                onAction={(value) => performAction(value, null)}
                                disabledKeys={
                                    selectedAni.size || selectedAni == "all"
                                        ? []
                                        : ["bulkDelete"]
                                }
                            >
                                <Item key="add">+ Add</Item>
                                <Item key="bulkDelete">
                                    <Delete />
                                    <Text>Delete</Text>
                                </Item>
                            </ActionGroup>
                        }
                    </Flex>

                    <Flex
                        width="100%"
                        height="100%"
                        direction="column"
                        gap="size-150"
                    >
                        <TableView
                            aria-label="Care Roles"
                            selectionMode={"multiple"}
                            selectedKeys={selectedAni}
                            onSelectionChange={setSelectedAni}
                            height={"45vh"}
                            renderEmptyState={renderEmptyState}
                            density="spacious"
                            overflowMode="wrap"
                        >
                            <TableHeader columns={isAdmin ? columnsAdmin : columns}>
                                {(column) => (
                                    <Column
                                        key={column.key}
                                        align={
                                            column.key === "actions"
                                                ? "end"
                                                : "start"
                                        }
                                        width={column.key === "ani" ? "41%" : column.key === "actions" ? "29%" : "auto"}
                                        
                                    >
                                        {column.name}
                                    </Column>
                                )}
                            </TableHeader>
                            <TableBody items={filteredAniByPass}>
                                {(item) => (
                                    <Row key={item.ani}>
                                        {(columnKey) =>
                                            getCell(columnKey, item)
                                        }
                                    </Row>
                                )}
                            </TableBody>
                        </TableView>
                    </Flex>

                    {isAddDialogOpen && (
                        <AddAniDialog
                            isDialogOpened={isAddDialogOpen}
                            editData={editData.current}
                            operation={operation}
                            handleCloseDialog={(isAddConfirmed, ani, bypass) =>
                                closeAddTimeOutDialog(
                                    isAddConfirmed,
                                    ani,
                                    bypass
                                )
                            }
                        />
                    )}
                </Provider>
            </div>
        </>
    )
}

export default connect(mapStateToProps)(AniByPass)
