import React, { useEffect, useState } from "react"
import { Item, TabList, TabPanels, Tabs, Well } from "@adobe/react-spectrum"
import { connect } from "react-redux"
import AgentGroups from "./ChildComponents/AgentGroups/AgentGroupList"
import {
    getAllAgentGroupList,
    getLoggedInUserIsAdmin,
    getPermissionsApi,
} from "../utilities/api/phoenix-api"
import AgentGroupMapping from "./ChildComponents/GroupConfig/AgentGroupMapping"
import DefaultConfigMapping from "./ChildComponents/DefaultConfig/DefaultConfigMapping"
import VoiceComplaince from "./ChildComponents/VoiceCompliance/VoiceComplianceList"
import { PheonixRoles } from "../constants/constants"
import CommonConfig from "./ChildComponents/CommonConfig/CommonConfig"
import AttributeList from "./ChildComponents/AttributeList/AttributeList"
import Supervisior from "./ChildComponents/Supervisior/AgentGroupList"


function mapStateToProps(state) {
    return {
        user: state.login.authorizedAccess.username,
        permissions:
            state.login.authorizedAccess != null
                ? state.login.authorizedAccess.permissions
                : [],
    }
}

const AmazonConnect = ({ user, permissions }) => {
    const [agentList, setAgentList] = useState([])
    const [isAdminInEdit, setIsAdminInEdit] = useState(false)
    const [commonPermissions, setCommonPermissions] = useState([])
    const [userPermissions, setUserPermissions] = useState([])

    const getAgentGroupList = async (ldap) => {
        const response = await getAllAgentGroupList(ldap);
        const updatedAgentGroupStructure = response.map((agent) => {
            const { agent_ldap } = agent
            const agentGroupList = new Set(
                response
                    .filter((subagent) => subagent.agent_ldap == agent_ldap)
                    .map((subagent) => {
                        return {
                            groupName: subagent.agent_group_name,
                            groupActiveStatus: subagent.is_agent_profile,
                            first_name: subagent.first_name,
                            last_name: subagent.last_name,
                        }
                    })
            )
            return { agent_ldap, agentGroupList: Array.from(agentGroupList) }
        })
        const uniqueAgentList = [
            ...new Map(
                updatedAgentGroupStructure.map((item) => [
                    item.agent_ldap,
                    item,
                ])
            ).values(),
        ]
        setAgentList(uniqueAgentList)
    }

    useEffect(() => {
        if (user?.userid) {
            getAgentGroupList(user.userid)
        }
        getPermissions()
        getLoggedInUserIsAdmin(user)
            .then((resp) => {
                setIsAdminInEdit(resp)
            })
            .catch((err) => console.log(err))
    }, [])

    const getPermissions = () => {
        getPermissionsApi(user)
            .then((res) => {
                setCommonPermissions(
                    res?.filter((obj) => obj.group === "global")
                )
                setUserPermissions(res?.filter((obj) => obj.group !== "global"))
            })
            .catch((err) => {
                console.log("Error in getting permissions ", err)
            })
    }

    return (
        <Well
            UNSAFE_style={{
                minHeight: "75vh",
                marginRight: "1%",
                marginLeft: "1%",
            }}
        >
            {permissions?.length && (
                <Tabs aria-label="Audit Reports">
                    <TabList>
                        {(permissions.includes(PheonixRoles.agentGroup) ||
                            permissions.includes(
                                PheonixRoles.agentGroupAdmin
                            )) && <Item key="agent_groups">USERS</Item>}
                        {(permissions.includes(PheonixRoles.supervisior) || permissions.includes(PheonixRoles.supervisiorAdmin)) && (<Item key="supervisior">SUPERVISORS</Item>)}
                        {(permissions.includes(PheonixRoles.agentGroupConfig) ||
                            permissions.includes(
                                PheonixRoles.agentGroupConfigAdmin
                            )) && (
                            <Item key="agentgroupconfig">GROUP CONFIG</Item>
                        )}
                        {(permissions.includes(PheonixRoles.voiceComplaince) ||
                            permissions.includes(
                                PheonixRoles.voiceComplainceAdmin
                            )) && (
                            <Item key={"voiceComplaince"}>
                                VOICE COMPLIANCE
                            </Item>
                        )}
                        {(permissions.includes(PheonixRoles.timeout) ||
                            permissions.includes(PheonixRoles.timeoutAdmin) ||
                            permissions.includes(PheonixRoles.aniBypass) ||
                            permissions.includes(PheonixRoles.aniBypassAdmin) ||
                            permissions.includes(
                                PheonixRoles.holidaySchedules
                            ) ||
                            permissions.includes(
                                PheonixRoles.holidaySchedulesAdmin
                            ) ||
                            permissions.includes(PheonixRoles.emergency) ||
                            permissions.includes(PheonixRoles.emergencyAdmin) ||
                            permissions.includes(PheonixRoles.dxPrompts) ||
                            permissions.includes(PheonixRoles.dxPromptsAdmin) ||
                            permissions.includes(PheonixRoles.dnis) ||
                            permissions.includes(PheonixRoles.dnisAdmin) ||
                            permissions.includes(PheonixRoles.didMagento) ||
                            permissions.includes(
                                PheonixRoles.didMagentoAdmin
                            ) ||
                            permissions.includes(PheonixRoles.spamCallers) ||
                            permissions.includes(
                                PheonixRoles.spamCallersAdmin
                            ) ||
                            permissions.includes(
                                PheonixRoles.operationalHours
                            ) ||
                            permissions.includes(
                                PheonixRoles.operationalHoursAdmin
                            )) && (
                            <Item key={"commonConfig"}>
                                ROUTING FLOW CONFIG
                            </Item>
                        )}
                        {(permissions.includes(PheonixRoles.attributeList) ||
                            permissions.includes(
                                PheonixRoles.attributeListAdmin
                            )) && (
                            <Item key={"attributeList"}>ATTRIBUTE LIST</Item>
                        )}
                    </TabList>
                    <TabPanels>
                        <Item key="agent_groups">
                            <AgentGroups
                                isAdmin={permissions.includes(
                                    PheonixRoles.agentGroupAdmin
                                )}
                                groupPermission={permissions.includes(
                                    PheonixRoles.userGroupQueuesAndProficienciesAccess
                                )}
                                additionalPermission={permissions.includes(
                                    PheonixRoles.userAdditionalQueuesAndProficienciesAccess
                                )}
                                agentList={agentList}
                                getUpdatedAgentList={() =>
                                    getAgentGroupList(user.userid)
                                }
                                user={user}
                                isAdminInEdit={isAdminInEdit}
                                commonPermissions={commonPermissions}
                                userPermissions={userPermissions}
                            />
                        </Item>
                        <Item key={"supervisior"}>
                            <Supervisior
                                isAdmin={permissions.includes(
                                    PheonixRoles.supervisiorAdmin
                                )}
                                groupPermission={permissions.includes(
                                    PheonixRoles.userGroupQueuesAndProficienciesAccess
                                )}
                                additionalPermission={permissions.includes(
                                    PheonixRoles.userAdditionalQueuesAndProficienciesAccess
                                )}
                                agentList={agentList}
                                getUpdatedAgentList={() =>
                                    getAgentGroupList(user.userid)
                                }
                                user={user}
                                isAdminInEdit={isAdminInEdit}
                                commonPermissions={commonPermissions}
                                userPermissions={userPermissions}
                            />
                        </Item>
                        <Item key="agentgroupconfig">
                            <AgentGroupMapping
                                isAdminInEdit={isAdminInEdit}
                                // addAccess={permissions.includes(
                                //     PheonixRoles.groupConfigAddAccess
                                // )}
                                // cloneAccess={permissions.includes(
                                //     PheonixRoles.groupConfigCloneAccess
                                // )}
                                // enforceUpdateAccess={permissions.includes(
                                //     PheonixRoles.groupConfigEnforceUpdateAccess
                                // )}
                                commonPermissions={commonPermissions}
                                userPermissions={userPermissions}
                            />
                        </Item>
                        {/* <Item key={"defaultConfig"}>
                        <DefaultConfigMapping />
                    </Item> */}
                        <Item key={"voiceComplaince"}>
                            <VoiceComplaince user={user} />
                        </Item>
                        <Item key={"commonConfig"}>
                            <CommonConfig user={user} />
                        </Item>
                        <Item key={"attributeList"}>
                            <AttributeList
                                isAdmin={permissions.includes(
                                    PheonixRoles.attributeListAdmin
                                )}
                            />
                        </Item>
                    </TabPanels>
                </Tabs>
            )}
        </Well>
    )
}

export default connect(mapStateToProps)(AmazonConnect)
