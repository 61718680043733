import {
    USER_INFO,
    USER_LOGOUT,
    SUPPORT_INDEX,
    MAIN_INDEX,
    SET_MENU,
    USER_AUTHORIZATION,
    ADOBE_IO_TOKEN
} from '../constants/constants';

const initialState = {
    userInfo: {},
    defaultIndexForSupportMenu: 0,
    defaultIndexForMainMenu: null,
    login: false,
    menuData: null,
    menuKeys: null,
    authorizedAccess: null
};

/**
 *
 * @param {*} state
 * @param {*} action
 */
export default function (state = initialState, action) {
    switch (action.type) {
        case USER_INFO:
            return {
                ...state,
                userInfo: action.userInfo,
            };
        case USER_LOGOUT:
            return {
                ...initialState,
            };
        case SUPPORT_INDEX:
            return {
                ...state,
                defaultIndexForSupportMenu: action.supportIndex,
                defaultIndexForMainMenu: action.mainIndex,
            };
        case MAIN_INDEX:
            return {
                ...state,
                defaultIndexForMainMenu: action.index,
            };
        case SET_MENU:
            return {
                ...state,
                menuData: action.menuData,
                menuKeys: action.menuKeys,
            };
        case USER_AUTHORIZATION:
            return {
                ...state,
                authorizedAccess: action.authorizedAccess,
            };
        case ADOBE_IO_TOKEN:
            return {
                ...state,
                token: action.token,
            };
        default:
            return state;
    }
}
