import React, { useEffect, useState, useRef } from "react"
import {
    getSrsMockDataApi,
    deleteSrsMockDataApi,
    addSrsMockDataApi,
} from "../../../../utilities/api/phoenix-api"
import {
    Flex,
    lightTheme,
    Provider,
    SearchField,
    ActionGroup,
    Item,
    Text,
    Content,
    IllustratedMessage,
    Heading,
    TableView,
    TableHeader,
    Column,
    TableBody,
    Cell,
    Row,
    ProgressCircle,
} from "@adobe/react-spectrum"
import Delete from "@spectrum-icons/workflow/Delete"
import Draw from "@spectrum-icons/workflow/Draw"
import { PheonixRoles, notify } from "../../../../constants/constants"
import { connect } from "react-redux"
import AddSrsMockData from "./AddSrsMockData"
import { renderEmptyState } from "../../../../utilities/components/CommonUseComponents"

function mapStateToProps(state) {
    return {
        user: state.login.userInfo,
        permissions:
            state.login.authorizedAccess != null
                ? state.login.authorizedAccess.permissions
                : [],
    }
}

const SrsMockData = (props) => {
    const [srsMockData, setSrsMockData] = useState([])
    const [filteredSrsMockData, setFilteredSrsMockData] = useState([])
    // const [errorMessage, setErrorMessage] = useState("")
    const [currentText, setCurrentText] = useState(null)
    const [selectedSrsMockData, setSelectedSrsMockData] = useState(new Set())
    const [operation, setOperation] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false)
    // const [isdeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    const ldap = props?.user?.userid
    const isAdmin = props?.permissions?.includes(
        PheonixRoles.srsMockDataAdmin
    )

    const columns = [
        { name: "Channel", key: "channel" },
        { name: "Test Mode", key: "testMode" },
    ]

    const columnsAdmin = [
        { name: "Channel", key: "channel" },
        { name: "Test Mode", key: "testMode" },
        { name: "Actions", key: "actions" },
    ]

    // const currentTimeoutSelected = useRef("")
    const editData = useRef(false)

    const getAllSrsMockData = () => {
        setIsLoading(true)
        getSrsMockDataApi(ldap)
            .then((res) => {
                setSrsMockData(res)
                setFilteredSrsMockData(res)
                setIsLoading(false)
            })
            .catch(() => {
                //console.log("Error in getting Srs Mock Data")
            })
    }

    useEffect(() => {
        getAllSrsMockData()
    }, [])

    // const deleteDidMagento = (ldap, name, multi) => {
    //     if (multi === "multi") {
    //         setIsLoading(true)
    //         const formatedData = name.join()
    //         const urlencoded = new URLSearchParams()
    //         urlencoded.append("did", formatedData)
    //         deleteDidMagentoApi(ldap, urlencoded)
    //             .then((res) => {
    //                 notify(res, "success")
    //                 getAllDidMagento()
    //                 setSelectedMagento(new Set())
    //                 setIsLoading(false)
    //             })
    //             .catch(() => {
    //                 notify("Error in Multi deleting Did Magento")
    //                 setIsLoading(false)
    //             })
    //     } else {
    //         setIsLoading(true)
    //         const urlencoded = new URLSearchParams()
    //         urlencoded.append("did", name)
    //         deleteDidMagentoApi(ldap, urlencoded)
    //             .then((res) => {
    //                 notify(res, "success")
    //                 getAllDidMagento()
    //                 setIsLoading(false)
    //             })
    //             .catch(() => {
    //                 notify("Error in deleting Magento")
    //                 setIsLoading(false)
    //             })
    //     }
    // }


    const performAction = (value, item) => {
        // //console.log({ value, item })
        switch (value) {
            case "add":
                editData.current = null
                setIsAddDialogOpen(true)
                setOperation("Add")
                break
            case "edit":
                editData.current = srsMockData.find(
                    (val) => val.channel == item.channel && val.testMode == item.testMode
                )
                setIsAddDialogOpen(true)
                setOperation("Edit")
                break
            case "delete":
                deleteSrsMockData(ldap, item)
                // currentTimeoutSelected.current = item
                // setIsDeleteDialogOpen(true)
                break
            case "bulkDelete":
                const selectedOptions = Array.from(selectedSrsMockData)
                // deleteDidMagento(ldap, selectedOptions, "multi")
                // setIsDeleteDialogOpen(true)
                break
        }
    }

    const deleteSrsMockData = (ldap, item) => {
        setIsLoading(true)
        const urlencoded = new URLSearchParams()
        urlencoded.append("channels",item.channel)
        deleteSrsMockDataApi(ldap, urlencoded)
            .then((res) => {
                notify(res, "success")
                getAllSrsMockData()
                setIsLoading(false)
            })
            .catch((err) => {
                notify(err, "error")
                setIsLoading(false)
            })
    }

    const getCell = (columnKey, item) => {
        // //console.log({ columnKey, item })
        switch (columnKey) {
            case "actions":
                return (
                    <Cell>
                        <ActionGroup
                            onAction={(value) => performAction(value, item)}
                        >
                            <Item key="edit">
                                <Draw />
                            </Item>
                            <Item key="delete">
                                <Delete />
                            </Item>
                        </ActionGroup>
                    </Cell>
                )
            case "channel":
                return <Cell>{item[columnKey]}</Cell>
            case "testMode":
                return <Cell>{item[columnKey]}</Cell>
            default:
                return <Cell>{item[columnKey]}</Cell>
        }
    }

    // const isDuplicateTimeoutName = (name) => {
    //     return timeOut.some((ele) => ele.timeoutName === name);
    // };

    const closeAddSrsMockDataDialogue = (isAddConfirmed, body) => {
        // if(isDuplicateTimeoutName(timeoutName)){
        //     notify(`${timeoutName} already exist`,"error");
        //     return;
        // }
        setIsAddDialogOpen(false)
        if (isAddConfirmed) {
            setIsLoading(true)
            addSrsMockDataApi(ldap, body)
                .then((res) => {
                    getAllSrsMockData()
                    notify(res, "success")
                })
                .catch(() => notify("Error in Adding Srs Mock Data", "error"))

            setIsLoading(false)
        }
    }

    const filterSrsMockDataList = (value) => {
        if (value === "") {
            setFilteredSrsMockData(srsMockData)
        } else {
            const getFilteredData = filteredSrsMockData.filter((val) =>
                val.channel.toLowerCase().includes(value.toLowerCase())
            )
            setFilteredSrsMockData(getFilteredData)
        }
    }


    useEffect(() => {
        const timer = setTimeout(() => {
            if (currentText !== null) {
                filterSrsMockDataList(currentText)
            }
        }, 500)
        return () => clearTimeout(timer)
    }, [currentText])

    const searchSrsMockData = (value) => {
        setCurrentText(value)
    }

    return (
        <>
          
          {/* <div
                className={isLoading ? "display-block" : "display-none"}
                style={{
                    paddingTop: "10%",
                    paddingLeft: "45%",
                    position: "absolute",
                    zIndex: "10",
                    // border:"1px solid red",
                    marginTop:"15%"
                }}
            > */}
                {isLoading ? (
                    <ProgressCircle
                        aria-label="Loading…"
                        isIndeterminate
                        size="L"
                        UNSAFE_style={{marginLeft:"44%",marginTop:"20%",position:"absolute",zIndex:10}}
                    />
                ) : null}
            {/* </div> */}

            <div align="center">
                <Heading variant="pageTitle">SRS MOCK DATA</Heading>
            </div>

            <div
                style={{
                    padding: "25px",
                    border: "0.8px solid",
                    borderRadius: "0.7em",
                    marginTop: "30px",
                    width: "40vw",
                    margin: "0 auto",
                }}
            >
                <Provider theme={lightTheme}>
                    <Flex
                        direction="row"
                        gap="size-100"
                        UNSAFE_className="searchMobile"
                    >
                        {/* <SearchField
                            aria-label="search"
                            value={currentText}
                            onChange={searchSrsMockData}
                            isQuiet
                            onClear={() => setCurrentText("")}
                            width={"30%"}
                        /> */}

                        {
                               isAdmin &&
                            <ActionGroup
                                width={"100%"}
                                alignItems="end"
                                onAction={(value) => performAction(value, null)}
                                disabledKeys={
                                    selectedSrsMockData.size ||
                                    selectedSrsMockData == "all"
                                        ? []
                                        : ["bulkDelete"]
                                }
                            >
                                <Item key="add">+ Add</Item>
                                {/* <Item key="bulkDelete">
                                    <Delete />
                                    <Text>Delete</Text>
                                </Item> */}
                            </ActionGroup>
                        }
                    </Flex>

                    <Flex
                        width="100%"
                        height="100%"
                        direction="column"
                        gap="size-150"
                    >
                        <TableView
                            aria-label="Timeout"
                            // selectionMode={"multiple"}
                            selectedKeys={selectedSrsMockData}
                            onSelectionChange={setSelectedSrsMockData}
                            height={"45vh"}
                            renderEmptyState={renderEmptyState}
                            density="spacious"
                            overflowMode="wrap"
                        >
                            <TableHeader
                                columns={isAdmin ? columnsAdmin : columns}
                            >
                                {(column) => (
                                    <Column
                                        key={column.key}
                                        align={
                                            column.key === "actions"
                                                ? "end"
                                                : "start"
                                        }
                                    
                                    >
                                        {column.name}
                                    </Column>
                                )}
                            </TableHeader>
                            <TableBody items={filteredSrsMockData}>
                                {(item) => (
                                    <Row
                                        key={`${item.channel}-${item.testMode}`}
                                    >
                                        {(columnKey) =>
                                            getCell(columnKey, item)
                                        }
                                    </Row>
                                )}
                            </TableBody>
                        </TableView>
                    </Flex>
                </Provider>

                {isAddDialogOpen && (
                    <AddSrsMockData
                        isDialogOpened={isAddDialogOpen}
                        editData={editData.current}
                        operation={operation}
                        handleCloseDialog={(isAddConfirmed, body) =>
                            closeAddSrsMockDataDialogue(isAddConfirmed, body)
                        }
                    />
                )}
            </div>
        </>
    )
}

export default connect(mapStateToProps)(SrsMockData)
