import React, { useEffect, useState, useRef } from "react"
import {
    getAllTimeoutAPI,
    addTimeoutAPI,
    deleteTimeoutAPI,
} from "../../../../utilities/api/phoenix-api"
import {
    Flex,
    lightTheme,
    Provider,
    SearchField,
    ActionGroup,
    Item,
    Text,
    Content,
    IllustratedMessage,
    Heading,
    TableView,
    TableHeader,
    Column,
    TableBody,
    Cell,
    Row,
    ProgressCircle,
} from "@adobe/react-spectrum"
import Delete from "@spectrum-icons/workflow/Delete"
import Draw from "@spectrum-icons/workflow/Draw"
import AddTimeOutDialog from "./AddTimeOutDialog"
import { PheonixRoles, notify } from "../../../../constants/constants"
import { connect } from "react-redux"
import { renderEmptyState } from "../../../../utilities/components/CommonUseComponents"
// import "./style.css"

function mapStateToProps(state) {
    return {
        user: state.login.userInfo,
        permissions:
            state.login.authorizedAccess != null
                ? state.login.authorizedAccess.permissions
                : [],
    }
}

const Timeout = (props) => {
    const [timeOut, setTimeOut] = useState([])
    // const [errorMessage, setErrorMessage] = useState("")
    const [currentText, setCurrentText] = useState(null)
    const [selectedTimeout, setSelectedTimeout] = useState(new Set())
    const [operation, setOperation] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false)
    // const [isdeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    const [filteredTimeOut, setFilteredTimeOut] = useState([])
    const ldap = props.user.userid
    const isAdmin = props.permissions.includes(PheonixRoles.timeoutAdmin)


    const columns = [
        { name: "Name", key: "timeoutName" },
        { name: "Value", key: "timeoutValue" },
        { name: "Description", key: "timeoutDescription" },
    ]

    const columnsAdmin = [
        { name: "Name", key: "timeoutName" },
        { name: "Value", key: "timeoutValue" },
        { name: "Description", key: "timeoutDescription" },
        { name: "Actions", key: "actions" },
    ]

    const currentTimeoutSelected = useRef("")
    const editData = useRef(false)

    const getAllTimeout = () => {
        setIsLoading(true)
        getAllTimeoutAPI()
            .then((res) => {
                setTimeOut(res)
                setFilteredTimeOut(res);
                setIsLoading(false)
            })
            .catch(() => {
                //console.log("Error in getting Ani pass")
            })
    }

    useEffect(() => {
        getAllTimeout()
    }, [])

    const deleteTimeOut = (ldap, name, multi) => {
        if (multi === "multi") {
            setIsLoading(true)
            const formatedData = name.join()
            const urlencoded = new URLSearchParams()
            urlencoded.append("timeoutNames", formatedData)
            deleteTimeoutAPI(ldap, urlencoded)
                .then((res) => {
                    notify(res, "success")
                    getAllTimeout()
                    setSelectedTimeout(new Set())
                    setIsLoading(false)
                })
                .catch(() => {
                    notify("Error in Multi deleting timeout")
                    setIsLoading(false)
                })
        } else {
            setIsLoading(true)
            const urlencoded = new URLSearchParams()
            urlencoded.append("timeoutNames", name)
            deleteTimeoutAPI(ldap, urlencoded)
                .then((res) => {
                    notify(res, "success")
                    getAllTimeout()
                    setIsLoading(false)
                })
                .catch(() => {
                    notify("Error in deleting Timeout")
                    setIsLoading(false)
                })
        }
    }

    const performAction = (value, item) => {
        switch (value) {
            case "add":
                editData.current = null
                setIsAddDialogOpen(true)
                setOperation("Add")
                break
            case "edit":
                editData.current = timeOut.find(
                    (val) => val.timeoutName == item
                )
                setIsAddDialogOpen(true)
                setOperation("Edit")
                break
            case "delete":
                deleteTimeOut(ldap, item)
                currentTimeoutSelected.current = item
                break
            case "bulkDelete":
                const selectedOptions = Array.from(selectedTimeout)
                deleteTimeOut(ldap, selectedOptions, "multi")
                break
        }
    }

    const getCell = (columnKey, item) => {
        switch (columnKey) {
            case "actions":
                return (
                    <Cell>
                        <ActionGroup
                            onAction={(value) =>
                                performAction(value, item.timeoutName)
                            }                        >
                            <Item key="edit" >
                                <Draw />
                            </Item>
                            <Item key="delete">
                                <Delete />
                            </Item>
                        </ActionGroup>
                    </Cell>
                )
            case "timeoutName":
                return <Cell>{item[columnKey]}</Cell>
            case "timeoutValue":
                return <Cell>{item[columnKey]}</Cell>
            default:
                return <Cell>{item[columnKey]?item[columnKey]:'-'}</Cell>
        }
    }

    const isDuplicateTimeoutName = (name) => {
        return timeOut.some((ele) => ele.timeoutName === name);
    };

    const closeAddTimeOutDialog = (isAddConfirmed, timeoutName , timeoutValue, timeoutDescription) => {
        if(operation !== "Edit" && isDuplicateTimeoutName(timeoutName)){
            notify(`${timeoutName} already exist`,"error");
            return;
        }
        setIsAddDialogOpen(false)
        if (isAddConfirmed) {
            setIsLoading(true)
            const urlencoded = new URLSearchParams()
            urlencoded.append("timeoutName", timeoutName)
            urlencoded.append("timeoutValue", timeoutValue)
            urlencoded.append("timeoutDescription", timeoutDescription);
            addTimeoutAPI(ldap, urlencoded)
                .then((res) => {
                    getAllTimeout()
                    notify(res, "success")
                })
                .catch(() => notify("Error in Adding timeout", "error"))

            setIsLoading(false)
        }
    }

    
    const filterTimeoutList = (value) => {
        if(value === ""){
            setFilteredTimeOut(timeOut)
        }else {
            const getFilteredData = filteredTimeOut.filter((info) =>
                info.timeoutName.toLowerCase().includes(value.toLowerCase())
            )
            setFilteredTimeOut(getFilteredData)
        } 
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if(currentText !== null){
                filterTimeoutList(currentText)
            }
        },500)
        return () => clearTimeout(timer)
    }, [currentText])

    const filterTimeout = (value) => {
        setCurrentText(value)
    }

    return (
        <>
          {isLoading ?  <div
                className={isLoading ? "display-block" : "display-none"}
                style={{
                    paddingTop: "10%",
                    paddingLeft: "45%",
                    position: "absolute",
                    zIndex:"10"
                }}
            >
              
                    <ProgressCircle
                        aria-label="Loading…"
                        isIndeterminate
                        size="L"
                    />
               
            </div> : null}

            <div align="center">
                <Heading variant="pageTitle">Timeout</Heading>
            </div>

            <div
                style={{
                    padding: "25px",
                    border: "0.8px solid",
                    borderRadius: "0.7em",
                    marginTop: "30px",
                    width: "75vw",
                    margin: "0 auto",
                }}
            >
                <Provider theme={lightTheme}>
                    <Flex
                        direction="row"
                        gap="size-100"
                        UNSAFE_className="searchMobile"
                    >
                        <SearchField
                        aria-label="search"
                            value={currentText}
                            onChange={filterTimeout}
                            isQuiet
                            onClear={() => setCurrentText("")}
                            width={"30%"}
                        />

                        {
                           isAdmin &&
                            <ActionGroup
                                width={"80%"}
                                alignItems="end"
                                onAction={(value) => performAction(value, null)}
                                disabledKeys={
                                    selectedTimeout.size ||
                                    selectedTimeout == "all"
                                        ? []
                                        : ["bulkDelete"]
                                }
                            >
                                <Item key="add">+ Add</Item>
                                <Item key="bulkDelete">
                                    <Delete />
                                    <Text>Delete</Text>
                                </Item>
                            </ActionGroup>
                        }
                    </Flex>

                    <Flex
                        width="100%"
                        height="100%"
                        direction="column"
                        gap="size-150"
                    >
                        <TableView
                            aria-label="Timeout"
                            selectionMode={"multiple"}
                            selectedKeys={selectedTimeout}
                            onSelectionChange={setSelectedTimeout}
                            height={"45vh"}
                            renderEmptyState={renderEmptyState}
                            density="spacious"
                            overflowMode="wrap"
                        >
                            <TableHeader columns={isAdmin ? columnsAdmin : columns}>
                                {(column) => (
                                    <Column
                                        key={column.key}
                                        align={column.key === "actions" ? "end" : "start"}
                                        width={column.key === "timeoutName" ? "35%" : column.key === "actions" ? "23%" : "auto"}
                                    >
                                        {column.name}
                                    </Column>
                                )}
                            </TableHeader>
                            <TableBody items={filteredTimeOut}>
                                {(item) => (
                                    <Row key={item.timeoutName}>
                                        {(columnKey) =>
                                            getCell(columnKey, item)
                                        }
                                    </Row>
                                )}
                            </TableBody>
                        </TableView>
                    </Flex>
                </Provider>

                {isAddDialogOpen && (
                    <AddTimeOutDialog
                        isDialogOpened={isAddDialogOpen}
                        editData={editData.current}
                        operation={operation}
                        handleCloseDialog={(isAddConfirmed,timeoutName,timeoutValue, timeoutDescription ) =>
                            closeAddTimeOutDialog(isAddConfirmed,timeoutName, timeoutValue, timeoutDescription)
                        }
                        
                    />
                )}
            </div>
        </>
    )
}

export default connect(mapStateToProps)(Timeout)
 