import React, { useEffect, useRef, useState } from "react"
import {
    Item,
    Content,
    Heading,
    IllustratedMessage,
    Flex,
    View,
    SearchField,
    ActionGroup,
    ContextualHelp,
    ProgressCircle,
} from "@adobe/react-spectrum"
import {
    getAllVoiceCompliance,
    deleteVoiceCompliance,
    bulkDeleteVoiceCompliance,
    addVoiceCompliance
} from "../../../utilities/api/phoenix-api"
import { notify, PheonixRoles } from "../../../constants/constants"
import VoiceList from "./VoiceList"
// import Copy from "@spectrum-icons/workflow/Copy"
import AddVoiceCompliance from "./AddVoiceCompliance"
import AddVoiceCompliancee from "./AddVoiceCompliancee"
import { connect } from "react-redux"

function mapStateToProps(state) {
    return {
        user: state.login.userInfo,
        permissions:
            state.login.authorizedAccess != null
                ? state.login.authorizedAccess.permissions
                : [],
    }
}

const VoiceCompliance = (props) => {
    const [voiceComplianceList, setVoiceComplianceList] = useState([])
    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false)
    const [operation, setOperation] = useState("")
    const [currentText, setCurrentText] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [selectedAccordians, setSelectedAccordians] = useState([])
    const [alreadyExistingKey , setAlreadyExistingKey] = useState([])
    const isAdmin = props.permissions.includes(
        PheonixRoles.voiceComplainceAdmin
    )

    const editData = useRef(false)

    const getVoiceComplianceList = async () => {
        setIsLoading(true)
        const response = await getAllVoiceCompliance()
        setVoiceComplianceList(response)
        setIsLoading(false)
    }

    const deleteVoiceComplianceMethod = (ldap, keyName) => {
        // const urlencoded = new URLSearchParams();
        // urlencoded.append("policy", keyName);
        deleteVoiceCompliance(props.user.userid , keyName)
            .then(() => {
                notify("Deleted successfully", "success")
                getVoiceComplianceList()
            })
            .catch(() => {
                notify("Error in deleting", "error")
            })
    }

    const returnEmptyState = () => {
        return (
            <IllustratedMessage>
                <Content>No results found</Content>
            </IllustratedMessage>
        )
    }

    const deleteVoiceComplianceGroupAccordian = (AgentLdap, deleteValue) => {
        bulkDeleteVoiceCompliance(AgentLdap, deleteValue)
            .then(() => {
                notify("Successfully deleted", "success")
                getVoiceComplianceList()
            })
            .catch(() => notify("Error in multi deleting", "error"))
    }

    const performAction = (value, AgentLdap, groupData) => {
        switch (value) {
            case "add":
                editData.current = null
                setIsAddDialogOpen(true)
                setOperation("Add")
                break
            case "edit":
                const keyy = Object.keys(groupData)[0];
                const valueArr = Object.values(groupData)[0]
               editData.current = {
                key : keyy,
                valueArray : valueArr
               }
                setIsAddDialogOpen(true)
                setOperation("Edit")
                break
            // case "edit3":
            //     if (groupData) {
            //         const { firstKey, secondKey, obj, index, data } = groupData
            //         editData.current = {
            //             first_key: firstKey || "",
            //             second_key: secondKey || "",
            //             second_level_array: data ? [...data] : [],
            //             obj: obj ? { ...obj } : {},
            //             indexx: index,
            //         }
            //     }
            //     setIsAddDialogOpen(true)
            //     setOperation("Edit")
            //     break
            case "bulkDelete":
                deleteVoiceComplianceGroupAccordian(
                    props.user,
                    selectedAccordians
                )
                break
        }
    }

    useEffect(() => {
        getVoiceComplianceList(props.user.userid)
    }, [])

    const filterVoiceComplianceList = (voiceComplianceList, currentText) => {
        if (currentText.trim() === "") {
            getVoiceComplianceList()
            return
        }

        const result = voiceComplianceList.filter((obj) => {
            return Object.keys(obj).some((key) =>
                key.trim().toLowerCase().includes(currentText.toLowerCase())
            )
        })
        setVoiceComplianceList(result)
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            filterVoiceComplianceList(voiceComplianceList, currentText)
        }, 500)
        return () => clearTimeout(timer)
    }, [currentText])

    const filterVoiceCompliance = (value) => {
        setCurrentText(value)
    }

    const selectedAccordiansValue = (value, operation) => {
        if (operation === "Add") {
            setSelectedAccordians([...selectedAccordians, value])
        } else if (operation === "Remove") {
            setSelectedAccordians((prevState) =>
                prevState.filter((item) => item !== value)
            )
        }
    }
    const handleSubmit = (data) => {
        addVoiceCompliance(props.user.userid,data)
        .then((res) => {
            setIsAddDialogOpen(false)
            notify("Updated successfully", "success")
            getVoiceComplianceList()
        })
        .catch(async (error) => {
            const value = await error
            setIsAddDialogOpen(false)
            notify(value, "error")
            //console.log("error", value)

        })
    }



    useEffect(()=>{
        if(voiceComplianceList.length){
            const result = voiceComplianceList?.map(ele => Object.keys(ele).join(', '));
            setAlreadyExistingKey(result);
        }
       
    },[voiceComplianceList])
    

    return (
        <>
            {isLoading ? (
                <View
                    UNSAFE_className={
                        isLoading ? "display-block" : "display-none"
                    }
                    UNSAFE_style={{
                        paddingTop: "10%",
                        paddingLeft: "45%",
                        position: "absolute",
                        zIndex: "10",
                    }}
                >
                    <ProgressCircle
                        aria-label="Loading…"
                        isIndeterminate
                        size="M"
                    />
                </View>
            ) : null}
            <div align="center">
                <Heading variant="pageTitle">Voice Compliance</Heading>
            </div>
            <View
                UNSAFE_style={{
                    padding: "25px",
                    border: "0.8px solid",
                    borderRadius: "0.7em",
                    marginTop: "30px",
                    width: "90vw",
                    margin: "0 auto",
                }}
            >
                <Content
                    aria-label="Example ListView for empty state"
                    minWidth="size-6000"
                    minHeight="size-3000"
                >
                    <Flex
                        direction="row"
                        gap="size-100"
                        UNSAFE_className="searchMobile"
                    >
                        <SearchField
                            isQuiet
                            label="Search"
                            labelPosition="side"
                            labelAlign="start"
                            contextualHelp={
                                <ContextualHelp variant="info">
                                    <Heading>Search tips</Heading>
                                    <Content>
                                        Search works on agent ldap as well as
                                        the group name.
                                    </Content>
                                </ContextualHelp>
                            }
                            onClear={() => setCurrentText("")}
                            onChange={filterVoiceCompliance}
                            aria-label="Search"
                            value={currentText}
                            width={"30%"}
                        />
                        {props.permissions.includes(
                            PheonixRoles.voiceComplainceAdmin
                        ) && (
                            <ActionGroup
                                width={"70%"}
                                justifySelf={"flex-end"}
                                alignItems="end"
                                onAction={(value) => performAction(value)}
                                disabledKeys={
                                    Object.keys(selectedAccordians).length
                                        ? [""]
                                        : ["bulkDelete"]
                                }
                            >
                                <Item key="add">+ Add</Item>
                                {/* <Item key="bulkDelete">Delete</Item> */}
                            </ActionGroup>
                        )}
                    </Flex>

                    <Flex marginTop={"size-300"} UNSAFE_className="listItems">
                        {voiceComplianceList && (
                            <VoiceList
                                data={voiceComplianceList}
                                isAdmin={isAdmin}
                                deleteApi={deleteVoiceComplianceMethod}
                                userid={props.user}
                                handleSubmit={handleSubmit}
                                ActionProcess={(value, ldap, data) =>
                                    performAction(value, ldap, data)
                                }
                                // getVoiceComplianceList={() =>
                                //     getVoiceComplianceList()
                                // }
                                // selectedAcc={(value, operation) =>
                                //     selectedAccordiansValue(value, operation)
                                // }
                            />
                        )}
                    </Flex>
                    {!voiceComplianceList.length && returnEmptyState()}
                </Content>
            </View>

            {/* {isAddDialogOpen && (
                <AddVoiceCompliance
                    isDialogOpened={isAddDialogOpen}
                    editData={editData.current}
                    operation={operation}
                    // allAgentsList={AgentList}
                    // groups={groupsList}
                    // cloneInfo={cloneInfo}
                    userid={props.user.userid}
                    handleCloseDialog={() => setIsAddDialogOpen(false)}
                    getVoiceComplianceList={getVoiceComplianceList}
                />
            )} */}
            {isAddDialogOpen && (
                <AddVoiceCompliancee
                    isDialogOpened={isAddDialogOpen}
                    editData={editData.current}
                    operation={operation}
                    alreadyExistingKey={alreadyExistingKey}
                    handleSubmit = {(data) => handleSubmit(data)}
                    userid={props.user.userid}
                    handleCloseDialog={() => setIsAddDialogOpen(false)}
                    getVoiceComplianceList={getVoiceComplianceList}
                />
            )}
        </>
    )
}

export default connect(mapStateToProps)(VoiceCompliance)
