import {
    USER_INFO,
    USER_LOGOUT,
    SUPPORT_INDEX,
    MAIN_INDEX,
    SET_MENU,
    USER_AUTHORIZATION,
    ADOBE_IO_TOKEN
} from '../constants/constants';

export function updateUserInfo(userInfo) {
    return {
        type: USER_INFO,
        userInfo,
    };
}

export function logout() {
    return {
        type: USER_LOGOUT,
    };
}

export function navigateToIndex(mainIndex, supportIndex) {
    return {
        type: SUPPORT_INDEX,
        supportIndex,
        mainIndex,
    };
}

export function changeMenuIndex(index) {
    return {
        type: MAIN_INDEX,
        index,
    };
}
export function setMenu(menuData, menuKeys) {
    return {
        type: SET_MENU,
        menuData,
        menuKeys
    };
}

export function updateUserPermissions(authorizedAccess) {
    return {
        type: USER_AUTHORIZATION,
        authorizedAccess,
    };
}

export function setIMSToken(token){
    return {
        type:ADOBE_IO_TOKEN,
        token:token
    }
}


