
import { CARE_PASSWORD, SERVER_URL, CARE_USERNAME } from '../../constants/constants';
import { get, post } from './methods';

export async function getSpamContacts() {
    return await get({
        url: `${SERVER_URL}/api/v1/spam/get/all`,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true
    });
}

export async function addSpamContacts(data, username) {
    const response = await post({
        url: `${SERVER_URL}/api/v1/spam/add`,
        json: data,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        queryParams: {
            ldap: username
        }
    });
    return response;
}

export async function deleteSpamContacts(contactNumbers, username) {
    const response = await post({
        url: `${SERVER_URL}/api/v1/spam/delete`,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        queryParams: {
            phoneNumbers: contactNumbers,
            ldap: username
        }
    });
    return response;
}

// Get all Service list
export async function getServiceList() {
    return await get({
        url: `${SERVER_URL}/api/v1/testServiceData/get/all`,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true
    });
}

export async function addServiceTestData(data,username) {
    const {service, endpoint, expectedResponse} = data; 
    const value = {service, endpoint, expectedResponse: JSON.parse(expectedResponse)};
    console.log({value});
    const auth = btoa(`${CARE_USERNAME}:${CARE_PASSWORD}`);
    return fetch( `${SERVER_URL}/api/v1/testServiceData/add?`+new URLSearchParams({
        ldap:username
    }).toString(),{
        method: 'POST',
        mode:'cors',
        credentials:'include',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${auth}`
        },
        body:JSON.stringify(value)       
    }).then((response) => response.json()).catch((err)=>err);
}

export async function deleteServiceTestData(data, username) {
    const auth = btoa(`${CARE_USERNAME}:${CARE_PASSWORD}`);
    return fetch( `${SERVER_URL}/api/v1/testServiceData/delete?`+new URLSearchParams({
        ldap:username
    }).toString(),{
        method: 'POST',
        mode:'cors',
        credentials:'include',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'Authorization': `Basic ${auth}`
        },
        body: new URLSearchParams({serviceEndpoint:data})       
    }).then((response) => response.json()).catch((err)=>err);
}


// Get all Service list
export async function getproductCodeList() {
    return await get({
        url: `${SERVER_URL}/api/v1/srsProductFamily/get/all`,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true
    });
}

export async function addproductCodeTestData(data,username) {
    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const auth = btoa(`${CARE_USERNAME}:${CARE_PASSWORD}`);
    return fetch( `${SERVER_URL}/api/v1/srsProductFamily/add?`+new URLSearchParams({
        ldap:username
    }).toString(),{
        method: 'POST',
        mode:'cors',
        credentials:'include',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'Authorization': `Basic ${auth}`
        },
        body:formBody       
    }).then((response) => response.json()).catch((err)=>err);
}

export async function deleteproductCodeTestData(data, username) {
    const auth = btoa(`${CARE_USERNAME}:${CARE_PASSWORD}`);
    return fetch( `${SERVER_URL}/api/v1/srsProductFamily/delete?`+new URLSearchParams({
        ldap:username
    }).toString(),{
        method: 'POST',
        mode:'cors',
        credentials:'include',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'Authorization': `Basic ${auth}`
        },
        body: new URLSearchParams({productCodes:data})       
    }).then((response) => response.json()).catch((err)=>err);
}