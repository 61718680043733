import React, { useState, useEffect } from "react";
import { getAllGroupNames, getSelectedGroupConfig } from "../../../utilities/api/phoenix-api";
import { ActionButton, DialogTrigger, Flex, Heading, View, Dialog, Divider, Content, ButtonGroup, Button, ComboBox, Item, TextField } from "@adobe/react-spectrum";
import { notify } from "../../../constants/constants";
import Delete from "@spectrum-icons/workflow/Delete"
import Add from "@spectrum-icons/workflow/Add"


function GroupCloneModal(props){
    const [activeStep, setActiveStep] = useState("1");
    const [isLoading, setIsLoading] = useState(false);
    const [groupNameList, setGroupNameList] = useState([]);
    const [selectedGroupName, setSelectedGroupName] = useState("");
    const [newGroupName, setNewGroupName] = useState("");
    const [selectedConfigs, setSelectedConfigs] = useState([]);
    const [openedSection, setOpenedSection] = useState("");

    const sideArrayObject = { key: "", value: "" };


    
    // getSelected Group Configs
        const getSelectedGroupsConfig = (groupName) => {
            setIsLoading(true);
           if(groupName){
            getSelectedGroupConfig(props.user , groupName?.trim()).then((config)=>{
                const updatedMapping = config.map((confObject)=>{
                    const {agent_group_name,config_key,config_values} = confObject;
                    const modifiedConfValue = config_values.map((conf)=>{
                        const modifiedConf = Object.keys(conf).map(key => {return {keys:key,value:conf[key]}});
                        return modifiedConf;
                    })
                    return {agent_group_name, config_key, config_values:modifiedConfValue}
                });
                setSelectedConfigs(updatedMapping);
            }).catch((err)=>{
                console.log({err});
                notify("Error in getting selected config","error");
            }).finally(()=>{
                setIsLoading(false);
            });
           }
        }
    // end
    const setSelectedGroupOptions = (value) => {
        const [groupName] = groupNameList.filter((group)=>group.id == value).map(value=>value.name);
        setSelectedGroupName(groupName)
        getSelectedGroupsConfig(groupName);
    }
    /**
     * Get All group names
     * @param {*} ldap 
     */
    const getAllGroup = (ldap) => {
        setIsLoading(true);
        getAllGroupNames(ldap).then((groupNames)=>{
            // Converting  "[dx_english]" to array
            const withoutBrackets = groupNames.slice(1, -1);
            const processedArray = withoutBrackets.split(",").map((name, id)=> {
                return {id,name};
            });
            // conversion end
            setGroupNameList(processedArray);
        }).catch((err)=>{
            //console.log({err});
            notify("Error in getting config list","error");
        }).finally(()=>{
            setIsLoading(false);
        });
    };

    const checkMainValue = (config_value) => {
        const value = config_value.map((ele)=>{
            return ele.map(objectlist => {
                if(objectlist.keys)
                    return {[objectlist.keys]:objectlist.value}
                else 
                    return null;
            }).filter(ele => ele !== null ).reduce(function(result, current) {
                return Object.assign(result, current);
              }, {});
        });
        return value;
    };

    const handelClose = (data=null) => {
       if(data && data.length) {
        const mappedData = data.map(ele=>{ 
            const value = checkMainValue(ele.config_values)    
            return{...ele,config_values:value , agent_group_name: newGroupName}
        })
        props.handleCloseDialog(false,mappedData);
       }else{
        props.handleCloseDialog(false);
       }
    }

    useEffect(()=>{
        getAllGroup(props.user);
    },[]);

    const changeKey = (index, value) => {
        const update = JSON.parse(JSON.stringify(selectedConfigs));
        update[index].config_key = value;
        setSelectedConfigs(update);
    };

    const changeConfigValue = (index, index2, index3, key, value) => {
        const update = JSON.parse(JSON.stringify(selectedConfigs));
        update[index].config_values[index2][index3][key] = value;
        setSelectedConfigs(update);
    };

    const deleteConfigvalue = (index, index2, index3) => {
        const update = JSON.parse(JSON.stringify(selectedConfigs));
        update[index].config_values[index2].splice(index3, 1);
        setSelectedConfigs(update);
    }

    const addconfigKeys = (index, index2) => {
        const update = JSON.parse(JSON.stringify(selectedConfigs));
        update[index].config_values[index2].push(sideArrayObject);
        setSelectedConfigs(update);
    }

    const deleteKey = (index) => {
        const update = JSON.parse(JSON.stringify(selectedConfigs));
        update.splice(index, 1);
        setSelectedConfigs(update);
    }

    // JSX for Group clone 
    return (
        <DialogTrigger
            type="modal"
            mobileType="modal"
            isOpen={props.isDialogOpened}
            defaultOpen={false}
        >
            <ActionButton isHidden isQuiet></ActionButton>
            <Dialog size="L">
                <Heading>Clone Group Config</Heading>
                <Divider />
                <Content>
                    {/* {isLoading && (
                        <ProgressCircle aria-label="Loading…" isIndeterminate />
                    )} */}
                    {(activeStep=="1") && (
                        <Flex direction={'row'} gap={"size-1000"}>
                            <View>
                                <ComboBox
                                    label="Pick group to clone"
                                    defaultItems={groupNameList}
                                    defaultInputValue={selectedGroupName}
                                    onSelectionChange={(value)=>setSelectedGroupOptions(value)}
                                    isDisabled={isLoading && selectedGroupName}
                                    width={'100%'}
                                    >
                                    {item => <Item key={item.id}>{item.name}</Item>}
                                </ComboBox>
                            </View>
                            <View>
                                <TextField
                                    label="New Group Name" 
                                    value={newGroupName}
                                    onChange={(value) => {
                                        const valueWithNoSpace = value.replace(/\s/g, '');
                                        setNewGroupName(valueWithNoSpace)}}
                                    width={'100%'}
                                    isDisabled={selectedGroupName===""}
                                />
                            </View>
                        </Flex>
                    )}
                    { (activeStep=="2") && (
                        <Flex direction={'column'} gap={'size-100'}>
                        {selectedConfigs.length && selectedConfigs.map((configMain, mainIndex) => (
                            <View>
                                <section className={openedSection == configMain.config_key?"configKeySection displayDetail": "configKeySection"} onClick={()=>setOpenedSection(configMain.config_key)}>
                                    <Flex justifyContent={'space-between'} marginBottom={'size-100'}>
                                        <TextField 
                                            aria-label="congifKeys"                                    
                                            value={configMain.config_key}
                                            onChange={(value)=> changeKey(mainIndex,value)}
                                        />
                                        <View right="size-0" UNSAFE_className="noBorder">
                                            <Button alignSelf={'flex-end'} onPress={()=>deleteKey(mainIndex)}><Delete /></Button>
                                        </View>
                                    </Flex>
                                    <Divider size="M" UNSAFE_className="firstHr"/>
                                    {
                                        configMain.config_values && configMain.config_values
                                        .map((
                                            configValues, configValueIndex
                                        )=>(
                                            <section className="configValues">
                                                {configValues.length && configValues.map((obj, objIndex)=>(
                                                <Flex direction={'row'} alignItems={'center'} justifyContent={'center'} gap={'size-100'} >
                                                    <View marginBottom={'10px'}>
                                                        <TextField 
                                                            aria-label="keys"
                                                            value={obj.keys}
                                                            onChange={(value)=>changeConfigValue(mainIndex,configValueIndex,objIndex,"keys",value)}
                                                        />
                                                    </View>
                                                    <strong>:</strong>
                                                    <View marginBottom={'10px'}>
                                                        <TextField 
                                                            aria-label="value"
                                                            value={obj.value}
                                                            onChange={(value)=>changeConfigValue(mainIndex,configValueIndex,objIndex,"value",value)}
                                                        />
                                                    </View>
                                                    <View width={'15%'} UNSAFE_className="noBorder">
                                                    {(configValues.length > 1) && <Button aria-label="removeKeyValue" variant="secondary" staticColor="black" style="fill" onPress={()=>deleteConfigvalue(mainIndex,configValueIndex,objIndex)}><Delete/></Button>}
                                                    {(configValues.length - 1 == objIndex) && <Button aria-label="addKeyValue" variant="secondary" staticColor="black" style="fill" onPress={()=>addconfigKeys(mainIndex,configValueIndex)} ><Add /></Button>}

                                                    </View>
                                                </Flex>   
                                                
                                                ))}
                                                {(configMain.config_values.length !== configValueIndex+1) && <Divider size="M" UNSAFE_className="otherHr"/> }
                                            </section>
                                        ))
                                    }

                                </section>
                            </View>
                            )) }
                        </Flex>
                    )}
                </Content>
                <Divider />
                   {activeStep=="1" && (
                        <ButtonGroup orientation="horizontal" align="end">
                            <Button variant="secondary" onPress={handelClose}>Close</Button>
                            <Button variant="secondary" isDisabled={!newGroupName || selectedGroupName==="" || !selectedConfigs.length} onPress={()=>setActiveStep("2")}>Next</Button>
                        </ButtonGroup>
                   )}
                    {activeStep=="2" && (
                        <ButtonGroup orientation="horizontal" align="end">
                            <Button variant="secondary" onPress={()=>setActiveStep("1")}>Previous</Button>
                            <Button variant="secondary" onPress={handelClose}>Close</Button>
                            <Button variant="secondary" onPress={()=>handelClose(selectedConfigs)}>Submit</Button>
                        </ButtonGroup>
                   )}
            </Dialog>
        </DialogTrigger>
    );
}

export default GroupCloneModal;