import React, { useEffect, useState, useRef } from 'react';
import Delete from '@spectrum-icons/workflow/Delete';
import Draw from '@spectrum-icons/workflow/Draw';
import 'react-schedule-job/dist/index.css';
import { connect } from 'react-redux';
import './style.css';
import {
  Flex, lightTheme, Provider, ActionGroup, Item, Text,
  Content, IllustratedMessage, Heading, TableView, TableHeader, Column,
  TableBody, Cell, Row, ProgressCircle, TooltipTrigger, Tooltip, ActionButton, useDragAndDrop} from '@adobe/react-spectrum';
import { ACTION_TYPE, CASE_ROUTE, CONDITION_TYPE, RULE_STATUS, RULE_TYPE, SRS_RULES } from '../../constants/constants';
import DeleteSRSRules from './DeleteSRSRules';
import AddSRSRules from './AddSRSRules';
import { addSRSRule, getAllSRSRules, deleteSRSRule, editSRSRule, reorderSRSRule } from '../../utilities/api/srsRules-api';
import { renderEmptyState } from '../../utilities/components/CommonUseComponents';

function mapStateToProps(state) {
  return {
    user: state.login.userInfo,
    permissions: (state.login.authorizedAccess != null) ? state.login.authorizedAccess.permissions : []
  };
}
const SRSRules = (props) => {
  const [allContacts, setAllContacts] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedMobiles, setSelectedMobiles] = useState(new Set());
  const [operation, setOperation] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isdeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [createRecordError, setCreateRecordError] = useState('');

  const columns = [
    {name: "Rule Id", key:"ruleId", minWidth:120},
    { name: 'Rule Name', key: 'ruleName', minWidth:120 },
    { name: 'Rule type', key: 'ruleType', minWidth:120 },
    { name: 'Condition Type', key: 'conditionType', minWidth:160 },
    { name: 'Condition', key: 'condition', minWidth:270 },
    { name: 'Action', key: 'action', minWidth:120 },
    { name: 'Sub-Rule', key: 'subRules', minWidth:120 },
    { name: 'Else Action', key: 'elseAction', minWidth:150 },
    { name: 'Else Sub-Rule', key: 'elseSubRules', minWidth:120 },
    { name: 'Ent Service', key: 'entitlementService', minWidth:120},
    { name: 'Support Level', key: 'supportLevelService', minWidth:140},
    { name: 'Support Plan', key: 'supportPlanService', minWidth:150},
    { name: 'Deny Reason Code', key:'denyReasonCode', minWidth:170}, 
    { name: 'Case Route', key:'caseRoute', minWidth:140},   
    { name: 'Status', key: 'ruleStatus', minWidth:120 }
  ];

  const columnsForAdmin = [
    {name: "Rule Id", key:"ruleId", minWidth:120},
    { name: 'Rule Name', key: 'ruleName', minWidth:120 },
    { name: 'Rule type', key: 'ruleType', minWidth:120 },
    { name: 'Condition Type', key: 'conditionType', minWidth:160 },
    { name: 'Condition', key: 'condition', minWidth:270 },
    { name: 'Action', key: 'action', minWidth:120 },
    { name: 'Sub-Rule', key: 'subRules', minWidth:120 },
    { name: 'Else Action', key: 'elseAction', minWidth:150 },
    { name: 'Else Sub-Rule', key: 'elseSubRules', minWidth:120 },
    { name: 'Ent Service', key: 'entitlementService', minWidth:120},
    { name: 'Support Level', key: 'supportLevelService', minWidth:140},
    { name: 'Support Plan', key: 'supportPlanService', minWidth:150},
    { name: 'Deny Reason Code', key:'denyReasonCode', minWidth:170},  
    { name: 'Status', key: 'ruleStatus', minWidth:120 },
    { name: 'Case Route', key:'caseRoute', minWidth:140}, 
    { name: 'Operations', key: 'actions', minWidth:140 },
  ];
  const currentContactSelected = useRef('');
  const editData = useRef(false);

  const getKeyFromValue = (value,Constant) => {
    return Object.keys(Constant).filter(key=>Constant[key]==value);
  }

  function getAllContacts() {
    setIsLoading(true);
    getAllSRSRules()
      .then((contacts) => {
        setIsLoading(false);
        setAllContacts(contacts);
        setFilteredContacts(contacts)
      }).catch((error) => {
        if(error){
          setIsLoading(false);
          setErrorMessage('Error occured!');
        }
      })
  }

  function deleteContacts(usernamesToDelete) {
    setIsLoading(true);
    const urlencoded = new URLSearchParams();
    urlencoded.append("ruleIds", usernamesToDelete);
    deleteSRSRule(urlencoded,props.user.userid).then(()=>{
      getAllContacts();
    }).catch((err)=>{
      if(err)
      setIsLoading(false);
    }).finally(()=>{
      setIsLoading(false);
      getAllContacts();
    });
  }

  useEffect(() => {
    getAllContacts();
  }, []);

  const formatDate = (milis) => {

    if (milis == null) {
      return '';
    } else {
      const d = new Date(Number(milis));
      const datestring = '' + d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear() + " " +
        d.getHours() + ":" + d.getMinutes();
      return datestring;
    }
  }

  const performAction = (value, contactNumber) => {
    switch (value) {
      case 'add':
        editData.current = null;
        setIsAddDialogOpen(true);
        setOperation("Add")
        break;
      case 'edit':
        editData.current =  allContacts.find((d) => (d.ruleId == contactNumber));
        setIsAddDialogOpen(true);
        setOperation("Edit")
        break;
      case 'delete':
        currentContactSelected.current = `${contactNumber}`;
        setIsDeleteDialogOpen(true);
        break;
      case 'bulkDelete':
        setIsDeleteDialogOpen(true);
        break;
    }
  }

  const closeDeleteDialog = (isDeletedConfirmed) => {
    setIsDeleteDialogOpen(false);
    if (isDeletedConfirmed) {
      let usernamesToDelete = [currentContactSelected.current];
      if (selectedMobiles == 'all') {
        usernamesToDelete = filteredContacts.map((ele)=> `${ele.ruleId}`);
      } else if (!currentContactSelected.current && selectedMobiles.size > 0) {
        usernamesToDelete = [...selectedMobiles];
        const formattedData = filteredContacts.map((ele,index)=> usernamesToDelete.includes(`${index}`)?`${ele.ruleId}`:null).filter(item => item != null);
        usernamesToDelete = formattedData;
      }
      deleteContacts(usernamesToDelete.join());
    }
  }

  const closeAddContactDialog = (isAddConfirmed, contactData) => {
    setCreateRecordError('');
    setErrorMessage('');
    const urlencoded = new URLSearchParams();
    urlencoded.append("createdBy", props.user.userid);
    urlencoded.append("createdDate", new Date().getTime());
    if(contactData){
      Object.keys(contactData).forEach(key=>{
        if(!contactData[key]){
          delete contactData[key]
        }else{
          urlencoded.append(key, contactData[key]);
        }        
      });
    }
    if (isAddConfirmed) {
      setIsLoading(true);
      if (editData.current !== null && operation === "Edit") {
        editSRSRule(urlencoded, props.user.userid).then(()=>{
          setIsLoading(false);
          setIsAddDialogOpen(false);
          getAllContacts();
          setCreateRecordError('');
          setErrorMessage('');
        }).catch((error)=>{
          if(error){
            setIsLoading(false);
            setCreateRecordError(JSON.stringify(error));
            setErrorMessage('Error occured!');
          }
        })
      }

      if (operation === "Add") {
          addSRSRule(urlencoded,props.user.userid).then(()=>{
              setIsLoading(false);
              setIsAddDialogOpen(false);
              getAllContacts();
              setCreateRecordError('');
              setErrorMessage('');
          }).catch(async (error)=>{
            const value = await error;
            if(value){
              setIsLoading(false);
              setCreateRecordError(value);
              setErrorMessage('Error occured!');
            }
          });
      }
    }else{
      setIsAddDialogOpen(false);
    }
  }

  const createBadgeforSubRules = (dataList) =>{
    return dataList.join(" ,");
  }


  const getCell = (columnKey, item) => {
    switch (columnKey) {
      case 'actions':
        return (
          <Cell>
            <ActionGroup onAction={(value) => performAction(value, item.ruleId)}>
              <Item key="edit">
                <Draw />
              </Item>
            </ActionGroup>
          </Cell>
        );
      case 'createdDate':
        return (
          <Cell>{formatDate(item[columnKey])}</Cell>
        )
      case 'ruleStatus':
          return (
            <Cell>{`${getKeyFromValue(item[columnKey],RULE_STATUS)}`}</Cell>
          );
      case 'action':
        return (
          <Cell>{`${getKeyFromValue(item[columnKey],ACTION_TYPE)}`}</Cell>
        );
      case 'elseAction':
        return (
          <Cell>{`${getKeyFromValue(item[columnKey],ACTION_TYPE)}`}</Cell>
        );
      case 'conditionType':
        return (
          <Cell>{`${getKeyFromValue(item[columnKey],CONDITION_TYPE)}`}</Cell>
      );
      case 'ruleType':
        return (
          <Cell>{`${getKeyFromValue(item[columnKey],RULE_TYPE)}`}</Cell>
      );
      case 'subRules':
        return(
          <Cell>{(item[columnKey]?.length)?createBadgeforSubRules(item[columnKey]):'-' }</Cell>
        );
      case 'elseSubRules':
        return(
          <Cell>{(item[columnKey]?.length)?createBadgeforSubRules(item[columnKey]):'-' }</Cell>
        );
      case 'entitlementService':
        return (
          <Cell>{(item[columnKey])?"Yes":"No"}</Cell>
        );
      case 'supportLevelService':
        return (
          <Cell>{(item[columnKey])?"Yes":"No"}</Cell>
        );
      case 'supportPlanService':
        return (
          <Cell>{(item[columnKey])?"Yes":"No"}</Cell>
        );
      case 'caseRoute':
        return (
          <Cell>{(item[columnKey]?.length)?`${getKeyFromValue(item[columnKey],CASE_ROUTE)}`:'-'}</Cell>
        );                   
      default:
        return (
          <Cell>
            <TooltipTrigger delay={0}>
              <ActionButton width={'100%'} UNSAFE_className="tooltipButton" aria-label={columnKey} isQuiet>{item[columnKey]}</ActionButton>
              <Tooltip>{item[columnKey]}</Tooltip>
            </TooltipTrigger>
          </Cell>
        );
    }
  }

  const reorderList = (data) =>{
    const urlencoded = new URLSearchParams();
    if(data){
      Object.keys(data).forEach(key=>{
        urlencoded.append(key, data[key]);
      });
    }
    urlencoded.append("createdBy", props.user.userid);
    urlencoded.append("createdDate", new Date().getTime());

    setIsLoading(true);
    reorderSRSRule(urlencoded,props.user.userid).then(()=>{
      getAllContacts();
    }).catch(err=>{
      if(err)
      setIsLoading(false);
    });

  }


  const { dragAndDropHooks } = useDragAndDrop({
    getItems(keys) {
      return [...keys].map((key) => {
        const item = filteredContacts[key];
        // Setup the drag types and associated info for each dragged item.
        return {
          'custom-app-type-reorder': JSON.stringify(item),
          'text/plain': item.ruleId
        };
      });
    },
    acceptedDragTypes: ['custom-app-type-reorder'],
    onReorder: async (e) => {
      const { keys, target } = e;
       if(target.dropPosition === 'before'){
        const selectedItemIndex = Array.from(keys).shift();
        const targetRuleId = filteredContacts[selectedItemIndex]?.ruleId;
        const endRuleId = filteredContacts[target.key].ruleId;
        const startRuleId = (target.key == 0)? '':filteredContacts[target.key - 1].ruleId;
        // if endRuleId is larger that 2k do something
        if((startRuleId < 2000 && targetRuleId < 2000 && endRuleId < 2000)||(startRuleId >= 2000 && targetRuleId >= 2000 && endRuleId >= 2000)){
          reorderList({targetRuleId, endRuleId, startRuleId});
        }
        //else sendRuleId data
       }else if (target.dropPosition === 'after'){
        const selectedItemIndex = Array.from(keys).shift();
        const targetRuleId = filteredContacts[selectedItemIndex]?.ruleId;
        const startRuleId = filteredContacts[target.key].ruleId;
        const endRuleId = (target.key == filteredContacts.length-1)? '':filteredContacts[+target.key + 1].ruleId;
        if((startRuleId < 2000 && targetRuleId < 2000 && endRuleId < 2000)||(startRuleId >= 2000 && targetRuleId >= 2000 && endRuleId >= 2000)){
          reorderList({targetRuleId, endRuleId, startRuleId});
        }
       }
    },
    getAllowedDropOperations: () => ['move']
  });

  return (
    <>

      <div className={isLoading ? 'display-block' : 'display-none'}
        style={{ paddingTop: '10%', paddingLeft: '45%', position: 'absolute', zIndex: '10' }}
      >
       {isLoading && <ProgressCircle aria-label="Loading…" isIndeterminate size='L' />}
      </div>

      <div align="center">
        <Heading variant="pageTitle">SRS Rules</Heading>
      </div>

      <div
        style={{
          padding: '25px',
          border: '0.8px solid',
          borderRadius: '0.7em',
          marginTop: '30px',
          width: '90vw',
          margin: '0 auto'
        }}
      >

        <Provider theme={lightTheme}>
          <Flex direction="row" gap="size-100" UNSAFE_className="searchMobile">
            {props.permissions.includes(SRS_RULES) && (
              <ActionGroup width={'100%'} alignItems="end" onAction={(value) => performAction(value, null)} disabledKeys={(selectedMobiles.size || selectedMobiles == 'all') ? [] : ['bulkDelete']}>
                <Item key="add">+ Add</Item>
                <Item key="bulkDelete" ><Delete /><Text>Delete</Text></Item>
              </ActionGroup>
            )} 

          </Flex>
          <Flex width="100%" height="100%" direction="column">
            <TableView aria-label="spam contacts"
              selectionMode={props.permissions.includes(SRS_RULES) ? "multiple" : "none"}
              dragAndDropHooks={dragAndDropHooks}
              selectedKeys={selectedMobiles}
              onSelectionChange={setSelectedMobiles}
              renderEmptyState={renderEmptyState}
              height="70vh"
              overflowMode="wrap"
            >
              <TableHeader columns={props.permissions.includes(SRS_RULES) ? columnsForAdmin : columns}>
                {(column) => (
                  <Column allowsResizing minWidth={column.minWidth} key={column.key} align={column.key === 'actions' ? 'end' : 'start'}>
                    {column.name}
                  </Column>
                )}
              </TableHeader>
              <TableBody>
                {filteredContacts.map((item,index)=> (
                    <Row key={index}>
                    {(columnKey) => getCell(columnKey, item)}
                  </Row>
                ))}
              </TableBody>
            </TableView>
          </Flex>
        </Provider>
      </div>


      {errorMessage && (<div
        style={{
          fontSize: 'large', color: 'red', marginTop: '1%',
        }}
        align="center"
      >
        {' '}
        <b> {errorMessage}</b>
      </div>)}

      {isdeleteDialogOpen &&
        <DeleteSRSRules
          isDialogOpened={isdeleteDialogOpen}
          handleCloseDialog={(isDeletedConfirmed) => closeDeleteDialog(isDeletedConfirmed)}
        />
      }

      {isAddDialogOpen &&
        <AddSRSRules
          isDialogOpened={isAddDialogOpen}
          editData={editData.current}
          operation={operation}
          handleCloseDialog={(isAddConfirmed, submitData) => closeAddContactDialog(isAddConfirmed, submitData)}
          existingData={allContacts}
          recordError = {createRecordError}
        />
      }
    </>
  );
}

export default connect(mapStateToProps)(SRSRules);