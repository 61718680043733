import React, { useEffect, useState, useRef } from "react"
import Delete from "@spectrum-icons/workflow/Delete"
import Draw from "@spectrum-icons/workflow/Draw"
import "react-schedule-job/dist/index.css"
import { connect } from "react-redux"
import "./style.css"
import {
    Flex,
    lightTheme,
    Provider,
    SearchField,
    ActionGroup,
    Item,
    Text,
    Content,
    IllustratedMessage,
    Heading,
    TableView,
    TableHeader,
    Column,
    TableBody,
    Cell,
    Row,
    Tooltip,
    TooltipTrigger,
    ActionButton,
} from "@adobe/react-spectrum"
import {
    getSpamContacts,
    deleteSpamContacts,
    addSpamContacts,
} from "../../../../utilities/api/spamCallers-api"
import DeleteConfirmation from "./DeleteConfirmationDialog"
import AddCaller from "./AddCallerDialog"
import { SPAM_CALLER_ADMIN } from "../../../../constants/constants"
import { ProgressCircle } from "@adobe/react-spectrum"
import DuplicateErrorDialog from "./DuplicateErrorDialog"
import { PheonixRoles } from '../../../../constants/constants';
import { renderEmptyState } from "../../../../utilities/components/CommonUseComponents"


function mapStateToProps(state) {
    return {
        user: state.login.userInfo,
        permissions:
            state.login.authorizedAccess != null
                ? state.login.authorizedAccess.permissions
                : [],
    }
}
const SpamCallersList = (props) => {
    const isAdmin = props?.permissions?.includes(
        PheonixRoles.spamCallersAdmin
    )
    const [allContacts, setAllContacts] = useState([])
    const [filteredContacts, setFilteredContacts] = useState([])
    const [errorMessage, setErrorMessage] = useState("")
    const [currentText, setCurrentText] = useState("")
    const [selectedMobiles, setSelectedMobiles] = useState(new Set())
    const [operation, setOperation] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false)
    const [isdeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    const [showDuplicateError, setShowDuplicateError] = useState(false)

    const columns = [
        { name: "Phone Number", key: "phoneNumber" },
        { name: "Remark", key: "remark" },
        { name: "Created By", key: "createdBy" },
        { name: "Created Date", key: "createdDate" },
    ]

    const columnsForAdmin = [
        { name: "Phone Number", key: "phoneNumber" },
        { name: "Remark", key: "remark" },
        { name: "Created By", key: "createdBy" },
        { name: "Created Date", key: "createdDate" },
        { name: "Actions", key: "actions" },
    ]

    const currentContactSelected = useRef("")
    const editData = useRef(false)

    function getAllContacts() {
        setIsLoading(true)
        getSpamContacts()
            .then((contacts) => {
                setIsLoading(false)
                setAllContacts(contacts)
                setFilteredContacts(contacts)
            })
            .catch((error) => {
                setIsLoading(false)
                //console.log(error)
                setErrorMessage("Error occured!")
            })
    }

    useEffect(() => {
        getAllContacts()
    }, [])

    const filterContacts = (value) => {
        setCurrentText(value)
        const filteredContact = allContacts.filter(
            (contact) =>
                contact.phoneNumber
                    ?.toLowerCase()
                    .includes(value.toLowerCase()) ||
                contact.createdBy
                    ?.toLowerCase()
                    .includes(value.toLowerCase()) ||
                contact.remark?.toLowerCase().includes(value.toLowerCase())
        )
        setFilteredContacts(filteredContact)
    }

    const formatDate = (milis) => {
        if (milis == null) {
            return ""
        } else {
            var d = new Date(Number(milis))
            var datestring =
                "" +
                d.getDate() +
                "-" +
                (d.getMonth() + 1) +
                "-" +
                d.getFullYear() +
                " " +
                d.getHours() +
                ":" +
                d.getMinutes()
            return datestring
        }
    }

    const performAction = (value, contactNumber) => {
        switch (value) {
            case "add":
                editData.current = null
                setIsAddDialogOpen(true)
                setOperation("Add")
                break
            case "edit":
                editData.current = allContacts.find(
                    (d) => d.phoneNumber == contactNumber
                )
                setIsAddDialogOpen(true)
                setOperation("Edit")
                break
            case "delete":
                currentContactSelected.current = contactNumber
                setIsDeleteDialogOpen(true)
                break
            case "bulkDelete":
                setIsDeleteDialogOpen(true)
                break
        }
    }

    function deleteContacts(phoneNumbersToDelete) {
        setIsLoading(true)
        deleteSpamContacts(phoneNumbersToDelete, props.user.userid)
            .then((res) => {
                setIsLoading(false)
                if (res.error) setErrorMessage(res.error)
                else {
                    const allContactsAfterDelete = allContacts.filter(
                        (contact) => {
                            let notDeleted = true
                            for (const el of phoneNumbersToDelete) {
                                if (
                                    contact.phoneNumber.toLowerCase() ===
                                    el.toLowerCase()
                                ) {
                                    notDeleted = false
                                    break
                                }
                            }
                            return notDeleted
                        }
                    )
                    setAllContacts(allContactsAfterDelete)
                    setFilteredContacts(allContactsAfterDelete)
                    setSelectedMobiles(new Set())
                }
            })
            .catch((error) => {
                setIsLoading(false)
                //console.log(error)
                setErrorMessage("Error occured!")
            })
    }

    const closeDeleteDialog = (isDeletedConfirmed) => {
        setIsDeleteDialogOpen(false)
        if (isDeletedConfirmed) {
            let phoneNumbersToDelete = [currentContactSelected.current]
            if (selectedMobiles == "all") {
                phoneNumbersToDelete = allContacts.map(
                    (contact) => contact.phoneNumber
                )
            } else if (
                !currentContactSelected.current &&
                selectedMobiles.size > 0
            ) {
                phoneNumbersToDelete = [...selectedMobiles]
            }
            deleteContacts(phoneNumbersToDelete)
        }
    }

    const checkDuplicateEntry = (phoneNumber) => {
        if (
            allContacts.filter((c) => c.phoneNumber == phoneNumber).length > 0
        ) {
            return true
        } else {
            return false
        }
    }

    const closeAddContactDialog = (isAddConfirmed, contactData) => {
        setIsAddDialogOpen(false)
        if (isAddConfirmed) {
            setIsLoading(true)
            var urlencoded = new URLSearchParams()
            urlencoded.append("phoneNumber", contactData.phoneNumber)
            urlencoded.append("remark", contactData.remark)
            urlencoded.append("createdBy", props.user.userid)
            urlencoded.append("createdDate", new Date().getTime())

            if (editData.current !== null && operation === "Edit") {
                deleteSpamContacts(
                    [editData.current.phoneNumber],
                    props.user.userid
                )
                    .then(() => {
                        addSpamContacts(urlencoded, props.user.userid).then(
                            (res) => {
                                if (res) {
                                    getAllContacts().then(() => {
                                        setIsLoading(false)
                                    })
                                }
                            }
                        )
                    })
                    .catch((error) => {
                        setIsLoading(false)
                        //console.log("add error", error)
                    })
            }

            if (operation === "Add") {
                if (checkDuplicateEntry(contactData.phoneNumber)) {
                    setShowDuplicateError(true)
                    setIsLoading(false)
                } else {
                    addSpamContacts(urlencoded, props.user.userid)
                        .then(() => {
                            setIsLoading(false)
                            getAllContacts()
                        })
                        .catch((error) => {
                            setIsLoading(false)
                            //console.log("add error", error)
                        })
                }
            }
        }
    }

    const getCell = (columnKey, item) => {
        switch (columnKey) {
            case "actions":
                return (
                    <Cell>
                        <ActionGroup
                            onAction={(value) =>
                                performAction(value, item.phoneNumber)
                            }
                        >
                            <Item key="edit">
                                <Draw />
                            </Item>
                            <Item key="delete">
                                <Delete />
                            </Item>
                        </ActionGroup>
                    </Cell>
                )
            case "createdDate":
                return <Cell>{formatDate(item[columnKey])}</Cell>
            case "remark":
                return (
                    <Cell>
                        <TooltipTrigger>
                            <ActionButton
                                UNSAFE_className="actionButtonTextOverflow"
                                isQuiet
                            >
                                <Text>{item[columnKey]}</Text>
                            </ActionButton>
                            <Tooltip>{item[columnKey]}</Tooltip>
                        </TooltipTrigger>
                        {/* <Text>{item[columnKey]}</Text> */}
                    </Cell>
                )
            default:
                return <Cell>{item[columnKey]}</Cell>
        }
    }

    const closeErrorDialog = () => {
        setShowDuplicateError(false)
    }

    return (
        <>
            {isLoading ? (
                <div
                    className={isLoading ? "display-block" : "display-none"}
                    style={{
                        paddingTop: "10%",
                        paddingLeft: "45%",
                        position: "absolute",
                        zIndex: "10",
                    }}
                >
                    <ProgressCircle
                        aria-label="Loading…"
                        isIndeterminate
                        size="L"
                    />
                </div>
            ) : null}

            <div align="center">
                <Heading variant="pageTitle">Spam Callers</Heading>
            </div>

            <div
                style={{
                    padding: "25px",
                    border: "0.8px solid",
                    borderRadius: "0.7em",
                    marginTop: "30px",
                    width: "65vw",
                    margin: "0 auto",
                }}
            >
                <Provider theme={lightTheme}>
                    <Flex
                        direction="row"
                        gap="size-100"
                        UNSAFE_className="searchMobile"
                    >
                        <SearchField
                            isQuiet
                            onClear={() => setCurrentText("")}
                            onChange={filterContacts}
                            placeholder="  Search"
                            value={currentText}
                            width={"20%"}
                        />

                        {props.permissions.includes(SPAM_CALLER_ADMIN) && (
                            <ActionGroup
                                width={"80%"}
                                alignItems="end"
                                onAction={(value) => performAction(value, null)}
                                disabledKeys={
                                    selectedMobiles.size ||
                                    selectedMobiles == "all"
                                        ? []
                                        : ["bulkDelete"]
                                }
                            >
                                <Item key="add">+ Add</Item>
                                <Item key="bulkDelete">
                                    <Delete />
                                    <Text>Delete</Text>
                                </Item>
                            </ActionGroup>
                        )}
                    </Flex>

                    <Flex
                        width="100%"
                        height="100%"
                        direction="column"
                        gap="size-150"
                    >
                        <TableView
                            aria-label="spam contacts"
                            selectionMode={
                                props.permissions.includes(SPAM_CALLER_ADMIN)
                                    ? "multiple"
                                    : "none"
                            }
                            selectedKeys={selectedMobiles}
                            onSelectionChange={setSelectedMobiles}
                            renderEmptyState={renderEmptyState}
                            density="spacious"
                            height="size-3000"
                        >
                            <TableHeader
                                columns={
                                    props.permissions.includes(
                                        SPAM_CALLER_ADMIN
                                    )
                                        ? columnsForAdmin
                                        : columns
                                }
                            >
                                {(column) => (
                                    <Column
                                        key={column.key}
                                        align={
                                            column.key === "actions"
                                                ? "end"
                                                : "start"
                                        }
                                    >
                                        {column.name}
                                    </Column>
                                )}
                            </TableHeader>
                            <TableBody items={filteredContacts}>
                                {(item) => (
                                    <Row key={item.phoneNumber}>
                                        {(columnKey) =>
                                            getCell(columnKey, item)
                                        }
                                    </Row>
                                )}
                            </TableBody>
                        </TableView>
                    </Flex>
                </Provider>
            </div>

            {errorMessage && (
                <div
                    style={{
                        fontSize: "large",
                        color: "red",
                        marginTop: "1%",
                    }}
                    align="center"
                >
                    {" "}
                    <b> {errorMessage}</b>
                </div>
            )}

            {isdeleteDialogOpen && (
                <DeleteConfirmation
                    isDialogOpened={isdeleteDialogOpen}
                    handleCloseDialog={(isDeletedConfirmed) =>
                        closeDeleteDialog(isDeletedConfirmed)
                    }
                />
            )}

            {isAddDialogOpen && (
                <AddCaller
                    isDialogOpened={isAddDialogOpen}
                    editData={editData.current}
                    operation={operation}
                    handleCloseDialog={(isAddConfirmed, contactData) =>
                        closeAddContactDialog(isAddConfirmed, contactData)
                    }
                />
            )}

            {showDuplicateError && (
                <DuplicateErrorDialog
                    isDialogOpened={showDuplicateError}
                    handleCloseDialog={() => closeErrorDialog()}
                />
            )}
        </>
    )
}

export default connect(mapStateToProps)(SpamCallersList)
