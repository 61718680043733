import React, { useEffect, useState } from "react"
import {
    Flex,
    View,
    DialogTrigger,
    ActionButton,
    Button,
    Item,
    TabList,
    TabPanels,
    Tabs,
} from "@adobe/react-spectrum"
import AddQueue from "./AddQueue"
import AddAdbandoned from "./AddAbandoned"
import Layout from "./Layout"
import AddConvWait from "./AddConvWait"
import AddOnQueueUsers from "./AddOnQueueUsers"
import AddVoiceMail from "./AddVoiceMail"
import Close from "@spectrum-icons/workflow/Close"
import { addNewAlert } from "../utilities/api/alertService-api"
import { connect } from "react-redux"
import { submitAlertConfiguration } from "./AlertValidation"
import { ToastContainer } from "react-toastify"

function mapStateToProps(state) {
    return {
        user: state.login.userInfo,
        permissions:
            state.login.authorizedAccess != null
                ? state.login.authorizedAccess.permissions
                : [],
        token: state.login.token,
    }
}

const AddAlert = ({
    isDialogOpened,
    setIsDialogOpened,
    onClose,
    allQueues,
    selectedAlert,
    user,
    operation,
    fetchMonitoringQueueList,
    existingQueues,
    adminPermission
}) => {
    const initialAlert = {
        queueId: "",
        queueName: "",
        channel: "",
        updatedBy: "",
        updatedDate: "",
        alertTypes: {
            wait: false,
            waitAlerts: [
                {
                    id: Math.floor(100000 + Math.random() * 900000),
                    alertOption: {
                        slack: true,
                        email: true,
                    },
                    recipient: {
                        emails: [" "],
                        slacks: [
                            {
                                channelName: "",
                                channelId: "",
                            },
                        ],
                    },
                    attribute: {
                        customAttribute: false,
                        customAttributes: [
                            {
                                attributeValues: [],
                                attributeName: "ORGID",
                                attributeValue: "",
                                items: [],
                            },
                        ],
                    },
                    schedule: {
                        customSchedules: [
                            {
                                timezone: "IST",
                                days: "MONDAY",
                                startTime: "*",
                                threshold: 0,
                                endTime: "*",
                            },
                        ],
                        custom: false,
                        threshold: 0,
                        everyday: true,
                    },
                },
            ],
            onQueueUser: false,
            onQueueUsersAlerts: [
                {
                    id: Math.floor(100000 + Math.random() * 900000),
                    alertOption: {
                        slack: true,
                        email: true,
                    },
                    recipient: {
                        emails: [" "],
                        slacks: [
                            {
                                channelName: "",
                                channelId: "",
                            },
                        ],
                    },
                    attribute: {
                        customAttribute: false,
                        customAttributes: [
                            {
                                attributeValues: [],
                                attributeName: "ORGID",
                                attributeValue: "",
                                items: [],
                            },
                        ],
                    },
                    schedule: {
                        customSchedules: [
                            {
                                timezone: "IST",
                                days: "MONDAY",
                                startTime: "*",
                                threshold: 0,
                                endTime: "*",
                            },
                        ],
                        custom: false,
                        threshold: 0,
                        everyday: true,
                    },
                },
            ],
            voicemail: false,
            voicemailAlerts: [
                {
                    id: Math.floor(100000 + Math.random() * 900000),
                    alertOption: {
                        slack: true,
                        email: true,
                    },
                    recipient: {
                        emails: [" "],
                        slacks: [
                            {
                                channelName: "",
                                channelId: "",
                            },
                        ],
                    },
                    attribute: {
                        customAttribute: false,
                        customAttributes: [
                            {
                                attributeValues: [],
                                attributeName: "ORGID",
                                attributeValue: "",
                                items: [],
                            },
                        ],
                    },
                    schedule: {
                        customSchedules: [
                            {
                                timezone: "IST",
                                days: "MONDAY",
                                startTime: "*",
                                threshold: 0,
                                endTime: "*",
                            },
                        ],
                        custom: false,
                        threshold: 0,
                        everyday: true,
                    },
                },
            ],
            abandoned: false,
            abandonedAlerts: [
                {
                    id: Math.floor(100000 + Math.random() * 900000),
                    alertOption: {
                        slack: true,
                        email: true,
                    },
                    recipient: {
                        emails: [" "],
                        slacks: [
                            {
                                channelName: "",
                                channelId: "",
                            },
                        ],
                    },
                    attribute: {
                        customAttribute: false,
                        customAttributes: [
                            {
                                attributeValues: [],
                                attributeName: "ORGID",
                                attributeValue: "",
                                items: [],
                            },
                        ],
                    },
                    schedule: {
                        customSchedules: [
                            {
                                timezone: "IST",
                                days: "MONDAY",
                                startTime: "*",
                                threshold: 0,
                                endTime: "*",
                            },
                        ],
                        custom: false,
                        threshold: 0,
                        everyday: true,
                    },
                },
            ],
        },
    }

    const ldap = user?.userid
    const [alertService, setAlertService] = useState([initialAlert])
    const [selectedTab, setSelectedTab] = useState("queue")
    const [selectedAlerts, setSelectedAlerts] = useState([])
    const [selectedChannel, setSelectedChannel] = useState()

    useEffect(() => {
        if (operation !== "edit") {
            setSelectedAlerts([])
            // setAlertService([initialAlert]);
        }
    }, [selectedChannel])

    useEffect(() => {
        if (selectedAlert.length) {
            setAlertService(selectedAlert)
            setSelectedChannel(selectedAlert[0].channel)
            const [alert] = selectedAlert
            const { alertTypes } = alert
            const alerts = []
            if (alertTypes.abandoned) alerts.push("abandoned")
            if (alertTypes.wait) alerts.push("wait")
            if (alertTypes.onQueueUser) alerts.push("onQueueUser")
            if (alertTypes.voicemail) alerts.push("voicemail")

            setSelectedAlerts(alerts)
        }
    }, [selectedAlert, selectedChannel])

    useEffect(() => {
        setAlertService((prevState) => [
            {
                ...prevState[0],
                alertTypes: {
                    ...prevState[0].alertTypes,
                    wait: selectedAlerts.includes("wait"),
                    waitAlerts: selectedAlerts.includes("wait")
                        ? prevState[0].alertTypes.waitAlerts
                        : initialAlert.alertTypes.waitAlerts,
                    abandoned: selectedAlerts.includes("abandoned"),
                    abandonedAlerts: selectedAlerts.includes("abandoned")
                        ? prevState[0].alertTypes.abandonedAlerts
                        : initialAlert.alertTypes.abandonedAlerts,
                    onQueueUser: selectedAlerts.includes("onQueueUser"),
                    onQueueUsersAlerts: selectedAlerts.includes("onQueueUser")
                        ? prevState[0].alertTypes.onQueueUsersAlerts
                        : initialAlert.alertTypes.onQueueUsersAlerts,
                    voicemail: selectedAlerts.includes("voicemail"),
                    voicemailAlerts: selectedAlerts.includes("voicemail")
                        ? prevState[0].alertTypes.voicemailAlerts
                        : initialAlert.alertTypes.voicemailAlerts,
                },
            },
        ])
    }, [selectedAlerts])

    const updateAbandonedState = (
        newAbandonedState,
        key = "abandonedAlerts"
    ) => {
        setAlertService((prevState) => [
            {
                ...prevState[0],
                alertTypes: {
                    ...prevState[0].alertTypes,
                    [key]: newAbandonedState,
                },
            },
        ])
    }

    const handleNext = () => {
        const tabKeys = ["queue", ...selectedAlerts]
        const currentIndex = tabKeys.indexOf(selectedTab)
        if (currentIndex < tabKeys.length - 1) {
            setSelectedTab(tabKeys[currentIndex + 1])
        }
    }

    const handlePrevious = () => {
        const tabKeys = ["queue", ...selectedAlerts]
        const currentIndex = tabKeys.indexOf(selectedTab)
        if (currentIndex > 0) {
            setSelectedTab(tabKeys[currentIndex - 1])
        }
    }

    const canGoNext = selectedTab !== selectedAlerts[selectedAlerts.length - 1]
    const canGoPrevious = selectedTab !== "queue"

    useEffect(() => {
        return () => {
            setAlertService(alertService)
        }
    }, [])

    const submitForm = (queueIn) => {
        queueIn.updatedBy = ldap
        queueIn.updatedDate = new Date().getTime()
        queueIn.channel = selectedChannel?.toLowerCase();
        if (submitAlertConfiguration(queueIn)) {
            addNewAlert(queueIn)
                .then((resp) => {
                    // props.close()
                    setIsDialogOpened(false)
                })
                .catch((err) => {
                    console.log({ err })
                })
                .finally(() => {
                    fetchMonitoringQueueList()
                })
        }
    }

    return (
        <DialogTrigger
            type="fullscreenTakeover"
            isOpen={isDialogOpened}
            defaultOpen={false}
            targetRef={document.getElementById("root")}
        >
            <ActionButton isHidden isQuiet></ActionButton>

            <Layout
                onNext={handleNext}
                onPrevious={handlePrevious}
                canGoNext={canGoNext}
                canGoPrevious={canGoPrevious}
                alertService={alertService}
                selectedChannel={selectedChannel}
                submitForm={submitForm}
                adminPermission={adminPermission}
            >
                <Flex direction="column" height="100%">
                    <Flex justifyContent="space-between">
                        <Tabs
                            aria-label="Add Alert"
                            selectedKey={selectedTab}
                            onSelectionChange={setSelectedTab}
                            height="100%"
                            width="100%"
                        >
                            <Flex width={"100%"}>
                                <TabList
                                    width={"100%"}
                                    UNSAFE_className="tabsPadding"
                                >
                                    <Item key="queue">Select Queue</Item>
                                    {selectedAlerts.includes("abandoned") && (
                                        <Item key="abandoned">Abandoned</Item>
                                    )}
                                    {selectedAlerts.includes("wait") && (
                                        <Item key="wait">Wait</Item>
                                    )}
                                    {selectedAlerts.includes("onQueueUser") && (
                                        <Item key="onQueueUser">
                                            OnQueueUsers
                                        </Item>
                                    )}
                                    {selectedAlerts.includes("voicemail") && (
                                        <Item key="voicemail">Voicemail</Item>
                                    )}
                                    {/* <Item key="close">
                                        <Button
                                    UNSAFE_className="pointer"
                                    UNSAFE_style={{ borderRadius: "0px" }}
                                    onPress={onClose}
                                    style="fill"
                                    // minWidth="10px"
                                    // width={"5%"}
                                    variant="negative"
                                    // height={"100%"}
                                >
                                    <Close />
                                </Button>
                                </Item> */}
                                </TabList>
                                <Button
                                    UNSAFE_className="pointer"
                                    UNSAFE_style={{ borderRadius: "0px" }}
                                    onPress={onClose}
                                    style="fill"
                                    // minWidth="10px"
                                    // width={"5%"}
                                    variant="negative"
                                    // height={"100%"}
                                >
                                    <Close />
                                </Button>
                            </Flex>
                            <TabPanels>
                                <Item key="queue">
                                    <View>
                                        <AddQueue
                                            alertService={alertService}
                                            setAlertService={setAlertService}
                                            allQueues={allQueues}
                                            selectedAlerts={selectedAlerts}
                                            setSelectedAlerts={
                                                setSelectedAlerts
                                            }
                                            selectedChannel={selectedChannel}
                                            setSelectedChannel={
                                                setSelectedChannel
                                            }
                                            operation={operation}
                                            existingQueues={existingQueues}
                                            adminPermission={adminPermission}
                                        />
                                    </View>
                                </Item>
                                {selectedAlerts.includes("abandoned") && (
                                    <Item key="abandoned">
                                        <View>
                                            <AddAdbandoned
                                                alertName="abandoned"
                                                abandonedState={
                                                    alertService[0].alertTypes
                                                        .abandonedAlerts
                                                }
                                                updateAbandonedState={
                                                    updateAbandonedState
                                                }
                                                adminPermission={adminPermission}
                                            />
                                        </View>
                                    </Item>
                                )}
                                {selectedAlerts.includes("wait") && (
                                    <Item key="wait">
                                        <View>
                                            <AddConvWait
                                                alertName="wait"
                                                abandonedState={
                                                    alertService[0].alertTypes
                                                        .waitAlerts
                                                }
                                                updateAbandonedState={(value) =>
                                                    updateAbandonedState(
                                                        value,
                                                        "waitAlerts"
                                                    )
                                                }
                                                adminPermission={adminPermission}
                                            />
                                        </View>
                                    </Item>
                                )}
                                {selectedAlerts.includes("onQueueUser") && (
                                    <Item key="onQueueUser">
                                        <View>
                                            <AddOnQueueUsers
                                                alertName="onQueueUser"
                                                abandonedState={
                                                    alertService[0].alertTypes
                                                        .onQueueUsersAlerts
                                                }
                                                updateAbandonedState={(value) =>
                                                    updateAbandonedState(
                                                        value,
                                                        "onQueueUsersAlerts"
                                                    )
                                                }
                                                adminPermission={adminPermission}
                                            />
                                        </View>
                                    </Item>
                                )}
                                {selectedAlerts.includes("voicemail") && (
                                    <Item key="voicemail">
                                        <View>
                                            <AddVoiceMail
                                                alertName="voicemail"
                                                abandonedState={
                                                    alertService[0].alertTypes
                                                        .voicemailAlerts
                                                }
                                                updateAbandonedState={(value) =>
                                                    updateAbandonedState(
                                                        value,
                                                        "voicemailAlerts"
                                                    )
                                                }
                                                adminPermission={adminPermission}
                                            />
                                        </View>
                                    </Item>
                                )}
                            </TabPanels>
                        </Tabs>
                    </Flex>
                </Flex>
                <ToastContainer enableMultiContainer containerId="modalToast" />
            </Layout>
        </DialogTrigger>
    )
}

export default connect(mapStateToProps)(AddAlert)
