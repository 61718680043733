import {
    Flex,
    View,
    Checkbox,
    NumberField,
    TimeField,
    ContextualHelp,
    ComboBox,
    Item,
    TextField,
    CheckboxGroup,
    Text,
    Button,
    DialogTrigger,
    AlertDialog,
    Heading,
    Content,
    RadioGroup,
    Radio,
    Badge,
    Dialog,
} from "@adobe/react-spectrum"
import React, { useEffect, useState } from "react"
import { Time } from "@internationalized/date"
import { Divider, ActionButton } from "@adobe/react-spectrum"
import {
    addNewAlert,
    getMonitoringQueuesNew,
    updateAlert,
} from "../utilities/api/alertService-api"
import { EMAIL_REGEX, URL_REGEX, ALERT_TYPES } from "../constants/constants"
import { getTimezones } from "../utilities/api/admin-api"
// import Timezone from '../utilities/components/purecloud/Timezone';
import { connect } from "react-redux"
import AlertOptions from "./AlertOptions"
// import { EmailChallengeTransaction } from '@okta/okta-auth-js';
import {
    Accordion,
    AccordionItem,
} from "../utilities/components/Accordion/Accordion"
import Add from "@spectrum-icons/workflow/Add"
import Delete from "@spectrum-icons/workflow/Delete"
import Close from "@spectrum-icons/workflow/Close"
import CloseCircle from "@spectrum-icons/workflow/CloseCircle"

function isValidTimeRanges(startTime1, endTime1, startTime2, endTime2) {
    //console.log({startTime1, endTime1, startTime2, endTime2});
    const startDate1 = new Date("1/1/1999 " + startTime1)
    const endDate1 = new Date("1/1/1999 " + endTime1)
    const startDate2 = new Date("1/1/1999 " + startTime2)
    const endDate2 = new Date("1/1/1999 " + endTime2)
    //console.log(`Start1 end2  ${(startDate1 < endDate2)}`);
    //console.log(`End1 start2  ${(endDate1 > startDate2)}`);
    return startDate1 < endDate2 && endDate1 > startDate2 ? false : true
}

function areSomeCommonDays(arr1, arr2) {
    if (!arr1.length || !arr1.length) return false
    if (arr1.includes("all") || arr1.includes("all")) return true
    return arr1.filter((value) => arr2.includes(value)).length > 0
}

function mapStateToProps(state) {
    return {
        user: state.login.userInfo,
        permissions:
            state.login.authorizedAccess != null
                ? state.login.authorizedAccess.permissions
                : [],
    }
}

const EditMonitorQueueList = (props) => {
    const timezones = [
        { id: "IST", name: "Indian Standard Time (IST)" },
        { id: "GMT", name: "Greenwich Mean Time (GMT)" },
        { id: "JST", name: "Japan Standard Time (JST)" },
        { id: "PST", name: "Pacific Standard Time (PST)" },
    ]
    const QueueAlertItem = {
        queueId: "",
        queueName: "",
        updatedBy: "",

        alertTypes: {
            onQueueUsersAlertOptions: {
                slack: true,
                email: true,
            },
            convWait: true,
            convWaitAlerts: [
                {
                    convWaitId: Math.floor(
                        100000 + Math.random() * 900000
                    ),
                    convWaitAlertOption: {
                        slack: true,
                        email: true,
                    },
                    convWaitSchedules: [
                        {
                            timezone: "IST",
                            days: "",
                            startTime: "*",
                            endTime: "*",
                            convWaitConditions: 0,
                        },
                    ],
                    convWaitEveryday: true,
                    convWaitAttribute: false,
                    globalAlert: true,
                    convWaitAttributes: [
                        {
                            attributeName: "",
                            attributeValue: "",
                            attributeValues: [],
                        },
                    ],
                    customAlert: false,
                    alertInfo: {
                        slackWebhooks: [],
                        emailRecipients: [],
                    },
                    convWaitCustom: false,
                    convWaitConditions: "60",
                },
            ],
            voiceMail: false,
            userThresholdSchedules: [
                {
                    timezone: "IST",
                    days: "all",
                    startTime: "*",
                    endTime: "*",
                    onQueueUserThreshold: 0,
                },
            ],
            convAbandoned: true,
            voiceMailAlertOptions: {
                slack: true,
                email: true,
            },

            onQueueUsers: false,
        },
        channel: "",
        alertInfo: {
            slackWebhooks: [],
            emailRecipients: [],
        },
        updatedDate: "",
    }

    // const dummyQueue = {
    //     queueId: 'abd123',
    //     queueName: 'test queue',
    // }

    // const [queue, setQueue] = useState(props.queue)
    const [queue, setQueue] = useState(props.queue)
    const [queueList, setQueueList] = useState([])
    const [channel, setChannel] = useState(
        props.queue.channel ? props.queue.channel : "call"
    )
    const channelList = [
        { id: "1", name: "call" },
        { id: "2", name: "chat" },
        { id: "3", name: "case" },
    ]
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [addSchedule, setAddSchedule] = useState(false)
    const [disableSubmit, setDisableSubmit] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [timezonesValue, setTimezonesValue] = useState(timezones)
    const [cnvType, setCnvType] = useState(
        props.queue.alertTypes?.convWaitCustom ? "custom" : "everyDay"
    )
    const [selectCustomAttribute, setSelectCustomAttribute] = useState(false)
    const [selectedCustomAlert, setSelectedCustomAlert] = useState(["global"])
    const [customValues, setCustomValues] = useState([])
    const [value, setvalue] = useState("")
    const [callWaitEmail, setCallWaitEmail] = useState("")
    const [callWaitSlack, setCallWaitSlack] = useState("")
    const [emailDl, setEmailDl] = useState("")
    const [slackWebhook, setSlackWebhook] = useState("")
    const [isDialogOpen, setIsDialogOpen] = useState(false)

    const [alertStates, setAlertStates] = useState(
        queue.alertTypes?.convWaitAlerts.map(() => ({
            selectCustomAttribute: false,
        }))
    )

    const toggleSelectCustomAttribute = (index) => {
        const newQueue = { ...queue }
        newQueue.alertTypes.convWaitAlerts[index].convWaitAttribute = !newQueue
            .alertTypes.convWaitAlerts[index].convWaitAttribute
        setQueue(newQueue)
    }

    const updateAlertOptions = (queue) => {
        const queueData = { ...queue }
        ALERT_TYPES.forEach((key) => {
            const optionKey = `${key}AlertOptions`
            if (!queueData.alertTypes[optionKey]) {
                if (queueData.alertTypes[key]) {
                    queueData.alertTypes[optionKey] = {
                        slack: true,
                        email: true,
                    }
                } else {
                    queueData.alertTypes[optionKey] = {
                        slack: false,
                        email: false,
                    }
                }
            }
        })
        setQueue(queueData)
    }

    const compareQueueNames = (a, b) => {
        if (a.name < b.name) {
            return -1
        }
        if (a.name > b.name) {
            return 1
        }
        return 0
    }

    useEffect(() => {
        getMonitoringQueuesNew()
            .then((resp) => {
                setQueueList(resp.sort(compareQueueNames))
            })
            .catch((err) => {
                console.log({ err })
            })
        getTimezones("timezones")
            .then((resp) => {
                const mappedTimezone = resp.map((tz) => {
                    const formattedData = {
                        ...tz,
                        id: tz.abbr,
                        name: `${tz.name} (${tz.abbr})`,
                    }
                    return formattedData
                })
                setTimezonesValue(mappedTimezone)
            })
            .catch((err) => {
                console.log(err)
            })
        console.log({ props }, props.queue)
        if (props.queue) {
            updateAlertOptions(props.queue)
            setQueue(props.queue)
        } else {
            setQueue(QueueAlertItem)
        }
        console.log(props, queue.queueId)
    }, [])

    const updateQueueData = (key, value) => {
        // console.log({ key, value });
        if (isSubmitted) {
            setIsSubmitted(false)
            setDisableSubmit(false)
        }
        setQueue({ ...queue, [key]: value })
    }

    const checkIfEnableAddNewScheduled = () => {
        // let checkValidity = queue.alertTypes.userThresholdSchedules.filter((element) => element.days=='all');
        // (checkValidity.length)?setAddSchedule(true):setAddSchedule(false);
        for (const [
            ind,
            th,
        ] of queue.alertTypes.userThresholdSchedules.entries()) {
            // console.log(ind)
            if (th.days === "all") {
                if (th.startTime === "all" && th.endTime === "all") {
                    return setAddSchedule(true)
                }
            }
        }
        setAddSchedule(false)
    }

    useEffect(() => {
        // console.log({ queue });
        if (queue?.queueId?.length && isSubmitted) {
            setIsSubmitted(false)
            setDisableSubmit(false)
        }
        if (!Object.keys(queue).includes("channel")) {
            console.log("Channel not here")
            setChannel("call")
            updateQueueData("channel", "call")
        }
        // checkIfEnableAddNewScheduled()
    }, [queue])

    const updateQueueAlertData = (key, value) => {
        const alertObject = queue.alertTypes
        alertObject[key] = value
        if (value) {
            if (alertObject[key + "AlertOptions"]) {
                alertObject[key + "AlertOptions"].email = value
                alertObject[key + "AlertOptions"].slack = value
            } else {
                alertObject[key + "AlertOptions"] = {
                    email: true,
                    slack: true,
                }
            }
        } else {
            if (!alertObject[key + "AlertOptions"]) {
                alertObject[key + "AlertOptions"] = {
                    email: false,
                    slack: false,
                }
            } else {
                alertObject[key + "AlertOptions"].email = false
                alertObject[key + "AlertOptions"].slack = false
            }
        }
        setQueue({
            ...queue,
            ...{
                ["alertTypes"]: alertObject,
            },
        })
        setIsSubmitted(false)
        setDisableSubmit(false)
    }

    const updateChannelData = (e) => {
        if (!e) {
            console.log("e channel", { e, channel })
            return
        }
        if (!isNaN(e)) {
            e = channelList.find((c) => c.id == e).name
        }
        if (e.toLowerCase() == "chat") {
            setChannel(e)
            updateQueueData("channel", e)
            updateQueueAlertData("voiceMail", false)
            updateQueueAlertData("convAbandoned", true)
        } else if (e.toLowerCase() == "case") {
            setChannel(e)
            updateQueueData("channel", e)
            updateQueueAlertData("voiceMail", false)
            updateQueueAlertData("convAbandoned", false)
        } else if (e.toLowerCase() == "call") {
            setChannel(e)
            updateQueueData("channel", e)
            updateQueueAlertData("voiceMail", true)
            updateQueueAlertData("convAbandoned", true)
        }
    }

    const updateThresholdData = (
        key,
        index,
        value,
        isCnvThreshold = false,
        convWaitIndex
    ) => {
        console.log({ key, index, value, isCnvThreshold, convWaitIndex })
        const alertObject = queue.alertTypes
        let threshold
        if (isCnvThreshold) {
            threshold =
                queue.alertTypes.convWaitAlerts[index].convWaitSchedules[
                    convWaitIndex
                ]
        } else {
            threshold = queue.alertTypes.userThresholdSchedules[index]
        }
        if (typeof value == "object") {
            if (key == "startTime" || key == "endTime") {
                if (
                    !isNaN(value.hour) ||
                    !isNaN(value.minute) ||
                    !isNaN(value.second)
                ) {
                    const hour = value.hour < 10 ? 0 + value.hour : value.hour
                    const min =
                        value.minute < 10 ? 0 + value.minute : value.minute
                    const sec =
                        value.second < 10 ? 0 + value.second : value.second
                    threshold[key] = `${hour}:${min}:${sec}`
                } else {
                    threshold[key] = value
                }
            } else {
                threshold[key] = value.filter((e) => e != "").join(",")
            }
        } else if (typeof value == "number") {
            threshold[key] = value
        } else if (typeof value == "boolean") {
            if (key == "startTime" || key == "endTime") {
                threshold["startTime"] = value ? "all" : "00:00:00"
                threshold["endTime"] = value ? "all" : "00:00:00"
            } else {
                threshold[key] = value ? "all" : ""
            }
        } else {
            threshold[key] = value ? value : ""
        }
        if (isCnvThreshold) {
            alertObject.convWaitAlerts[index].convWaitSchedules[
                convWaitIndex || 0
            ] = threshold
        } else {
            alertObject.userThresholdSchedules[index] = threshold
        }
        setQueue({
            ...queue,
            ["alertTypes"]: alertObject,
        })
        setIsSubmitted(false)
        setDisableSubmit(false)
    }

    function shedulesWithCommonDays(thresholdSchedules, ind) {
        let shedules = []
        if (thresholdSchedules[ind].days.includes("all")) {
            //this condition is very rare
            shedules = [...thresholdSchedules]
            shedules.splice(ind, ind + 1)
        } else {
            for (const [i, th] of thresholdSchedules.entries()) {
                const arr1 = th.days.split(",")
                const arr2 = thresholdSchedules[ind].days.split(",")
                const commonDays = arr1.filter((value) => arr2.includes(value))

                if (
                    i !== ind &&
                    (th.days.includes("all") || commonDays.length > 0)
                ) {
                    shedules.push(th)
                }
            }
        }
        return shedules
    }

    function isValidTimeRangeForCommonDays(th, currTh) {
        if (
            th.startTime.includes("all") ||
            th.endTime.includes("all") ||
            currTh.startTime.includes("all") ||
            currTh.endTime.includes("all")
        ) {
            return false
        }
        // console.log(th,currTh ,isValidTimeRanges(th.startTime, th.endTime, currTh.startTime, currTh.endTime));
        return isValidTimeRanges(
            th.startTime,
            th.endTime,
            currTh.startTime,
            currTh.endTime
        )
    }

    function isDayTimeOverlap(thresholdSchedules, ind) {
        const shedWithCommonDays = shedulesWithCommonDays(
            thresholdSchedules,
            ind
        )
        // console.log('shedWithCommonDays', shedWithCommonDays, thresholdSchedules[ind], ind)
        for (const [i, sh] of shedWithCommonDays.entries()) {
            console.log(i)
            if (!isValidTimeRangeForCommonDays(sh, thresholdSchedules[ind]))
                return true
        }
        return false
    }

    function isDayOverlap(thresholdSchedules, ind) {
        //console.log(thresholdSchedules.entries(), { ind });
        for (const [i, th] of thresholdSchedules.entries()) {
            if (i !== ind) {
                return areSomeCommonDays(
                    th.days.split(","),
                    thresholdSchedules[ind].days.split(",")
                )
            }
        }
        return false
    }

    function isTimeOverlap(thresholdSchedules, ind) {
        for (const [i, th] of thresholdSchedules.entries()) {
            if (i !== ind) {
                //console.log(i, th, ind)
                if (
                    thresholdSchedules[ind].startTime === "all" &&
                    thresholdSchedules[ind].endTime === "all"
                ) {
                    return true
                }
                //console.log('Step 3');
                const isValidTimeRange = isValidTimeRanges(
                    th.startTime,
                    th.endTime,
                    thresholdSchedules[ind].startTime,
                    thresholdSchedules[ind].endTime
                )
                if (!isValidTimeRange) return true
            }
        }
        return false
    }

    const isValidThresholdSchedules = (
        thresholdSchedules,
        isconvWait = false
    ) => {
        for (const th of thresholdSchedules) {
            if (th.days == "") {
                setErrorMessage("Please select valid day or days")
                return false
            }
            if (
                th.startTime !== "all" &&
                th.endTime !== "all" &&
                new Date("1/1/1999 " + th.endTime) <
                    new Date("1/1/1999 " + th.startTime)
            ) {
                setErrorMessage("End Time should be after Start Time.")
                return false
            }
            if (isconvWait && !th.convWaitConditions) {
                setErrorMessage(`Invalid schedule condition.`)
                return false
            } else if (
                th.onQueueUserThreshold &&
                th.onQueueUserThreshold == null
            ) {
                setErrorMessage("Invalid Agent Threshold")
                return false
            }
            if (th.timezone == "") {
                setErrorMessage("No timezone is selected")
                return false
            }
        }
        if (thresholdSchedules.length <= 1) return true
        for (const th of thresholdSchedules) {
            const ind = thresholdSchedules.findIndex((e) => e === th)
            //console.log({thresholdSchedules, th, ind});
            if (th.days === "all") {
                if (th.startTime === "all" && th.endTime === "all") {
                    setErrorMessage(
                        "Selected time range is conflict of other schedule"
                    )
                    return false
                } else {
                    if (isTimeOverlap(thresholdSchedules, ind)) {
                        setErrorMessage(
                            "Selected time range is conflict of other schedule"
                        )
                        return false
                    }
                }
            } else {
                if (th.startTime === "all" && th.endTime === "all") {
                    if (isDayOverlap(thresholdSchedules, ind)) {
                        setErrorMessage(
                            "Selected Days are  conflict for some timerange"
                        )
                        return false
                    }
                } else {
                    if (isDayTimeOverlap(thresholdSchedules, ind)) {
                        setErrorMessage(
                            "Selected Days and time range is conflict of other schedule"
                        )
                        return false
                    }
                }
            }
        }
        return true
    }

    const isValidConvWaitSchedules = (convWaitSchedules) => {
        return isValidThresholdSchedules(convWaitSchedules, true)
    }
    function validURL(str) {
        var pattern = new RegExp(
            "^(https?:\\/\\/)?" + // protocol
                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
                "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
                "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
                "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
                "(\\#[-a-z\\d_]*)?$",
            "i"
        ) // fragment locator
        return !!pattern.test(str)
    }

    // const checkIsValid = (queue) => {
    //     if(queue.alertTypes.convWait){
    //         if(queue.alertTypes.convWaitAlerts[0].convWaitAlertOption.email && queue.alertTypes.convWaitAlerts[0].convWaitAlertOption.slack){
    //             if(queue.alertTypes.convWaitAlerts[0].customAlert && queue.alertTypes.convWaitAlerts[0].globalAlert){
    //                 if((queue.alertInfo.emailRecipients.length || queue.alertTypes.convWaitAlerts[0].alertInfo.emailRecipients.length)
    //                     && (queue.alertInfo.slackWebhooks.length || queue.alertTypes.convWaitAlerts[0].alertInfo.slackWebhooks.length)){
    //                     return true;
    //                 }

    //             }else if(queue.alertTypes.convWaitAlerts[0].customAlert){
    //                 if(queue.alertTypes.convWaitAlerts[0].alertInfo.emailRecipients.length || queue.alertTypes.convWaitAlerts[0].alertInfo.slackWebhooks.length){
    //                     return true;
    //                 }
    //             }else if(queue.alertTypes.convWaitAlerts[0].globalAlert){
    //                 if(queue.alertInfo.emailRecipients.length || queue.alertInfo.slackWebhooks.length){
    //                     return true;
    //                 }
    //             }else{
    //                 return false;
    //             }

    //         }else if(queue.alertTypes.convWaitAlerts[0].convWaitAlertOption.slack){
    //             if(queue.alertTypes.convWaitAlerts[0].customAlert && queue.alertTypes.convWaitAlerts[0].globalAlert){
    //                if(queue.alertTypes.convWaitAlerts[0].alertInfo.slackWebhooks.length || queue.alertInfo.slackWebhooks.length){
    //                    return true;
    //                }
    //             }else if(queue.alertTypes.convWaitAlerts[0].customAlert){
    //                 if(queue.alertTypes.convWaitAlerts[0].alertInfo.slackWebhooks.length){
    //                     return true;
    //                 }
    //             }else if(queue.alertTypes.convWaitAlerts[0].globalAlert){
    //                 if(queue.alertInfo.slackWebhooks.length){
    //                     return true;
    //                 }
    //             }else {
    //                 return false;

    //             }
    //         }else if(queue.alertTypes.convWaitAlerts[0].convWaitAlertOption.email){
    //             if(queue.alertTypes.convWaitAlerts[0].customAlert && queue.alertTypes.convWaitAlerts[0].globalAlert){
    //                 if(queue.alertTypes.convWaitAlerts[0].alertInfo.emailRecipients.length || queue.alertInfo.emailRecipients.length){
    //                     return true;
    //                 }
    //             }else if(queue.alertTypes.convWaitAlerts[0].customAlert){
    //                 if(queue.alertTypes.convWaitAlerts[0].alertInfo.emailRecipients.length){
    //                     return true;
    //                 }
    //             }else if(queue.alertTypes.convWaitAlerts[0].globalAlert){
    //                 if(queue.alertInfo.emailRecipients.length){
    //                     return true;
    //                 }
    //             }else {
    //                 return false;
    //             }
    //         }
    //     }
    // }

    const checkIsValid = (queue) => {
        if (queue.alertTypes.convWait) {
            return queue.alertTypes.convWaitAlerts.every((alert) => {
                const { email, slack } = alert.convWaitAlertOption
                const { customAlert, globalAlert, alertInfo } = alert
                const queueEmailRecipients =
                    queue.alertInfo.emailRecipients.length
                const queueSlackWebhooks = queue.alertInfo.slackWebhooks.length
                const alertEmailRecipients = alertInfo.emailRecipients.length
                const alertSlackWebhooks = alertInfo.slackWebhooks.length

                if (email && slack) {
                    if (customAlert && globalAlert) {
                        return (
                            (queueEmailRecipients || alertEmailRecipients) &&
                            (queueSlackWebhooks || alertSlackWebhooks)
                        )
                    } else if (customAlert) {
                        return alertEmailRecipients && alertSlackWebhooks
                    } else if (globalAlert) {
                        return queueEmailRecipients && queueSlackWebhooks
                    } else {
                        return false
                    }
                } else if (slack) {
                    if (customAlert && globalAlert) {
                        return alertSlackWebhooks || queueSlackWebhooks
                    } else if (customAlert) {
                        return alertSlackWebhooks
                    } else if (globalAlert) {
                        return queueSlackWebhooks
                    } else {
                        return false
                    }
                } else if (email) {
                    if (customAlert && globalAlert) {
                        return alertEmailRecipients || queueEmailRecipients
                    } else if (customAlert) {
                        return alertEmailRecipients
                    } else if (globalAlert) {
                        return queueEmailRecipients
                    } else {
                        return false
                    }
                }
            })
        }
        return true
    }

    const isValidAlert = (queueIn) => {
        console.log("QUEUE IN", queueIn)
        const isVALID = checkIsValid(queueIn)
        if (!isVALID) {
            setErrorMessage("Please provide Email/Slack or both")
            return false
        }
        //console.log(`Email Test ${EMAIL_REGEX.test(queueIn.emailRecipients)}`);
        if (!queueIn.queueId) {
            setErrorMessage("Please select a queue.")
            return false
        }
        if (queueIn.queueId == "" || queueIn.queueId == null) {
            setErrorMessage("Please select a queue.")
            return false
        }
        if (
            !queueIn.alertInfo.slackWebhooks.length &&
            !queueIn.alertInfo.emailRecipients.length
        ) {
            setErrorMessage("Email and Slack Webhook URL are empty")
            return false
        }

        if (!queueIn.channel) {
            setErrorMessage("Please select a channel.")
            return false
        }

        // if (
        //     queueIn.emailRecipients &&
        //     !queueIn.emailRecipients.includes(",") &&
        //     !EMAIL_REGEX.test(queueIn.emailRecipients)
        // ) {
        //     setErrorMessage(`Invalid Email`)
        //     return false
        // }
        // if (queueIn.emailRecipients && queueIn.emailRecipients.includes(",")) {
        //     const invalidEmails = queueIn.emailRecipients
        //         .split(",")
        //         .filter((email) => !EMAIL_REGEX.test(email))
        //     //console.log({ invalidEmails });
        //     if (invalidEmails.length) {
        //         setErrorMessage(`Invalid one or multiple Email`)
        //         return false
        //     }
        // }

        // if (
        //     queueIn.slackWebhook &&
        //     (!validURL(queueIn.slackWebhook) ||
        //         !queueIn.slackWebhook.includes(URL_REGEX))
        // ) {
        //     setErrorMessage(`Invalid Slack Webhook`)
        //     return false
        // }

        if (
            !queueIn.alertTypes.convWait &&
            !queueIn.alertTypes.voiceMail &&
            !queueIn.alertTypes.onQueueUsers &&
            !queueIn.alertTypes.convAbandoned
        ) {
            setErrorMessage("No alert type is selected")
            return false
        }

        if (
            queueIn.alertTypes.convWait &&
            queueIn.alertTypes.convWaitEveryday &&
            isNaN(queueIn.alertTypes.convWaitConditions)
        ) {
            setErrorMessage(`${queueIn.channel} schedule condition is Invalid.`)
            return false
        }

        if (
            queueIn.alertTypes.convWait &&
            queueIn.alertTypes.convWaitCustom &&
            !queueIn.alertTypes.convWaitSchedules.length
        ) {
            setErrorMessage(
                `No threshold schedule is created for ${cnvType} Wait.`
            )
            return false
        }
        if (
            queueIn.alertTypes.convWait &&
            queueIn.alertTypes.convWaitCustom &&
            queueIn.alertTypes.convWaitSchedules.length &&
            !isValidConvWaitSchedules(queueIn.alertTypes.convWaitSchedules)
        ) {
            return false
        }

        if (
            queueIn.alertTypes.onQueueUsers &&
            !queueIn.alertTypes.userThresholdSchedules.length
        ) {
            setErrorMessage(
                "No threshold schedule is created for On Queue Agents"
            )
            return false
        }
        if (
            queueIn.alertTypes.onQueueUsers &&
            queueIn.alertTypes.userThresholdSchedules.length &&
            !isValidThresholdSchedules(
                queueIn.alertTypes.userThresholdSchedules
            )
        ) {
            return false
        }
        if (
            queue.alertTypes.voiceMail &&
            !queue.alertTypes.voiceMailAlertOptions.email &&
            !queue.alertTypes.voiceMailAlertOptions.slack
        ) {
            setErrorMessage(
                "Select one of the alertOptions Email or Slack or uncheck Voicemail"
            )
            return false
        }
        if (
            queue.alertTypes.convWait &&
            !queue.alertTypes.convWaitAlertOptions.email &&
            !queue.alertTypes.convWaitAlertOptions.slack
        ) {
            setErrorMessage(
                "Select one of the alertOptions Email or Slack or uncheck conv Wait"
            )
            return false
        }
        if (
            queue.alertTypes.convAbandoned &&
            !queue.alertTypes.convAbandonedAlertOptions.email &&
            !queue.alertTypes.convAbandonedAlertOptions.slack
        ) {
            setErrorMessage(
                "Select one of the alertOptions Email or Slack or uncheck Abandoned Conversation"
            )
            return false
        }
        if (
            queue.alertTypes.onQueueUsers &&
            !queue.alertTypes.onQueueUsersAlertOptions.email &&
            !queue.alertTypes.onQueueUsersAlertOptions.slack
        ) {
            setErrorMessage(
                "Select one of the alertOptions Email or Slack or uncheck onQueueUsers"
            )
            return false
        }
        if (
            ((queue.alertTypes.voiceMail && queue.alertTypes.voiceMailAlertOptions.email) ||
           ( queue.alertTypes.convAbandoned &&  queue.alertTypes.convAbandonedAlertOptions.email) ||
                (queue.alertTypes.onQueueUsers &&  queue.alertTypes.onQueueUsersAlertOptions.email)) 
                &&
            !queue.alertInfo?.emailRecipients?.length
        ) {
            setErrorMessage("Please provide Email")
            return false
        }
        if (
            ((queue.alertTypes.voiceMail && queue.alertTypes.voiceMailAlertOptions.slack) ||
           ( queue.alertTypes.convAbandoned &&  queue.alertTypes.convAbandonedAlertOptions.slack) ||
                (queue.alertTypes.onQueueUsers &&  queue.alertTypes.onQueueUsersAlertOptions.slack)) 
                &&
            !queue.alertInfo?.slackWebhooks?.length
        ) {
            setErrorMessage("Please provide Slack Webhook URL")
            return false
        }

        setErrorMessage("")
        return true
        // return true;
    }

    const submitForm = (queueIn) => {
        const isValidForm = isValidAlert(queueIn)
        if (!isValidForm) {
            // const addModel = document.querySelector(
            //     ".spectrum-Dialog-content_6d8b48"
            // )
            // addModel?.scrollTop = 0
            setIsSubmitted(true)
            setDisableSubmit(true)
            //console.log("SetIsSubmitted to true ");
        } else {
            //console.log({ queueIn });
            // let [selectedQueue] = queueList.filter(ele=>ele.id == queue.queueId);
            // queueIn.channel = formatChannelToSend(queueIn.channel);
            queueIn.updatedBy = props.user.userid
            queueIn.updatedDate = new Date().getTime()
            updateQueueData("updatedDate", new Date().getTime())
            updateQueueData("updatedBy", props.user.userid)
            setIsSubmitted(false)
            setDisableSubmit(false)
            addNewAlert(queueIn)
                .then((resp) => {
                    console.log({ resp })
                    props.close()
                })
                .catch(() => {
                    //console.log({ err });
                })
        }
    }

    // function formatChannelToSend(ch){
    //     if(ch.toLowerCase() == 'voice')
    //         return 'call';
    //     else if(ch.toLowerCase() == 'chat')
    //         return 'chat'
    //     else if(ch.toLowerCase() == 'case')
    //         return 'case';
    //     return '';
    // }

    const setQueueNameID = (selected) => {
        // console.log({ selected })
        setIsSubmitted(false)
        if (props.selectedQueues.includes(selected) && !props.isUpdate) {
            alert("Queue is already being monitored!")
            setDisableSubmit(true)
        } else {
            setDisableSubmit(false)
            updateQueueData("queueId", selected)
            const getSelectedName = queueList.filter(
                (element) => element.id == selected
            )
            //console.log({ getSelectedName });
            if (getSelectedName.length) {
                //console.log({ getSelectedName });
                const [selectedItem] = getSelectedName
                updateQueueData("queueName", selectedItem.name)
                updateQueueData("queueId", selectedItem.id)
            }
        }
    }

    // const timeZoneChange = (data) => {
    //     //console.log({ data });
    //     window.scrollTo(0, 0);
    // }

    const updateForm = (queueIn) => {
        queueIn.updatedBy = props.user.userid
        queueIn.updatedDate = new Date().getTime()
        // queueIn.channel = formatChannelToSend(queueIn.channel);
        if (isValidAlert(queueIn)) {
            updateAlert(queueIn)
                .then((resp) => {
                    console.log({ resp })
                    props.close()
                })
                .catch(() => {
                    //console.log({ err });
                })
        }
    }

    // const queueFilter = (e) => {
    //     //console.log(`Input String ${e}`);

    // }

    const removeThresholdIndex = (index) => {
        const updatedThreshold = queue.alertTypes.userThresholdSchedules.filter(
            (ele, i) => i != index
        )
        updateQueueAlertData("userThresholdSchedules", updatedThreshold)
    }

    const updateQueueAlertOptions = (alertOption, alertMedium, value) => {
        const queueData = { ...queue }
        if (queueData.alertTypes[alertOption]) {
            queueData.alertTypes[alertOption][alertMedium] = value
        } else {
            queueData.alertTypes[alertOption] = {
                email: alertMedium == "email" ? value : false,
                slack: alertMedium == "slack" ? value : false,
            }
        }

        setQueue(queueData)
        setIsSubmitted(false)
        setDisableSubmit(false)
    }

    const updateConvWaitQueueAlertOptions = (index, alert, value) => {
        const queueData = { ...queue }
        queueData.alertTypes.convWaitAlerts[index].convWaitAlertOption[
            alert
        ] = value
        setQueue(queueData)
        setIsSubmitted(false)
        setDisableSubmit(false)
    }

    const covTypeChange = (event, index) => {
        const value = event // Extract the value from the event
        // Create a shallow copy of the queue state to avoid direct mutation
        const updatedQueue = { ...queue }

        if (value === "everyDay") {
            updatedQueue.alertTypes.convWaitAlerts[
                index
            ].convWaitEveryday = true
            updatedQueue.alertTypes.convWaitAlerts[index].convWaitCustom = false
        }
        if (value === "custom") {
            updatedQueue.alertTypes.convWaitAlerts[
                index
            ].convWaitEveryday = false
            updatedQueue.alertTypes.convWaitAlerts[index].convWaitCustom = true
        }

        // Update the queue state
        setQueue(updatedQueue)
        // Update the cnvType state
        setCnvType(value)
    }

    const addNewThresholdData = () => {
        const newThresholdSchedule = {
            timezone: "IST",
            days: "all",
            startTime: "*",
            endTime: "*",
            onQueueUserThreshold: 0,
        }
        const updatedQueue = { ...queue }
        updatedQueue.alertTypes.userThresholdSchedules = [
            ...updatedQueue.alertTypes.userThresholdSchedules,
            newThresholdSchedule,
        ]
        setQueue(updatedQueue)
    }

    // Add new Call wait threshold

    const addNewCnvThresholdData = (index) => {
        const newConvWaitSchedule = {
            timezone: "",
            days: "",
            startTime: "all",
            endTime: "*",
            convWaitConditions: "0",
        }

        setQueue((prevState) => ({
            ...prevState,
            alertTypes: {
                ...prevState.alertTypes,
                convWaitAlerts: prevState.alertTypes.convWaitAlerts.map(
                    (alert, i) =>
                        i === index
                            ? {
                                  ...alert,
                                  convWaitSchedules: [
                                      ...alert.convWaitSchedules,
                                      newConvWaitSchedule,
                                  ],
                              }
                            : alert
                ),
            },
        }))
    }

    // Remove Conversation Wait threshold
    const removeCnvThresholdIndex = (alertIndex, scheduleIndex) => {
        console.log({ alertIndex, scheduleIndex })
        const updatedThreshold = queue.alertTypes.convWaitAlerts[
            alertIndex
        ].convWaitSchedules.filter((ele, i) => i != scheduleIndex)
        const alertObject = queue.alertTypes
        alertObject.convWaitAlerts[
            alertIndex
        ].convWaitSchedules = updatedThreshold
        setQueue({
            ...queue,
            ...{
                ["alertTypes"]: alertObject,
            },
        })
    }

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }

    const getSelectedChannel = () => {
        const ch = channelList.find(
            (c) => c.name.toLowerCase() == props.queue.channel?.toLowerCase()
        )
        if (ch && ch?.id) return ch.id
        return "1"
    }
    const getChannelName = (id) => {
        if (!isNaN(id)) return channelList.find((c) => c.id == id).name
        return "call"
    }

    const addNewCallWait = () => {
        const newObj = {
            convWaitId: Math.floor(100000 + Math.random() * 900000),
            convWaitAlertOption: {
                slack: true,
                email: true,
            },
            convWaitEveryday: true,
            convWaitAttribute: false,
            globalAlert: true,
            convWaitAttributes: [
                {
                    attributeValue: "",
                    attributeName: "",
                    attributeValues: [],
                },
            ],
            convWaitSchedules: [],
            customAlert: false,
            alertInfo: {
                slackWebhooks: [],
                emailRecipients: [],
            },
            convWaitCustom: false,
            convWaitConditions: "0",
        }

        const newQueue = {
            ...queue,
            alertTypes: {
                ...queue.alertTypes,
                convWaitAlerts: [...queue.alertTypes.convWaitAlerts, newObj],
            },
        }
        setQueue(newQueue)
    }

    const handleDeleteCallWait = (index) => {
        const newQueue = {
            ...queue,
            alertTypes: {
                ...queue.alertTypes,
                convWaitAlerts: queue.alertTypes.convWaitAlerts.filter(
                    (ele, i) => i != index
                ),
            },
        }
        setQueue(newQueue)
    }

    const handleCallWaitEmailChange = (e, index) => {
        if (e.key === "Enter") {
            setQueue((prevState) => {
                // Deep copy of the state
                let newQueue = JSON.parse(JSON.stringify(prevState))
                newQueue.alertTypes?.convWaitAlerts[
                    index
                ].alertInfo.emailRecipients.push(callWaitEmail)
                return newQueue
            })
            setCallWaitEmail("")
        }
    }

    const handleCallWaitSlackChange = (e, index) => {
        if (e.key === "Enter") {
            setQueue((prevState) => {
                // Deep copy of the state
                let newQueue = JSON.parse(JSON.stringify(prevState))
                newQueue.alertTypes?.convWaitAlerts[
                    index
                ].alertInfo.slackWebhooks.push(callWaitSlack)
                return newQueue
            })
            setCallWaitSlack("")
        }
    }

    const handleCallWaitAttributeNameChange = (val, index, attrIndex) => {
        setQueue((prevState) => {
            // Copy the convWaitAttributes array for the specified alert
            let newConvWaitAttributes = [
                ...prevState.alertTypes.convWaitAlerts[index]
                    .convWaitAttributes,
            ]

            // Update the attributeName for the first attribute
            newConvWaitAttributes[attrIndex] = {
                ...newConvWaitAttributes[attrIndex],
                attributeName: val,
            }

            // Return the new state with the updated convWaitAttributes
            return {
                ...prevState,
                alertTypes: {
                    ...prevState.alertTypes,
                    convWaitAlerts: prevState.alertTypes.convWaitAlerts.map(
                        (alert, i) =>
                            i === index
                                ? {
                                      ...alert,
                                      convWaitAttributes: newConvWaitAttributes,
                                  }
                                : alert
                    ),
                },
            }
        })
    }

    // const convertArrayToBadge = (
    //     type,
    //     selectedUserArray,
    //     objIndex,
    //     attrIndex
    // ) => {
    //     if (selectedUserArray.length <= 2) {
    //         return selectedUserArray.map((val, index) => (
    //             <Badge
    //                 key={index}
    //                 variant="neutral"
    //                 UNSAFE_style={{
    //                     margin: "2px 2px",
    //                     background: "rgb(102, 102, 102)",
    //                     color: "white",
    //                     padding: "3px 0px 0px 10px",
    //                     borderRadius: "4px",
    //                     height: "30px",
    //                     display: "block",
    //                 }}
    //             >
    //                 <Text UNSAFE_style={{ padding: "0px", fontSize: "14px" }}>
    //                     {val}
    //                 </Text>
    //                 <Button
    //                     style="fill"
    //                     UNSAFE_style={{
    //                         marginTop: "-4px",
    //                         cursor: "pointer",
    //                         transform: "scale(0.7)",
    //                     }}
    //                     onPress={() =>
    //                         removeValue(type, val, objIndex, attrIndex)
    //                     }
    //                 >
    //                     <CloseCircle aria-label="Done" />
    //                 </Button>
    //             </Badge>
    //         ))
    //     } else {
    //         return (
    //             <>
    //                 {selectedUserArray.slice(0, 2).map((val, index) => (
    //                     <Badge
    //                         key={index}
    //                         variant="neutral"
    //                         UNSAFE_style={{
    //                             margin: "2px 2px",
    //                             background: "rgb(102, 102, 102)",
    //                             color: "white",
    //                             padding: "3px 0px 0px 10px",
    //                             borderRadius: "4px",
    //                             height: "30px",
    //                             display: "block",
    //                         }}
    //                     >
    //                         <Text
    //                             UNSAFE_style={{
    //                                 padding: "0px",
    //                                 fontSize: "14px",
    //                             }}
    //                         >
    //                             {val}
    //                         </Text>
    //                         <Button
    //                             style="fill"
    //                             UNSAFE_style={{
    //                                 marginTop: "-4px",
    //                                 cursor: "pointer",
    //                                 transform: "scale(0.7)",
    //                             }}
    //                             onPress={() =>
    //                                 removeValue(type, val, objIndex, attrIndex)
    //                             }
    //                         >
    //                             <CloseCircle aria-label="Done" />
    //                         </Button>
    //                     </Badge>
    //                 ))}
    //                 {selectedUserArray.length > 2 && (
    //                     <DialogTrigger type="popover" placement="right top">
    //                         <ActionButton
    //                             height={"30px"}
    //                             marginTop={"2px"}
    //                             UNSAFE_style={{ cursor: "pointer" }}
    //                         >
    //                             Show All
    //                         </ActionButton>
    //                         <Dialog>
    //                             <Content
    //                                 height={"auto"}
    //                                 UNSAFE_style={{
    //                                     display: "flex",
    //                                     flexWrap: "wrap",
    //                                 }}
    //                             >
    //                                 {selectedUserArray.map((val, index) => (
    //                                     <Badge
    //                                         key={index}
    //                                         variant="neutral"
    //                                         UNSAFE_style={{
    //                                             margin: "2px 2px",
    //                                             background:
    //                                                 "rgb(102, 102, 102)",
    //                                             color: "white",
    //                                             padding: "3px 0px 0px 10px",
    //                                             borderRadius: "4px",
    //                                             height: "30px",
    //                                             display: "block",
    //                                         }}
    //                                     >
    //                                         <Text
    //                                             UNSAFE_style={{
    //                                                 padding: "0px",
    //                                                 fontSize: "14px",
    //                                             }}
    //                                         >
    //                                             {val}
    //                                         </Text>
    //                                         <Button
    //                                             style="fill"
    //                                             UNSAFE_style={{
    //                                                 marginTop: "-4px",
    //                                                 cursor: "pointer",
    //                                                 transform: "scale(0.7)",
    //                                             }}
    //                                             onPress={() =>
    //                                                 removeValue(
    //                                                     type,
    //                                                     val,
    //                                                     objIndex,
    //                                                     attrIndex
    //                                                 )
    //                                             }
    //                                         >
    //                                             <CloseCircle aria-label="Done" />
    //                                         </Button>
    //                                     </Badge>
    //                                 ))}
    //                             </Content>
    //                         </Dialog>
    //                     </DialogTrigger>
    //                 )}
    //             </>
    //         )
    //     }
    // }

    const showAllButton = (type, selectedUserArray, objIndex, attrIndex) => {
        return (
            <DialogTrigger type="popover" placement="right top">
                <ActionButton
                    // height={"30px"}
                    // marginTop={"2px"}
                    UNSAFE_style={{
                        cursor: "pointer",
                        border: "none",
                        height: "20px",
                    }}
                >
                    <a> view all</a>
                </ActionButton>
                <Dialog>
                    <Content
                        height={"auto"}
                        UNSAFE_style={{
                            display: "flex",
                            flexWrap: "wrap",
                        }}
                    >
                        {selectedUserArray.map((val, index) => (
                            <Badge
                                key={index}
                                variant="neutral"
                                UNSAFE_style={{
                                    margin: "2px 2px",
                                    background: "rgb(102, 102, 102)",
                                    color: "white",
                                    padding: "3px 0px 0px 10px",
                                    borderRadius: "4px",
                                    height: "30px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between", // Add this line
                                }}
                            >
                                <View>
                                    <Text
                                        UNSAFE_style={{
                                            padding: "0px",
                                            fontSize: "14px",
                                            width: "auto",
                                        }}
                                    >
                                        {val}
                                    </Text>
                                </View>
                                <View>
                                    <Button
                                        style="fill"
                                        UNSAFE_style={{
                                            marginTop: "-4px",
                                            cursor: "pointer",
                                            transform: "scale(0.7)",
                                        }}
                                        onPress={() =>
                                            removeValue(
                                                type,
                                                val,
                                                objIndex,
                                                attrIndex
                                            )
                                        }
                                    >
                                        <CloseCircle aria-label="Done" />
                                    </Button>
                                </View>
                            </Badge>
                        ))}
                    </Content>
                </Dialog>
            </DialogTrigger>
        )
    }

    const removeValue = (type, val, objIndex, attrIndex) => {
        const newQueue = { ...queue }
        const operations = {
            convWaitAttributes: () => {
                newQueue.alertTypes.convWaitAlerts[objIndex].convWaitAttributes[
                    attrIndex
                ].attributeValues = newQueue.alertTypes.convWaitAlerts[
                    objIndex
                ].convWaitAttributes[attrIndex].attributeValues.filter(
                    (ele) => ele != val
                )
            },
            email: () => {
                newQueue.alertTypes.convWaitAlerts[
                    objIndex
                ].alertInfo.emailRecipients = newQueue.alertTypes.convWaitAlerts[
                    objIndex
                ].alertInfo.emailRecipients.filter((ele) => ele != val)
            },
            slack: () => {
                newQueue.alertTypes.convWaitAlerts[
                    objIndex
                ].alertInfo.slackWebhooks = newQueue.alertTypes.convWaitAlerts[
                    objIndex
                ].alertInfo.slackWebhooks.filter((ele) => ele != val)
            },
            alertEmail: () => {
                newQueue.alertInfo.emailRecipients = newQueue.alertInfo.emailRecipients.filter(
                    (ele) => ele != val
                )
            },
            alertSlack: () => {
                newQueue.alertInfo.slackWebhooks = newQueue.alertInfo.slackWebhooks.filter(
                    (ele) => ele != val
                )
            },
        }

        if (operations[type]) {
            operations[type]()
            setQueue(newQueue)
        } else {
            console.error(`Unknown type: ${type}`)
        }
    }

    const addNewCustomAttribute = (index) => {
        const newQueue = { ...queue }
        newQueue.alertTypes.convWaitAlerts[index].convWaitAttributes.push({
            attributeName: "",
            attributeValue: "",
            attributeValues: [],
        })
        setQueue(newQueue)
    }

    const removeCustomAttribute = (index, attrIndex) => {
        const newQueue = { ...queue }
        newQueue.alertTypes.convWaitAlerts[
            index
        ].convWaitAttributes = newQueue.alertTypes.convWaitAlerts[
            index
        ].convWaitAttributes.filter((ele, i) => i != attrIndex)
        setQueue(newQueue)
    }

    // const handleInputChange = (index,attrIndex, event) => {
    //     const { name, value } = event.target;
    //     const newQueue = [...queue];

    //     newQueue.alertTypes.convWaitAlerts[index].convWaitAttributes[attrIndex][name] = value;
    //     setQueue(newQueue);
    // };

    // const handleKeyPress = (index,attrIndex, event) => {
    //     if (event.key === 'Enter') {
    //         const newQueue = [...queue];
    //         const newValue = newQueue.alertTypes.convWaitAlerts[index].convWaitAttributes[attrIndex].trim();
    //         if (newValue !== "") {
    //             newQueue.alertTypes.convWaitAlerts[index].convWaitAttributes[attrIndex].attributeValues.push(newValue);
    //             newQueue.alertTypes.convWaitAlerts[index].convWaitAttributes[attrIndex].attributeValue = ""; // Clear the input field
    //             setQueue(newQueue);
    //         }
    //     }
    // };

    const handleInputChange = (index, attrIndex, event) => {
        const { name, value } = event.target
        const newQueue = { ...queue }
        newQueue.alertTypes.convWaitAlerts[index].convWaitAttributes[attrIndex][
            name
        ] = value
        setQueue(newQueue)
    }

    const handleKeyPress = (index, attrIndex, event) => {
        if (event.key === "Enter") {
            const newQueue = { ...queue }
            const newValue = newQueue.alertTypes.convWaitAlerts[
                index
            ].convWaitAttributes[attrIndex].attributeValue.trim()
            if (newValue !== "") {
                newQueue.alertTypes.convWaitAlerts[index].convWaitAttributes[
                    attrIndex
                ].attributeValues.push(newValue)
                newQueue.alertTypes.convWaitAlerts[index].convWaitAttributes[
                    attrIndex
                ].attributeValue = "" // Clear the input field
                setQueue(newQueue)
            }
        }
    }
    console.log("Queue", queue)

    const handleEmailAndSlackUpdate = (type) => {
        console.log({ type })
        if (type === "email") {
            if (emailDl.trim() !== "") {
                const newQueue = { ...queue }
                newQueue?.alertInfo?.emailRecipients?.push(emailDl.trim())
                setQueue(newQueue)
                setEmailDl("")
            }
        } else if (type === "slack") {
            if (slackWebhook.trim() !== "") {
                const newQueue = { ...queue }
                newQueue.alertInfo.slackWebhooks.push(slackWebhook.trim())
                setQueue(newQueue)
                setSlackWebhook("")
            }
        }
    }

    // const handleCheckboxChange = (value, index) => {
    //     const newQueue = { ...queue }

    //     if (value.length === 0) {
    //         newQueue.alertTypes.convWaitAlerts[index].globalAlert = true
    //         newQueue.alertTypes.convWaitAlerts[index].customAlert = false
    //         // setSelectedCustomAlert(["global"])
    //     } else {
    //         newQueue.alertTypes.convWaitAlerts[
    //             index
    //         ].globalAlert = value.includes("global")
    //         newQueue.alertTypes.convWaitAlerts[index].customAlert = value.some(
    //             (val) => val !== "global"
    //         )
    //         // setSelectedCustomAlert(value)
    //     }
    //     setQueue(newQueue)
    // }

    const updateConvWaitConditions = (index, value) => {
        const newQueue = { ...queue }
        newQueue.alertTypes.convWaitAlerts[index].convWaitConditions = value
        setQueue(newQueue)
    }

    const handleCheckboxChange = (value, index) => {
        const newQueue = { ...queue }
        const alert = newQueue.alertTypes.convWaitAlerts[index]

       if (value === "custom") {
            alert.customAlert = !alert.customAlert
            alert.globalAlert = false
        }

        if (!alert.globalAlert && !alert.customAlert) {
            alert.globalAlert = true
        }

        setQueue(newQueue)
    }

    return (
        <div id="modelAddEditQueue">
            <Flex direction="column" gap="size-100" margin="size-250">
                <Flex
                    direction="row"
                    justifyContent="center"
                    marginBottom="size-200"
                >
                    {errorMessage && (
                        <Text align="center">
                            {" "}
                            <span className="errorMessage">
                                Error : {errorMessage}
                            </span>
                        </Text>
                    )}
                </Flex>
                <Flex direction="row" gap="size-1000">
                    <View alignSelf="center" minWidth="size-1600">
                        Queue <Text UNSAFE_className="errorMessage">*</Text>
                    </View>
                    {queueList.length > 0 && !props.isUpdate && (
                        <ComboBox
                            defaultItems={queueList}
                            // defaultInputValue="Please select the queue"
                            onSelectionChange={setQueueNameID}
                            onInputChange={(e) =>
                                updateQueueData("queueName", e)
                            }
                            aria-label="Queues"
                            width="size-3000"
                            marginStart={"3px"}
                            isQuiet
                        >
                            {(item) => (
                                <Item key={item.id} aria-label={item.name}>
                                    {item.name}
                                </Item>
                            )}
                        </ComboBox>
                    )}
                    {queueList.length && props.isUpdate && (
                        <ComboBox
                            defaultItems={queueList}
                            defaultSelectedKey={props.queue.queueId}
                            defaultInputValue={props.queue.queueName}
                            onSelectionChange={setQueueNameID}
                            onInputChange={(e) =>
                                updateQueueData("queueName", e)
                            }
                            aria-label="Queues"
                            isDisabled
                            width="size-3000"
                            marginStart={"3px"}
                            isQuiet
                        >
                            {(item) => (
                                <Item key={item.id} aria-label={item.name}>
                                    {item.name}
                                </Item>
                            )}
                        </ComboBox>
                    )}
                </Flex>
                {/* {isSubmitted && <Flex>
                <Text> <span className='errorMessage'>Please select queue</span></Text>
            </Flex>} */}
                <Flex direction="row" gap="size-1000">
                    <View alignSelf="center" minWidth="size-1600">
                        Email
                        <ContextualHelp variant="help">
                            <Heading>Email</Heading>
                            <Content>
                                <Text>
                                    Provide Email and press enter to add more.
                                </Text>
                            </Content>
                        </ContextualHelp>
                    </View>
                    <TextField
                        // UNSAFE_style={{backgroundColor:"red"}}
                        value={emailDl}
                        UNSAFE_className="TARUN"
                        placeholder="Enter Email and hit enter"
                        aria-label="Email"
                        onChange={setEmailDl}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleEmailAndSlackUpdate("email")
                            }
                        }}
                        width="size-3000"
                        marginStart={"3px"}
                        isQuiet
                    />
                </Flex>
                <View UNSAFE_style={{ textAlign: "end" }}>
                    {queue?.alertInfo?.emailRecipients?.length > 0 && (
                        <>
                            <Text
                                UNSAFE_style={{
                                    fontSize: "12px",
                                    fontStyle: "italic",
                                    fontWeight: 600,
                                }}
                            >
                                {queue.alertInfo.emailRecipients.length} emails
                                entered
                            </Text>
                            <Text>
                                {" "}
                                {showAllButton(
                                    "alertEmail",
                                    queue.alertInfo.emailRecipients
                                )}
                            </Text>
                        </>
                    )}
                    {/* {queue.alertInfo.emailRecipients.length > 0 && (
                        <View
                            UNSAFE_style={{
                                border: "1px solid #c3c3c3",
                                margin: "10px 0px 10px 41%",
                            }}
                        >
                            {convertArrayToBadge(
                                "alertEmail",
                                queue.alertInfo.emailRecipients
                            )}
                        </View>
                    )} */}
                </View>
                <Flex direction="row" gap="13%">
                    <View alignSelf="center" minWidth="size-1600">
                        Slack Webhook URL
                        <ContextualHelp variant="help">
                            <Heading>Webhook URL of Slack channel</Heading>
                            <Content>
                                <Text>
                                    Webhook URL of Slack channel where
                                    notifications will be triggered (Your Slack
                                    workspace admin can generate the webhook for
                                    your slack channel). Provide Slack Webhook
                                    URL and press enter to add more.
                                </Text>
                            </Content>
                        </ContextualHelp>
                    </View>
                    <TextField
                        value={slackWebhook}
                        placeholder="Enter Slack Webhook URL and hit enter"
                        aria-label="slack web url"
                        onChange={setSlackWebhook}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleEmailAndSlackUpdate("slack")
                            }
                        }}
                        width="size-3000"
                        isQuiet
                    />
                </Flex>
                <View UNSAFE_style={{ textAlign: "end" }}>
                    {queue?.alertInfo?.slackWebhooks?.length > 0 && (
                        <>
                            <Text
                                UNSAFE_style={{
                                    fontSize: "12px",
                                    fontStyle: "italic",
                                    fontWeight: 600,
                                }}
                            >
                                {queue.alertInfo.slackWebhooks.length} slack
                                urls entered
                            </Text>
                            <Text>
                                {" "}
                                {showAllButton(
                                    "alertSlack",
                                    queue.alertInfo.slackWebhooks
                                )}
                            </Text>
                        </>
                    )}
                    {/* {queue.alertInfo.slackWebhooks.length > 0 && (
                        <View
                            UNSAFE_style={{
                                border: "1px solid #c3c3c3",
                                margin: "10px 0px 10px 41%",
                            }}
                        >
                            {convertArrayToBadge(
                                "alertSlack",
                                queue.alertInfo.slackWebhooks
                            )}
                        </View>
                    )} */}
                </View>
                <Flex direction="row" gap="size-1000">
                    <View alignSelf="center" minWidth="size-1600">
                        Channel<Text UNSAFE_className="errorMessage">*</Text>
                    </View>
                    {!props.isUpdate && (
                        <ComboBox
                            items={channelList}
                            // defaultInputValue="Please select the channel"
                            placeholder="Please select the channel"
                            onInputChange={(e) => {
                                updateChannelData(e?.trim())
                                updateQueueData("channel", e?.trim())
                            }}
                            aria-label="Queues"
                            width="size-3000"
                            marginStart={"3px"}
                            isQuiet
                        >
                            {(item) => (
                                <Item key={item.id} aria-label={item.name}>
                                    {item.name}
                                </Item>
                            )}
                        </ComboBox>
                    )}
                    {props.isUpdate && (
                        <ComboBox
                            defaultItems={channelList}
                            defaultInputValue={props?.queue?.channel}
                            defaultSelectedKey={getSelectedChannel()}
                            onSelectionChange={(e) => {
                                updateChannelData(e?.trim())
                                updateQueueData(
                                    "channel",
                                    getChannelName(e?.trim())
                                )
                            }}
                            onInputChange={() => {}}
                            aria-label="Queues"
                            width="size-3000"
                            marginStart={"3px"}
                            isQuiet
                        >
                            {(item) => (
                                <Item key={item.id} aria-label={item.name}>
                                    {item.name}
                                </Item>
                            )}
                        </ComboBox>
                    )}
                </Flex>
                {!channel ? (
                    <View>
                        <h4>Please select a channel.</h4>{" "}
                    </View>
                ) : (
                    <View>
                        <h4>Alert Types</h4>
                        <Flex direction="column" marginStart="size-250">
                            {channel?.toLowerCase() == "call" && (
                                <Flex direction="row" height="size-300">
                                    <View width={"70%"}>
                                        <Checkbox
                                            UNSAFE_className="checkBoxLabelBeforeHelp"
                                            isSelected={
                                                queue?.alertTypes?.voiceMail
                                            }
                                            onChange={(e) =>
                                                updateQueueAlertData(
                                                    "voiceMail",
                                                    e
                                                )
                                            }
                                            aria-label="Voicemail"
                                        >
                                            Voicemail
                                        </Checkbox>
                                        <ContextualHelp
                                            UNSAFE_className="helpContextIcon"
                                            variant="help"
                                        >
                                            <Heading>Voicemail</Heading>
                                            <Content>
                                                <Text>
                                                    To receive notification when
                                                    voicemail is received.
                                                </Text>
                                            </Content>
                                        </ContextualHelp>
                                    </View>
                                    {props.queue.alertTypes.voiceMail && (
                                        <AlertOptions
                                            email={
                                                queue.alertTypes
                                                    ?.voiceMailAlertOptions
                                                    ?.email
                                            }
                                            slack={
                                                queue.alertTypes
                                                    ?.voiceMailAlertOptions
                                                    ?.slack
                                            }
                                            onEmailChange={(value) =>
                                                updateQueueAlertOptions(
                                                    "voiceMailAlertOptions",
                                                    "email",
                                                    value
                                                )
                                            }
                                            onSlackChange={(value) =>
                                                updateQueueAlertOptions(
                                                    "voiceMailAlertOptions",
                                                    "slack",
                                                    value
                                                )
                                            }
                                            disabled={
                                                !queue.alertTypes?.voiceMail
                                            }
                                        />
                                    )}
                                </Flex>
                            )}

                            <Flex direction={"column"}>
                                {channel.toLowerCase() != "case" && (
                                    <Flex direction={"row"}>
                                        <View width={"70%"}>
                                            {/* {(!(channel == 'Chat')) &&  <Checkbox UNSAFE_className='checkBoxLabelBeforeHelp' isHidden={(channel == 'Case')}  isDisabled={(channel == 'Case')} isSelected={queue.alertTypes.convAbandoned} onChange={(e) => updateQueueAlertData('abandonedCall', e)} aria-label="abandonedCall">Call Abandoned</Checkbox>} */}
                                            <Checkbox
                                                UNSAFE_className="checkBoxLabelBeforeHelp"
                                                isSelected={
                                                    queue.alertTypes
                                                        ?.convAbandoned
                                                }
                                                onChange={(e) =>
                                                    updateQueueAlertData(
                                                        "convAbandoned",
                                                        e
                                                    )
                                                }
                                                aria-label="convAbandoned"
                                            >
                                                {capitalizeFirstLetter(channel)}{" "}
                                                Abandoned
                                            </Checkbox>

                                            <ContextualHelp
                                                UNSAFE_className="helpContextIcon"
                                                variant="help"
                                                isHidden={
                                                    channel.toLowerCase() ==
                                                    "case"
                                                }
                                            >
                                                <Heading>
                                                    {capitalizeFirstLetter(
                                                        channel
                                                    )}{" "}
                                                    Abandoned
                                                </Heading>
                                                <Content>
                                                    <Text>
                                                        To receive notification
                                                        when {channel} is
                                                        abandoned by customer.
                                                    </Text>
                                                </Content>
                                            </ContextualHelp>
                                        </View>
                                        {props.queue.alertTypes
                                            ?.convAbandoned && (
                                            <AlertOptions
                                                email={
                                                    queue.alertTypes
                                                        ?.convAbandonedAlertOptions
                                                        ?.email
                                                }
                                                slack={
                                                    queue.alertTypes
                                                        ?.convAbandonedAlertOptions
                                                        ?.slack
                                                }
                                                onEmailChange={(value) =>
                                                    updateQueueAlertOptions(
                                                        "convAbandonedAlertOptions",
                                                        "email",
                                                        value
                                                    )
                                                }
                                                onSlackChange={(value) =>
                                                    updateQueueAlertOptions(
                                                        "convAbandonedAlertOptions",
                                                        "slack",
                                                        value
                                                    )
                                                }
                                                disabled={
                                                    !queue.alertTypes
                                                        ?.convAbandoned
                                                }
                                            />
                                        )}
                                    </Flex>
                                )}
                            </Flex>

                            {/* Need to hide email and other button on option */}

                            {/* CAAAAAAAAAAL WAIIIIIIIIIT */}

                            <Flex direction={"column"}>
                                <Flex direction={"row"}>
                                    <View width={"91%"}>
                                        <Checkbox
                                            UNSAFE_className="checkBoxLabelBeforeHelp"
                                            isSelected={
                                                queue.alertTypes?.convWait
                                            }
                                            onChange={(e) =>
                                                updateQueueAlertData(
                                                    "convWait",
                                                    e
                                                )
                                            }
                                            aria-label="convWait"
                                        >
                                            {capitalizeFirstLetter(channel)}{" "}
                                            Wait
                                        </Checkbox>
                                        <ContextualHelp
                                            UNSAFE_className="helpContextIcon"
                                            variant="help"
                                        >
                                            <Heading>
                                                {capitalizeFirstLetter(channel)}{" "}
                                                Wait
                                            </Heading>
                                            <Content>
                                                <Text>
                                                    To enable call wait
                                                    notification. If checkbox is
                                                    ticked and schedule
                                                    condition is provided as 60
                                                    seconds then a notification
                                                    will be triggered when call
                                                    is ringing and not answered
                                                    by any agent in 60 seconds.
                                                </Text>
                                            </Content>
                                        </ContextualHelp>
                                    </View>
                                    {queue.alertTypes?.convWait && (
                                        <Button
                                            aria-label="addKeyValue"
                                            variant="secondary"
                                            staticColor="black"
                                            style="fill"
                                            UNSAFE_className="noBorder cursor"
                                            onPress={() => addNewCallWait()}
                                        >
                                            <Add />
                                        </Button>
                                    )}
                                </Flex>
                                {
                                    queue.alertTypes?.convWait &&
                                        queue.alertTypes?.convWaitAlerts.map(
                                            (alert, index) => (
                                                <View
                                                    marginStart={"size-250"}
                                                    UNSAFE_style={{
                                                        border:
                                                            "1px solid #c3c3c3",
                                                        padding: "10px",
                                                        borderRadius: "5px",
                                                        marginBottom: "5px",
                                                    }}
                                                >
                                                    <Flex
                                                        direction={
                                                            "row-reverse"
                                                        }
                                                    >
                                                        <View>
                                                            <Button
                                                                aria-label="addKeyValue"
                                                                variant="secondary"
                                                                staticColor="black"
                                                                style="fill"
                                                                UNSAFE_className="noBorder cursor"
                                                                onPress={() =>
                                                                    handleDeleteCallWait(
                                                                        index
                                                                    )
                                                                }
                                                            >
                                                                <Delete />
                                                            </Button>
                                                        </View>
                                                        <AlertOptions
                                                            email={
                                                                alert
                                                                    .convWaitAlertOption
                                                                    ?.email
                                                            }
                                                            slack={
                                                                alert
                                                                    .convWaitAlertOption
                                                                    ?.slack
                                                            }
                                                            onEmailChange={(
                                                                value
                                                            ) =>
                                                                updateConvWaitQueueAlertOptions(
                                                                    index,
                                                                    "email",
                                                                    value
                                                                )
                                                            }
                                                            onSlackChange={(
                                                                value
                                                            ) =>
                                                                updateConvWaitQueueAlertOptions(
                                                                    index,

                                                                    "slack",
                                                                    value
                                                                )
                                                            }
                                                            disabled={
                                                                !queue
                                                                    .alertTypes
                                                                    .convWait
                                                            }
                                                        />
                                                    </Flex>

                                                    <Flex
                                                        direction={"row"}
                                                        justifyContent={
                                                            "space-between"
                                                        }
                                                    >
                                                        <RadioGroup
                                                            // label="Schedule"
                                                            labelPosition="side"
                                                            value={
                                                                alert.convWaitCustom
                                                                    ? "custom"
                                                                    : "everyDay"
                                                            }
                                                            orientation={
                                                                "horizontal"
                                                            }
                                                            onChange={(event) =>
                                                                covTypeChange(
                                                                    event,
                                                                    index
                                                                )
                                                            }
                                                            UNSAFE_style={{
                                                                display: "flex",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <View
                                                            // UNSAFE_style={{
                                                            //     marginLeft:
                                                            //         "33%",
                                                            // }}
                                                            >
                                                                <Radio value="everyDay">
                                                                    Everyday
                                                                </Radio>
                                                                <Radio value="custom">
                                                                    Custom
                                                                </Radio>
                                                            </View>
                                                        </RadioGroup>
                                                    </Flex>
                                                    {props.isUpdate ? (
                                                        <Flex direction={"row"}>
                                                            {alert.convWaitCustom && (
                                                                // || cnvType === "custom"
                                                                <Accordion>
                                                                    <AccordionItem
                                                                        key={`1-acc`}
                                                                        index={
                                                                            index
                                                                        }
                                                                        title="Custom CallWait Configuration"
                                                                        addNewCnvThreshold={() =>
                                                                            addNewCnvThresholdData(
                                                                                index
                                                                            )
                                                                        }
                                                                        queue={
                                                                            queue
                                                                        }
                                                                        removeCnvThresholdIndex={
                                                                            removeCnvThresholdIndex
                                                                        }
                                                                        updateThresholdData={
                                                                            updateThresholdData
                                                                        }
                                                                        timezonesValue={
                                                                            timezonesValue
                                                                        }
                                                                        isUpdate={
                                                                            props.isUpdate
                                                                        }
                                                                    ></AccordionItem>
                                                                </Accordion>
                                                            )}
                                                        </Flex>
                                                    ) : (
                                                        <Flex direction={"row"}>
                                                            {cnvType ===
                                                                "custom" && (
                                                                <Accordion>
                                                                    <AccordionItem
                                                                        key={`1-acc`}
                                                                        index={
                                                                            index
                                                                        }
                                                                        title="Custom CallWait Configuration"
                                                                        addNewCnvThreshold={() =>
                                                                            addNewCnvThresholdData(
                                                                                index
                                                                            )
                                                                        }
                                                                        queue={
                                                                            queue
                                                                        }
                                                                        removeCnvThresholdIndex={
                                                                            removeCnvThresholdIndex
                                                                        }
                                                                        updateThresholdData={
                                                                            updateThresholdData
                                                                        }
                                                                        timezonesValue={
                                                                            timezonesValue
                                                                        }
                                                                        isUpdate={
                                                                            props.isUpdate
                                                                        }
                                                                    ></AccordionItem>
                                                                </Accordion>
                                                            )}
                                                        </Flex>
                                                    )}
                                                    {props.isUpdate
                                                        ? alert.convWaitEveryday && (
                                                              <Flex
                                                                  direction={
                                                                      "row"
                                                                  }
                                                              >
                                                                  <NumberField
                                                                      UNSAFE_className="custom-textField"
                                                                      width={
                                                                          "100%"
                                                                      }
                                                                      value={
                                                                          alert.convWaitConditions
                                                                              ? alert.convWaitConditions
                                                                              : 0
                                                                      }
                                                                      label="Schedule Condition"
                                                                      labelPosition="side"
                                                                      labelAlign="end"
                                                                      minValue={
                                                                          0
                                                                      }
                                                                      maxValue={
                                                                          600
                                                                      }
                                                                      onChange={(
                                                                          e
                                                                      ) =>
                                                                          updateConvWaitConditions(
                                                                              index,
                                                                              e
                                                                          )
                                                                      }
                                                                      isRequired
                                                                      aria-label="seconds"
                                                                      formatOptions={{
                                                                          style:
                                                                              "unit",
                                                                          unit:
                                                                              "second",
                                                                      }}
                                                                      isQuiet
                                                                  />
                                                              </Flex>
                                                          )
                                                        : cnvType ===
                                                              "everyDay" && (
                                                              <Flex
                                                                  direction={
                                                                      "row"
                                                                  }
                                                              >
                                                                  <NumberField
                                                                      UNSAFE_className="custom-textField"
                                                                      width={
                                                                          "100%"
                                                                      }
                                                                      value={
                                                                          alert.convWaitConditions
                                                                              ? alert.convWaitConditions
                                                                              : 0
                                                                      }
                                                                      label="Schedule Condition"
                                                                      labelPosition="side"
                                                                      labelAlign="end"
                                                                      minValue={
                                                                          0
                                                                      }
                                                                      maxValue={
                                                                          600
                                                                      }
                                                                      onChange={(
                                                                          e
                                                                      ) =>
                                                                          updateConvWaitConditions(
                                                                              index,
                                                                              e
                                                                          )
                                                                      }
                                                                      isRequired
                                                                      aria-label="seconds"
                                                                      formatOptions={{
                                                                          style:
                                                                              "unit",
                                                                          unit:
                                                                              "second",
                                                                      }}
                                                                      isQuiet
                                                                  />
                                                              </Flex>
                                                          )}
                                                    <Divider
                                                        size="S"
                                                        margin={"10px 0px"}
                                                    />

                                                    {
                                                        <View>
                                                            <Flex
                                                                direction={
                                                                    "row"
                                                                }
                                                                justifyContent={
                                                                    "space-between"
                                                                }
                                                            >
                                                                <Checkbox
                                                                    isSelected={
                                                                        alert.convWaitAttribute
                                                                    }
                                                                    onChange={() =>
                                                                        toggleSelectCustomAttribute(
                                                                            index
                                                                        )
                                                                    }
                                                                >
                                                                    Custom
                                                                    Attribute
                                                                </Checkbox>
                                                                {alert.convWaitAttribute && (
                                                                    <Button
                                                                        aria-label="addKeyValue"
                                                                        variant="secondary"
                                                                        staticColor="black"
                                                                        style="fill"
                                                                        UNSAFE_className="noBorder cursor"
                                                                        onPress={() =>
                                                                            addNewCustomAttribute(
                                                                                index
                                                                            )
                                                                        }
                                                                    >
                                                                        <Add />
                                                                    </Button>
                                                                )}
                                                            </Flex>
                                                            {alert.convWaitAttribute &&
                                                                alert.convWaitAttributes.map(
                                                                    (
                                                                        ele,
                                                                        attrIndex
                                                                    ) =>
                                                                        console.log(
                                                                            {
                                                                                ele,
                                                                                attrIndex,
                                                                            }
                                                                        ) || (
                                                                            <View
                                                                                gap={
                                                                                    "10px"
                                                                                }
                                                                            >
                                                                                <Flex>
                                                                                    <ComboBox
                                                                                        defaultItems={[
                                                                                            {
                                                                                                id:
                                                                                                    "ORGID",
                                                                                                name:
                                                                                                    "ORGID",
                                                                                            },
                                                                                            {
                                                                                                id:
                                                                                                    "LEVEL",
                                                                                                name:
                                                                                                    "LEVEL",
                                                                                            },
                                                                                            {
                                                                                                id:
                                                                                                    "PRODUCTNAME",
                                                                                                name:
                                                                                                    "PRODUCTNAME",
                                                                                            },
                                                                                            {
                                                                                                id:
                                                                                                    "PRIORITY",
                                                                                                name:
                                                                                                    "PRIORITY",
                                                                                            },
                                                                                        ]}
                                                                                        width={
                                                                                            "40%"
                                                                                        }
                                                                                        inputValue={
                                                                                            ele.attributeName
                                                                                        }
                                                                                        isQuiet
                                                                                        onSelectionChange={(
                                                                                            val
                                                                                        ) =>
                                                                                            handleCallWaitAttributeNameChange(
                                                                                                val,
                                                                                                index,
                                                                                                attrIndex
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        {(
                                                                                            item
                                                                                        ) => (
                                                                                            <Item
                                                                                                key={
                                                                                                    item.id
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    item.name
                                                                                                }
                                                                                            </Item>
                                                                                        )}
                                                                                    </ComboBox>
                                                                                    <TextField
                                                                                        isQuiet
                                                                                        marginStart={
                                                                                            "10px"
                                                                                        }
                                                                                        type="text"
                                                                                        name="attributeValue"
                                                                                        value={
                                                                                            ele.attributeValue
                                                                                        }
                                                                                        onChange={(
                                                                                            value
                                                                                        ) =>
                                                                                            handleInputChange(
                                                                                                index,
                                                                                                attrIndex,
                                                                                                {
                                                                                                    target: {
                                                                                                        name:
                                                                                                            "attributeValue",
                                                                                                        value,
                                                                                                    },
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                        onKeyDown={(
                                                                                            event
                                                                                        ) => {
                                                                                            if (
                                                                                                event.key ===
                                                                                                "Enter"
                                                                                            ) {
                                                                                                handleKeyPress(
                                                                                                    index,
                                                                                                    attrIndex,
                                                                                                    event
                                                                                                )
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    {alert.convWaitAttribute && (
                                                                                        <Button
                                                                                            UNSAFE_style={{
                                                                                                transform:
                                                                                                    "scale(0.5)",
                                                                                                marginTop:
                                                                                                    "10px",
                                                                                            }}
                                                                                            aria-label="addKeyValue"
                                                                                            variant="secondary"
                                                                                            // staticColor="black"
                                                                                            style="fill"
                                                                                            UNSAFE_className="cursor"
                                                                                            onPress={() =>
                                                                                                removeCustomAttribute(
                                                                                                    index,
                                                                                                    attrIndex
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <Close />
                                                                                        </Button>
                                                                                    )}
                                                                                </Flex>
                                                                                <View>
                                                                                    {alert.convWaitAttribute &&
                                                                                        queue
                                                                                            ?.alertTypes
                                                                                            ?.convWaitAlerts[
                                                                                            index
                                                                                        ]
                                                                                            ?.convWaitAttributes[
                                                                                            attrIndex
                                                                                        ]
                                                                                            ?.attributeValues
                                                                                            ?.length >
                                                                                            0 && (
                                                                                            <View
                                                                                                UNSAFE_style={{
                                                                                                    textAlign:
                                                                                                        "end",
                                                                                                }}
                                                                                            >
                                                                                                <Text
                                                                                                    UNSAFE_style={{
                                                                                                        fontSize:
                                                                                                            "12px",
                                                                                                        fontStyle:
                                                                                                            "italic",
                                                                                                        fontWeight: 600,
                                                                                                    }}
                                                                                                >
                                                                                                    {
                                                                                                        queue
                                                                                                            .alertTypes
                                                                                                            ?.convWaitAlerts[
                                                                                                            index
                                                                                                        ]
                                                                                                            .convWaitAttributes[
                                                                                                            attrIndex
                                                                                                        ]
                                                                                                            .attributeValues
                                                                                                            .length
                                                                                                    }{" "}
                                                                                                    attribute
                                                                                                    values
                                                                                                    entered
                                                                                                </Text>
                                                                                                <Text>
                                                                                                    {" "}
                                                                                                    {showAllButton(
                                                                                                        "convWaitAttributes",
                                                                                                        alert
                                                                                                            .convWaitAttributes[
                                                                                                            attrIndex
                                                                                                        ]
                                                                                                            .attributeValues,
                                                                                                        index,

                                                                                                        attrIndex
                                                                                                    )}
                                                                                                </Text>
                                                                                            </View>
                                                                                        )}
                                                                                </View>
                                                                            </View>
                                                                        )
                                                                )}
                                                        </View>
                                                    }
                                                    <Divider
                                                        size="S"
                                                        margin={"10px 0px"}
                                                        height={"0.1px"}
                                                    />

                                                    {
                                                        <Flex
                                                            direction={"column"}
                                                        >
                                                            {/* <View>
                                                                <Checkbox
                                                                    onChange={() =>
                                                                        handleCheckboxChange(
                                                                            "global",
                                                                            index
                                                                        )
                                                                    }
                                                                    isSelected={
                                                                        alert.globalAlert
                                                                    }
                                                                    value="global"
                                                                >
                                                                    Global Alert
                                                                </Checkbox>
                                                                <ContextualHelp variant="help" marginTop={"5px"}>
                                                                    <Heading>
                                                                        Global Alert
                                                                    </Heading>
                                                                    <Content>
                                                                        <Text>
                                                                        If you check this checkbox, alerts will be sent to the primary Email and Slack mentioned above.
                                                                        </Text>
                                                                    </Content>
                                                                </ContextualHelp>
                                                            </View> */}
                                                            <View>
                                                                <Checkbox
                                                                    onChange={() =>
                                                                        handleCheckboxChange(
                                                                            "custom",
                                                                            index
                                                                        )
                                                                    }
                                                                    isSelected={
                                                                        alert.customAlert
                                                                    }
                                                                    value="custom"
                                                                >
                                                                    Custom Email/Slack
                                                                </Checkbox>
                                                                <ContextualHelp variant="help" marginTop={"5px"}>
                                                                    <Heading>
                                                                        Custom Email/Slack
                                                                    </Heading>
                                                                    <Content>
                                                                        <Text>
                                                                        If you check this checkbox, alerts will be sent to the custom email and Slack mentioned below.
                                                                        </Text>
                                                                    </Content>
                                                                </ContextualHelp>
                                                            </View>
                                                            {/* </CheckboxGroup> */}
                                                        </Flex>
                                                    }
                                                    {alert.customAlert && (
                                                        <>
                                                            <Flex
                                                                direction="row"
                                                                // gap="size-1000"
                                                            >
                                                                <View
                                                                    alignSelf="center"
                                                                    minWidth="size-1600"
                                                                    width={
                                                                        "40%"
                                                                    }
                                                                >
                                                                    Email
                                                                    <ContextualHelp variant="help">
                                                                        <Heading>
                                                                            Email
                                                                        </Heading>
                                                                        <Content>
                                                                            <Text>
                                                                                Provide
                                                                                Email
                                                                                and
                                                                                press
                                                                                enter
                                                                                to
                                                                                add
                                                                                more.
                                                                            </Text>
                                                                        </Content>
                                                                    </ContextualHelp>
                                                                </View>
                                                                <TextField
                                                                    width={
                                                                        "60%"
                                                                    }
                                                                    placeholder="Enter Email and hit enter"
                                                                    marginStart={
                                                                        "3px"
                                                                    }
                                                                    isQuiet
                                                                    value={
                                                                        callWaitEmail
                                                                    }
                                                                    onChange={
                                                                        setCallWaitEmail
                                                                    }
                                                                    onKeyDown={(
                                                                        e
                                                                    ) => {
                                                                        handleCallWaitEmailChange(
                                                                            e,
                                                                            index
                                                                        )
                                                                    }}
                                                                />
                                                            </Flex>
                                                            {queue?.alertTypes
                                                                ?.convWaitAlerts[
                                                                index
                                                            ]?.alertInfo
                                                                ?.emailRecipients
                                                                ?.length > 0 ? (
                                                                <View
                                                                    UNSAFE_style={{
                                                                        textAlign:
                                                                            "end",
                                                                    }}
                                                                >
                                                                    <Text
                                                                        UNSAFE_style={{
                                                                            fontSize:
                                                                                "12px",
                                                                            fontStyle:
                                                                                "italic",
                                                                            fontWeight: 600,
                                                                        }}
                                                                    >
                                                                        {
                                                                            queue
                                                                                .alertTypes
                                                                                ?.convWaitAlerts[
                                                                                index
                                                                            ]
                                                                                .alertInfo
                                                                                .emailRecipients
                                                                                .length
                                                                        }{" "}
                                                                        emails
                                                                        entered{" "}
                                                                    </Text>
                                                                    <Text>
                                                                        {showAllButton(
                                                                            "email",
                                                                            queue
                                                                                .alertTypes
                                                                                ?.convWaitAlerts[
                                                                                index
                                                                            ]
                                                                                .alertInfo
                                                                                .emailRecipients,
                                                                            index
                                                                        )}
                                                                    </Text>
                                                                </View>
                                                            ) : null}
                                                            <Flex
                                                                direction="row"

                                                                // gap="13%"
                                                            >
                                                                <View
                                                                    alignSelf="center"
                                                                    minWidth="size-1600"
                                                                    width={
                                                                        "40%"
                                                                    }
                                                                >
                                                                    Slack
                                                                    Webhook URL
                                                                    <ContextualHelp variant="help">
                                                                        <Heading>
                                                                            Webhook
                                                                            URL
                                                                            of
                                                                            Slack
                                                                            channel
                                                                        </Heading>
                                                                        <Content>
                                                                            <Text>
                                                                                Webhook
                                                                                URL
                                                                                of
                                                                                Slack
                                                                                channel
                                                                                where
                                                                                notifications
                                                                                will
                                                                                be
                                                                                triggered
                                                                                (Your
                                                                                Slack
                                                                                workspace
                                                                                admin
                                                                                can
                                                                                generate
                                                                                the
                                                                                webhook
                                                                                for
                                                                                your
                                                                                slack
                                                                                channel)
                                                                            </Text>
                                                                        </Content>
                                                                    </ContextualHelp>
                                                                </View>
                                                                <TextField
                                                                    width={
                                                                        "60%"
                                                                    }
                                                                    placeholder="Enter Slack Webhook URL and hit enter"
                                                                    isQuiet
                                                                    value={
                                                                        callWaitSlack
                                                                    }
                                                                    onChange={
                                                                        setCallWaitSlack
                                                                    }
                                                                    onKeyDown={(
                                                                        e
                                                                    ) => {
                                                                        handleCallWaitSlackChange(
                                                                            e,
                                                                            index
                                                                        )
                                                                    }}
                                                                />
                                                            </Flex>
                                                            {queue?.alertTypes
                                                                ?.convWaitAlerts[
                                                                index
                                                            ]?.alertInfo
                                                                ?.slackWebhooks
                                                                ?.length > 0 ? (
                                                                <View
                                                                    UNSAFE_style={{
                                                                        textAlign:
                                                                            "end",
                                                                    }}
                                                                >
                                                                    <Text
                                                                        UNSAFE_style={{
                                                                            fontSize:
                                                                                "12px",
                                                                            fontStyle:
                                                                                "italic",
                                                                            fontWeight: 600,
                                                                        }}
                                                                    >
                                                                        {
                                                                            queue
                                                                                .alertTypes
                                                                                ?.convWaitAlerts[
                                                                                index
                                                                            ]
                                                                                .alertInfo
                                                                                .slackWebhooks
                                                                                .length
                                                                        }{" "}
                                                                        slack
                                                                        urls
                                                                        entered{" "}
                                                                    </Text>
                                                                    {showAllButton(
                                                                        "slack",
                                                                        queue
                                                                            .alertTypes
                                                                            ?.convWaitAlerts[
                                                                            index
                                                                        ]
                                                                            .alertInfo
                                                                            .slackWebhooks,
                                                                        index
                                                                    )}
                                                                </View>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </View>
                                            )
                                        )

                                    // (
                                    //     <View marginStart={"size-250"} UNSAFE_style={{border:"1px solid red"}}>
                                    //         <Flex direction={"row"}>
                                    //             <RadioGroup
                                    //                 label="Schedule"
                                    //                 labelPosition="side"
                                    //                 value={cnvType}
                                    //                 orientation={"horizontal"}
                                    //                 onChange={covTypeChange}
                                    //             >
                                    //                 <Radio value="everyDay">
                                    //                     Everyday
                                    //                 </Radio>
                                    //                 <Radio value="custom">
                                    //                     Custom
                                    //                 </Radio>
                                    //             </RadioGroup>
                                    //         </Flex>
                                    //         <Flex direction={"row"}>
                                    //             {queue.alertTypes
                                    //                 .convWaitCustom && (
                                    //                 <Accordion>
                                    //                     <AccordionItem
                                    //                         key={`1-acc`}
                                    //                         title="Custom CallWait Configuration"
                                    //                         addNewCnvThreshold={
                                    //                             addNewCnvThresholdData
                                    //                         }
                                    //                         queue={queue}
                                    //                         removeCnvThresholdIndex={
                                    //                             removeCnvThresholdIndex
                                    //                         }
                                    //                         updateThresholdData={
                                    //                             updateThresholdData
                                    //                         }
                                    //                         timezonesValue={
                                    //                             timezonesValue
                                    //                         }
                                    //                         isUpdate={
                                    //                             props.isUpdate
                                    //                         }
                                    //                     ></AccordionItem>
                                    //                 </Accordion>
                                    //             )}
                                    //         </Flex>
                                    //         {(queue.alertTypes.convWaitEveryday ||
                                    //             cnvType === "everyDay") && (
                                    //             <Flex direction={"row"}>
                                    //                 <NumberField
                                    //                     maxWidth={"250px"}
                                    //                     value={
                                    //                         queue.alertTypes
                                    //                             .convWaitConditions
                                    //                             ? queue.alertTypes
                                    //                                   .convWaitConditions
                                    //                             : 0
                                    //                     }
                                    //                     marginEnd={"35px"}
                                    //                     // marginTop={'20px'}
                                    //                     label="Schedule Condition"
                                    //                     labelPosition="side"
                                    //                     labelAlign="end"
                                    //                     minValue={0}
                                    //                     maxValue={600}
                                    //                     onChange={(e) =>
                                    //                         updateQueueAlertData(
                                    //                             "convWaitConditions",
                                    //                             e
                                    //                         )
                                    //                     }
                                    //                     isRequired
                                    //                     aria-label="seconds"
                                    //                     formatOptions={{
                                    //                         style: "unit",
                                    //                         unit: "second",
                                    //                     }}
                                    //                     isQuiet
                                    //                 />
                                    //             </Flex>
                                    //         )}
                                    //     </View>
                                    // )
                                }
                            </Flex>
                            {/* CAAALLL WAIIIT ENNNDDD */}

                            {/* Flex Row end */}

                            <View marginTop={"12px"}>
                                <Flex direction={"column"}>
                                    <Flex direction={"row"}>
                                        <View width={"70%"}>
                                            <Checkbox
                                                UNSAFE_className="checkBoxLabelBeforeHelp"
                                                isSelected={
                                                    queue.alertTypes
                                                        ?.onQueueUsers
                                                }
                                                onChange={(e) =>
                                                    updateQueueAlertData(
                                                        "onQueueUsers",
                                                        e
                                                    )
                                                }
                                            >
                                                OnQueue Agent
                                            </Checkbox>
                                            <ContextualHelp
                                                UNSAFE_className="helpContextIcon"
                                                variant="help"
                                            >
                                                <Heading>OnQueueAgent</Heading>
                                                <Content>
                                                    <Text>
                                                        To receive notifications
                                                        if minimum number of
                                                        agents (mentioned in
                                                        Available Agent
                                                        Threshold below) are not
                                                        available to take call
                                                        in the selected queue.
                                                    </Text>
                                                </Content>
                                            </ContextualHelp>
                                        </View>
                                        {props.queue.alertTypes
                                            ?.onQueueUsers && (
                                            <AlertOptions
                                                email={
                                                    queue.alertTypes
                                                        ?.onQueueUsersAlertOptions
                                                        ?.email
                                                }
                                                slack={
                                                    queue.alertTypes
                                                        ?.onQueueUsersAlertOptions
                                                        ?.slack
                                                }
                                                onEmailChange={(value) =>
                                                    updateQueueAlertOptions(
                                                        "onQueueUsersAlertOptions",
                                                        "email",
                                                        value
                                                    )
                                                }
                                                onSlackChange={(value) =>
                                                    updateQueueAlertOptions(
                                                        "onQueueUsersAlertOptions",
                                                        "slack",
                                                        value
                                                    )
                                                }
                                                disabled={
                                                    !queue.alertTypes
                                                        ?.onQueueUsers
                                                }
                                            />
                                        )}
                                    </Flex>

                                    {queue.alertTypes?.onQueueUsers && (
                                        <Flex
                                            direction={"column"}
                                            marginStart="size-250"
                                        >
                                            <Flex
                                                direction={"row"}
                                                justifyContent="space-between"
                                                UNSAFE_style={{
                                                    paddingRight: "10px",
                                                }}
                                            >
                                                <h5>Schedules</h5>
                                                <Button
                                                    aria-label="addKeyValue"
                                                    variant="secondary"
                                                    staticColor="black"
                                                    style="fill"
                                                    isDisabled={addSchedule}
                                                    UNSAFE_className="noBorder cursor"
                                                    onPress={() =>
                                                        addNewThresholdData()
                                                    }
                                                >
                                                    <Add />
                                                </Button>
                                            </Flex>
                                            <View>
                                                {queue.alertTypes?.userThresholdSchedules.map(
                                                    (schedule, index) => (
                                                        <Flex
                                                            key={`main-${index}`}
                                                            direction={"column"}
                                                            // marginStart="size-600"
                                                            gap="size-100"
                                                            // marginStart={"size-250"}
                                                            UNSAFE_style={{
                                                                border:
                                                                    "1px solid #c3c3c3",
                                                                padding: "10px",
                                                                borderRadius:
                                                                    "5px",
                                                                marginBottom:
                                                                    "5px",
                                                            }}
                                                        >
                                                            <Flex
                                                                direction={
                                                                    "row"
                                                                }
                                                                rowGap="size-800"
                                                                marginTop="size-100"
                                                                gap={"size-900"}
                                                            >
                                                                <Text
                                                                    width={
                                                                        "size-1250"
                                                                    }
                                                                >
                                                                    <h5 className="schedule-label">
                                                                        Days
                                                                    </h5>
                                                                </Text>
                                                                <Flex
                                                                    direction={
                                                                        "column"
                                                                    }
                                                                >
                                                                    <Flex
                                                                        direction={
                                                                            "row"
                                                                        }
                                                                        justifyContent="space-between"
                                                                    >
                                                                        <Checkbox
                                                                            isSelected={
                                                                                schedule.days !=
                                                                                    undefined &&
                                                                                schedule.days.toLowerCase() ===
                                                                                    "all"
                                                                            }
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                updateThresholdData(
                                                                                    "days",
                                                                                    index,
                                                                                    e
                                                                                )
                                                                            }
                                                                            id={
                                                                                index
                                                                            }
                                                                        >
                                                                            Everyday
                                                                        </Checkbox>
                                                                        {queue
                                                                            .alertTypes
                                                                            ?.userThresholdSchedules
                                                                            .length >
                                                                            1 &&
                                                                            !props.isUpdate && (
                                                                                <ActionButton
                                                                                    onPress={() =>
                                                                                        removeThresholdIndex(
                                                                                            index,
                                                                                            queue
                                                                                                .alertTypes
                                                                                                ?.userThresholdSchedules
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <Delete />
                                                                                </ActionButton>
                                                                            )}
                                                                        {queue
                                                                            .alertTypes
                                                                            ?.userThresholdSchedules
                                                                            .length >
                                                                            1 &&
                                                                            props.isUpdate && (
                                                                                <DialogTrigger>
                                                                                    <ActionButton aria-label="Delete">
                                                                                        <Delete />
                                                                                    </ActionButton>
                                                                                    <AlertDialog
                                                                                        variant="confirmation"
                                                                                        title="Delete record?"
                                                                                        primaryActionLabel="Confirm"
                                                                                        cancelLabel="Cancel"
                                                                                        onPrimaryAction={() =>
                                                                                            removeThresholdIndex(
                                                                                                index,
                                                                                                queue
                                                                                                    .alertTypes
                                                                                                    ?.userThresholdSchedules
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        Are
                                                                                        you
                                                                                        sure
                                                                                        you
                                                                                        want
                                                                                        to
                                                                                        delete
                                                                                        this
                                                                                        record?
                                                                                    </AlertDialog>
                                                                                </DialogTrigger>
                                                                            )}
                                                                    </Flex>
                                                                    <CheckboxGroup
                                                                        defaultValue={[]}
                                                                        value={
                                                                            schedule.days &&
                                                                            schedule.days.split(
                                                                                ","
                                                                            )
                                                                        }
                                                                        isDisabled={
                                                                            schedule.days &&
                                                                            schedule.days.toLowerCase() ===
                                                                                "all"
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            updateThresholdData(
                                                                                "days",
                                                                                index,
                                                                                e
                                                                            )
                                                                        }
                                                                        orientation="horizontal"
                                                                        id={`day-${index}`}
                                                                    >
                                                                        <Checkbox
                                                                            id={`monday-${index}`}
                                                                            value="MONDAY"
                                                                        >
                                                                            Mon
                                                                        </Checkbox>
                                                                        <Checkbox
                                                                            id={`tuesday-${index}`}
                                                                            value="TUESDAY"
                                                                        >
                                                                            Tue
                                                                        </Checkbox>
                                                                        <Checkbox
                                                                            id={`wensday-${index}`}
                                                                            value="WEDNESDAY"
                                                                        >
                                                                            Wed
                                                                        </Checkbox>
                                                                        <Checkbox
                                                                            id={`thursday-${index}`}
                                                                            value="THURSDAY"
                                                                        >
                                                                            Thu
                                                                        </Checkbox>
                                                                        <Checkbox
                                                                            id={`friday-${index}`}
                                                                            value="FRIDAY"
                                                                        >
                                                                            Fri
                                                                        </Checkbox>
                                                                        <Checkbox
                                                                            id={`sat-${index}`}
                                                                            value="SATURDAY"
                                                                        >
                                                                            Sat
                                                                        </Checkbox>
                                                                        <Checkbox
                                                                            id={`sunday-${index}`}
                                                                            value="SUNDAY"
                                                                        >
                                                                            Sun
                                                                        </Checkbox>
                                                                    </CheckboxGroup>
                                                                </Flex>
                                                            </Flex>
                                                            <Flex
                                                                direction={
                                                                    "row"
                                                                }
                                                                rowGap="size-800"
                                                                gap={"size-400"}
                                                            >
                                                                <Text
                                                                    width={
                                                                        "size-1250"
                                                                    }
                                                                >
                                                                    <h5 className="schedule-label">
                                                                        Start
                                                                        Time
                                                                    </h5>
                                                                </Text>
                                                                <Flex
                                                                    direction={
                                                                        "column"
                                                                    }
                                                                >
                                                                    <Checkbox
                                                                        isSelected={
                                                                            schedule.startTime &&
                                                                            schedule.startTime.toLowerCase() ===
                                                                            "all"
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            updateThresholdData(
                                                                                "startTime",
                                                                                index,
                                                                                e
                                                                            )
                                                                        }
                                                                        aria-label="allday"
                                                                    >
                                                                        All Day
                                                                    </Checkbox>
                                                                    <TimeField
                                                                        value={
                                                                            schedule.startTime &&
                                                                            new Time(
                                                                                schedule.startTime.split(
                                                                                    ":"
                                                                                )[0],
                                                                                schedule.startTime.split(
                                                                                    ":"
                                                                                )[1],
                                                                                schedule.startTime.split(
                                                                                    ":"
                                                                                )[2]
                                                                            )
                                                                        }
                                                                        isDisabled={
                                                                            schedule.startTime &&
                                                                            schedule.startTime.toLowerCase() ===
                                                                            "all"
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            updateThresholdData(
                                                                                "startTime",
                                                                                index,
                                                                                e
                                                                            )
                                                                        }
                                                                        granularity="second"
                                                                        hourCycle={
                                                                            24
                                                                        }
                                                                        aria-label="time"
                                                                        isQuiet
                                                                    />
                                                                </Flex>
                                                            </Flex>
                                                            <Flex
                                                                direction={
                                                                    "row"
                                                                }
                                                                gap={"size-400"}
                                                            >
                                                                <Text
                                                                    width={
                                                                        "size-1250"
                                                                    }
                                                                >
                                                                    <h5 className="schedule-label">
                                                                        End Time
                                                                    </h5>
                                                                </Text>
                                                                <TimeField
                                                                    value={
                                                                        new Time(
                                                                            schedule.endTime.split(
                                                                                ":"
                                                                            )[0],
                                                                            schedule.endTime.split(
                                                                                ":"
                                                                            )[1],
                                                                            schedule.endTime.split(
                                                                                ":"
                                                                            )[2]
                                                                        )
                                                                    }
                                                                    isDisabled={
                                                                        schedule.startTime &&
                                                                        schedule.startTime.toLowerCase() ===
                                                                        "all"
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        updateThresholdData(
                                                                            "endTime",
                                                                            index,
                                                                            e
                                                                        )
                                                                    }
                                                                    hourCycle={
                                                                        24
                                                                    }
                                                                    aria-label="endtime"
                                                                    granularity="second"
                                                                    isQuiet
                                                                />
                                                            </Flex>
                                                            <Flex
                                                                direction={
                                                                    "row"
                                                                }
                                                                gap={"size-400"}
                                                            >
                                                                <Text
                                                                    width={
                                                                        "size-1250"
                                                                    }
                                                                >
                                                                    <h5 className="schedule-label">
                                                                        Timezone
                                                                        <Text UNSAFE_className="errorMessage">
                                                                            *
                                                                        </Text>
                                                                    </h5>
                                                                </Text>
                                                                {!props.isUpdate && (
                                                                    <ComboBox
                                                                        items={
                                                                            timezonesValue
                                                                        }
                                                                        selectedKey={
                                                                            schedule.timezone
                                                                        }
                                                                        onSelectionChange={(
                                                                            e
                                                                        ) =>
                                                                            updateThresholdData(
                                                                                "timezone",
                                                                                index,
                                                                                e
                                                                            )
                                                                        }
                                                                        aria-label="timezone"
                                                                        width="size-3000"
                                                                        isQuiet
                                                                    >
                                                                        {(
                                                                            timezone
                                                                        ) => (
                                                                            <Item
                                                                                key={
                                                                                    timezone.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    timezone.name
                                                                                }
                                                                            </Item>
                                                                        )}
                                                                    </ComboBox>
                                                                )}
                                                                {props.isUpdate && (
                                                                    <ComboBox
                                                                        items={
                                                                            timezonesValue
                                                                        }
                                                                        selectedKey={
                                                                            schedule.timezone
                                                                        }
                                                                        onSelectionChange={(
                                                                            e
                                                                        ) =>
                                                                            updateThresholdData(
                                                                                "timezone",
                                                                                index,
                                                                                e
                                                                            )
                                                                        }
                                                                        aria-label="timezone"
                                                                        width="size-3000"
                                                                        isQuiet
                                                                    >
                                                                        {(
                                                                            timezone
                                                                        ) => (
                                                                            <Item
                                                                                key={
                                                                                    timezone.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    timezone.name
                                                                                }
                                                                            </Item>
                                                                        )}
                                                                    </ComboBox>
                                                                )}
                                                            </Flex>
                                                            <Flex
                                                                direction={
                                                                    "row"
                                                                }
                                                                alignContent={
                                                                    "center"
                                                                }
                                                            >
                                                                <Text>
                                                                    <h5 className="schedule-label">
                                                                        Agent
                                                                        Threshold
                                                                        <Text UNSAFE_className="errorMessage">
                                                                            *
                                                                        </Text>
                                                                    </h5>
                                                                </Text>
                                                                <ContextualHelp
                                                                    alignSelf="center"
                                                                    variant="help"
                                                                >
                                                                    <Heading>
                                                                        Agent
                                                                        Threshold
                                                                    </Heading>
                                                                    <Content>
                                                                        <Text>
                                                                            Occurred
                                                                            notification
                                                                            will
                                                                            be
                                                                            triggered
                                                                            if
                                                                            this
                                                                            Threshold
                                                                            is
                                                                            matched
                                                                            and
                                                                            Resolved
                                                                            notification
                                                                            is
                                                                            triggered
                                                                            once
                                                                            available
                                                                            agent
                                                                            count
                                                                            go
                                                                            beyond
                                                                            this
                                                                            threshold.
                                                                        </Text>
                                                                    </Content>
                                                                </ContextualHelp>
                                                                <NumberField
                                                                    minValue={0}
                                                                    maxValue={
                                                                        99
                                                                    }
                                                                    value={
                                                                        schedule.onQueueUserThreshold
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        updateThresholdData(
                                                                            "onQueueUserThreshold",
                                                                            index,
                                                                            e
                                                                        )
                                                                    }
                                                                    aria-label="threshold"
                                                                    height="0px"
                                                                    width="size-3000"
                                                                    isQuiet
                                                                />
                                                            </Flex>

                                                            {queue.alertTypes
                                                                .userThresholdSchedules
                                                                .length > 1 &&
                                                                queue.alertTypes
                                                                    ?.userThresholdSchedules
                                                                    .length !=
                                                                    index +
                                                                        1 && (
                                                                    <Divider size="M" />
                                                                )}
                                                        </Flex>
                                                    )
                                                )}
                                            </View>
                                        </Flex>
                                    )}
                                    <Flex
                                        direction="row"
                                        justifyContent="center"
                                        marginTop="size-200"
                                    >
                                        {errorMessage && (
                                            <Text align="center">
                                                {" "}
                                                <span className="errorMessage">
                                                    Error : {errorMessage}
                                                </span>
                                            </Text>
                                        )}
                                    </Flex>
                                    <Flex
                                        direction="row"
                                        justifyContent="space-around"
                                        marginTop="size-400"
                                    >
                                        {!props.isUpdate && (
                                            <Button
                                                onPress={() =>
                                                    submitForm(queue)
                                                }
                                                isDisabled={disableSubmit}
                                            >
                                                Submit
                                            </Button>
                                        )}
                                        {props.isUpdate && (
                                            <Button
                                                onPress={() =>
                                                    updateForm(queue)
                                                }
                                            >
                                                Update
                                            </Button>
                                        )}
                                    </Flex>
                                </Flex>
                            </View>
                        </Flex>
                    </View>
                )}
            </Flex>
        </div>
    )
}

export default connect(mapStateToProps)(EditMonitorQueueList)
