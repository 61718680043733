import React, { useEffect, useState, useRef } from 'react';
import Delete from '@spectrum-icons/workflow/Delete';
import Draw from '@spectrum-icons/workflow/Draw';
import 'react-schedule-job/dist/index.css'
import { connect } from 'react-redux';
import '../index.css';
import {
    Flex, lightTheme, Provider, SearchField, ActionGroup, Item, Text,
    Content, IllustratedMessage, Badge, TableView, TableHeader, Column,
    TableBody, Cell, Row, Heading
} from '@adobe/react-spectrum';
import DeleteDialog from './deleteDialog';
import AddUserDialog from "./AddUserDialog";
import { deleteUsers, getUsers, saveUser } from '../utilities/api/admin-api';
import ErrorDialog from './ErrorDialog';
import { notify } from '../constants/constants';
import { renderEmptyState } from '../utilities/components/CommonUseComponents';

function mapStateToProps(state) {
    return {
        user: state.login.userInfo,
    };
}

/**
 * A component for managing user data and performing user operations.
 * @param {{object}} props - The component props.
 * @returns The UserManagement component.
 */
const UserManagement = (props) => {
    const [allUsers, setAllUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [currentText, setCurrentText] = useState('');
    const [selectedUsers, setSelectedUsers] = useState(new Set());
    const [operation, setOperation] = useState('');
    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
    const [isdeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [showError, setShowError] = useState(false);

    const columns = [
        { name: 'Username', key: 'username' },
        { name: 'Roles', key: 'roles' },
        { name: 'Permissions', key: 'permissions' },
        { name: 'Actions', key: 'actions' }
    ];

    const currentUserSelected = useRef('');
    const editData = useRef(false);
    const isLoading = useRef(false);

    const generateKey = (pre) => {
        return `${ pre }_${ new Date().getTime() }`;
    }

    function getAllUsersData() {
        isLoading.current = true;
        getUsers()
            .then((users) => {
                isLoading.current = false;
                setAllUsers(users);
                setFilteredUsers(users)

            }).catch((error) => {
                isLoading.current = false;
                console.log(error)
                setErrorMessage('Error occured!');
            })
    }

    const getRoles = (roles) => {
        const roleNames = [];
        roles.forEach(element => {
            roleNames.push(element.name);
        });
        return roleNames.join(',');
    }

    const getRolesBadge = (roles) => {
        const badges = [];
        roles.forEach(element => {
            badges.push((<Badge aria-label={`${generateKey(element.name)}`} key={generateKey(element.name)} variant="neutral">{element.name}</Badge>));
        });
        return badges;
    }

    const getPermissionBadge = (roles) => {
        const permissionNames = [];
        roles.forEach(role => {
            role.permissions.forEach(permission => {
                permissionNames.push(permission);
            })
        });

        const uniquePermissionNames = permissionNames.filter((v, i, a) => a.indexOf(v) === i);
        const badges = [];
        uniquePermissionNames.forEach(element => badges.push((<Badge aria-label={`${generateKey(element)}`} key={generateKey(element)} variant="neutral">{element}</Badge>)))
        return badges;
    }

    const getPermissions = (roles) => {
        const permissionNames = [];
        roles.forEach(role => {
            role.permissions.forEach(permission => {
                permissionNames.push(permission);
            })
        });
        const uniquePermissionNames = permissionNames.filter((v, i, a) => a.indexOf(v) === i);
        return uniquePermissionNames.join(',');
    }

    function deleteSelectedUsers(usersToDelete) {
        isLoading.current = true;
        deleteUsers(usersToDelete, props.user.userid)
            .then((res) => {
                isLoading.current = false;
                if (res.error) {
                    setErrorMessage(res.error);
                }
                else {
                    getAllUsersData();
                    setSelectedUsers(new Set())
                }

            }).catch((error) => {
                isLoading.current = false;
                console.log(error)
                setErrorMessage('Error occured!');
            })
    }

    const filterFunction = (value) => {
        const filteredUsers = allUsers.filter(user =>
            {
                return user?.username?.toLowerCase().includes(value.toLowerCase())
                || getRoles(user?.roles)?.toLowerCase().includes(value.toLowerCase())
                || getPermissions(user?.roles)?.toLowerCase().includes(value.toLowerCase())
            }
        )
        console.log({value,filteredUsers});
        setFilteredUsers(filteredUsers)
    };

    useEffect(() => {
        getAllUsersData();
    }, []);

    useEffect(()=>{
        if(!filteredUsers.length)
            setFilteredUsers(allUsers)
        console.log({filteredUsers});
    },[filteredUsers])

    useEffect(()=>{
        const timeout = setTimeout(() => {
            if(currentText)
            filterFunction(currentText);
            else
            setFilteredUsers(allUsers);
        }, 500);
        return ()=> clearTimeout(timeout);
    },[currentText]);

    const filterUsers = (value) => {
        console.log({value});
        setCurrentText(value);
    }


    const performAction = (value, username) => {
        switch (value) {
            case 'add':
                editData.current = null;
                setOperation("Add User")
                setIsAddDialogOpen(true);
                break;
            case 'edit':
                editData.current = allUsers.find((user) => user.username == username);
                setOperation("Edit User")
                setIsAddDialogOpen(true);
                break;
            case 'delete':
                currentUserSelected.current = username;
                setIsDeleteDialogOpen(true);
                break;
            case 'bulkDelete':
                currentUserSelected.current = username;
                setIsDeleteDialogOpen(true);
                break;
        }
    }

    const closeDeleteDialog = (isDeletedConfirmed) => {
        setIsDeleteDialogOpen(false);
        if (isDeletedConfirmed) {
            let usersToDelete = [];
            if (currentUserSelected.current instanceof Set) {
                currentUserSelected.current.forEach((username) => {
                    usersToDelete.push(username);
                });
            }
            else {
                usersToDelete = [currentUserSelected.current];
            }

            if (selectedUsers == 'all') {
                usersToDelete = allUsers.map(user => user.username)
            }
            deleteSelectedUsers(usersToDelete);
        }
    }

    const closeAddUserDialog = (isAddConfirmed, userData, error) => {
        setIsAddDialogOpen(false);
        setShowError(error);
        if (isAddConfirmed) {
            isLoading.current = true;

            saveUser(userData, props.user.userid)
                .then((res) => {
                    isLoading.current = false;
                    if (res) {
                        notify('User Roles updated successfully', 'success');
                        getAllUsersData();
                    }
                }).catch((error) => {
                    isLoading.current = false;
                    notify('Error occured!', 'error');
                    console.log("add error", error)
                })
        }
    }

    const getCell = (columnKey, item) => {
        switch (columnKey) {
            case 'actions':
                return (
                    <Cell>
                        <ActionGroup onAction={(value) => performAction(value, item.username)}>
                            <Item key="edit">
                                <Draw />
                            </Item>
                            <Item key="delete">
                                <Delete />
                            </Item>
                        </ActionGroup>
                    </Cell>
                );
            case 'roles':
                return (
                    <Cell>
                        <Flex direction="row" gap={8} wrap="wrap" maxWidth={500}>
                            {getRolesBadge(item[columnKey])}
                        </Flex>
                    </Cell>
                )
            case 'permissions':
                return (
                    <Cell>
                        <Flex direction="row" gap={8} wrap="wrap" maxWidth={580}>
                            {getPermissionBadge(item['roles'])}
                        </Flex>
                    </Cell>
                )
            default:
                return (
                    <Cell>{item[columnKey]}</Cell>
                );
        }
    }

    const closeErrorDialog = () => {
        setShowError(false);
      }

    return (
        <>
            <div align="center">
                <Heading variant="pageTitle">Care Users Details</Heading>
            </div>

            <div
                style={{
                    padding: '25px',
                    border: '0.8px solid',
                    borderRadius: '0.7em',
                    marginTop: '30px',
                }}
            >

                <Provider theme={lightTheme}>
                    <Flex direction="row" gap="size-100" UNSAFE_className="searchMobile">
                    <SearchField isQuiet
                            onClear={() => setCurrentText('')}
                            onChange={filterUsers}
                            aria-label="Search"
                            value={currentText}
                            width={'20%'}
                        />
                        <ActionGroup width={'80%'} alignItems="end" onAction={(value) => performAction(value, selectedUsers)} disabledKeys={(selectedUsers.size || selectedUsers == 'all') ? [] : ['bulkDelete']}>
                            <Item key="add">+ Add</Item>
                            <Item key="bulkDelete" ><Delete /><Text>Delete</Text></Item>
                        </ActionGroup>


                    </Flex>

                    <TableView aria-label="spam users"
                        selectionMode="multiple"
                        selectedKeys={selectedUsers}
                        onSelectionChange={setSelectedUsers}
                        height={'45vh'}
                        renderEmptyState={renderEmptyState}
                        density="spacious"
                        overflowMode="wrap"
                    >

                        <TableHeader columns={columns}>
                            {(column) => (
                                <Column key={column.key} align={column.key === 'actions' ? 'end' : 'start'}
                                    minWidth={column.key === 'permissions' ? '50%' : '10%'}>
                                    {column.name}
                                </Column>
                            )}
                        </TableHeader>
                        <TableBody items={filteredUsers} loadingState={isLoading.current ? 'loading' : ''}>
                            {item => (
                                <Row key={item.username}>
                                    {(columnKey) => getCell(columnKey, item)}
                                </Row>
                            )}
                        </TableBody>
                    </TableView>
                </Provider>
                {isdeleteDialogOpen && <DeleteDialog
                    isDialogOpened={isdeleteDialogOpen}
                    handleCloseDialog={(isDeletedConfirmed) => closeDeleteDialog(isDeletedConfirmed)}
                    entity="User"
                />}
            </div>


            {errorMessage && (<div
                style={{
                    fontSize: 'large', color: 'red', marginTop: '1%',
                }}
                align="center"
            >
                {' '}
                <b> {errorMessage}</b>
            </div>)}
            {isAddDialogOpen && <AddUserDialog
                isDialogOpened={isAddDialogOpen}
                editData={editData.current}
                handleCloseDialog={(isAddConfirmed, userData, error) => closeAddUserDialog(isAddConfirmed, userData,error)}
                operation={operation}
                allUsers={allUsers}
            />
            }

            {showError &&
                <ErrorDialog
                    isDialogOpened={showError}
                    handleCloseDialog={() => closeErrorDialog()}
                    msg="Username already exists"
                />
            }
        </>
    );
}

export default connect(mapStateToProps)(UserManagement);
