import React, { useEffect, useState, useRef } from "react"
import {
    getAllHolidaySchedulesAPI,
    addHolidayScheduleAPI,
    deleteHolidayScheduleAPI,
} from "../../../../utilities/api/phoenix-api"
import {
    Flex,
    lightTheme,
    Provider,
    SearchField,
    ActionGroup,
    Item,
    Text,
    Content,
    IllustratedMessage,
    Heading,
    TableView,
    TableHeader,
    Column,
    TableBody,
    Cell,
    Row,
    ProgressCircle,
} from "@adobe/react-spectrum"
import { connect } from "react-redux"
import Delete from "@spectrum-icons/workflow/Delete"
import Draw from "@spectrum-icons/workflow/Draw"
import AddHolidaySchedule from "./AddHolidaySchedule"
import { notify, PheonixRoles } from "../../../../constants/constants"
import { renderEmptyState } from "../../../../utilities/components/CommonUseComponents"

function mapStateToProps(state) {
    return {
        user: state.login.userInfo,
        permissions:
            state.login.authorizedAccess != null
                ? state.login.authorizedAccess.permissions
                : [],
    }
}

const HolidaySchedule = (props) => {
    const [holidaySchedule, setHolidaySchedules] = useState([])
    const [filteredHoliday, setFilteredHoliday] = useState([])
    // const [errorMessage, setErrorMessage] = useState("")
    const [currentText, setCurrentText] = useState(null)
    const [selectedHoliday, setSelectedHoliday] = useState(new Set())
    const [operation, setOperation] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false)
    // const [isdeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    // const [showDuplicateError, setShowDuplicateError] = useState(false)
    const ldap = props.user.userid
    const isAdmin = props.permissions.includes(
        PheonixRoles.holidaySchedulesAdmin
    )

    const columns = [
        { name: "Language", key: "language" },
        { name: "Date", key: "date" },
        { name: "Name", key: "name" },
        { name: "Timezone", key: "tz" },
    ]

    const columnsAdmin = [
        { name: "Language", key: "language" },
        { name: "Date", key: "date" },
        { name: "Name", key: "name" },
        { name: "Timezone", key: "tz" },
        { name: "Actions", key: "actions" },
    ]

    // const currentContactSelected = useRef("")
    const editData = useRef(false)

    const getAllHolidaySchedules = () => {
        setIsLoading(true)
        getAllHolidaySchedulesAPI()
            .then((res) => {
                setHolidaySchedules(res)
                setFilteredHoliday(res)
                setIsLoading(false)
            })
            .catch(() => {
                console.log("Error in getting Holiday schedule")
            })
    }
    useEffect(() => {
        getAllHolidaySchedules()
    }, [])

    const deleteHolidaySchedule = (ldap, item, multi) => {
        if (multi === "multi") {
            setIsLoading(true)
            const urlencoded = new URLSearchParams()
            for (const key in item) {
                urlencoded.append(key, item[key])
            }
            deleteHolidayScheduleAPI(ldap, urlencoded)
                .then((err) => {
                    notify(err, "success")
                    getAllHolidaySchedules()
                    setSelectedHoliday(new Set())
                    setIsLoading(false)
                })
                .catch(() => {
                    notify("Error in deleting Holiday Schedule", "error")
                    setIsLoading(false)
                })
        } else {
            setIsLoading(true)
            const urlencoded = new URLSearchParams()
            urlencoded.append(`${[item.language]}`, item.date)
            deleteHolidayScheduleAPI(ldap, urlencoded)
                .then((res) => {
                    notify(res, "success")
                    getAllHolidaySchedules()
                    setIsLoading(false)
                })
                .catch(() => {
                    notify("Error in deleting Holiday Schedule", "error")
                    setIsLoading(false)
                })
        }
    }

    const performAction = (value, item) => {
        switch (value) {
            case "add":
                editData.current = null
                setIsAddDialogOpen(true)
                setOperation("Add")
                break
            case "edit":
                editData.current = holidaySchedule.find(
                    (val) =>
                        val.language === item.language && val.date === item.date
                )
                setIsAddDialogOpen(true)
                setOperation("Edit")
                break
            case "delete":
                deleteHolidaySchedule(ldap, item)
                // setIsDeleteDialogOpen(true)
                break
            case "bulkDelete":
                const selectedOption = Array.from(selectedHoliday)
                const mappedData = {}
                selectedOption.forEach((item) => {
                    const [language, date] = item.split(":")
                    if (!mappedData[language]) {
                        mappedData[language] = date
                    } else {
                        mappedData[language] += "," + date
                    }
                })
                deleteHolidaySchedule(ldap, mappedData, "multi")
                // setIsDeleteDialogOpen(true)
                break
        }
    }

    const getCell = (columnKey, item) => {
        switch (columnKey) {
            case "actions":
                return (
                    <Cell>
                        <ActionGroup
                            onAction={(value) => performAction(value, item)}
                        >
                            <Item key="edit">
                                <Draw />
                            </Item>
                            <Item key="delete">
                                <Delete />
                            </Item>
                        </ActionGroup>
                    </Cell>
                )
            case "name":
                return <Cell>{item[columnKey]}</Cell>
            case "language":
                return <Cell>{item[columnKey]}</Cell>
            case "tz":
                return <Cell>{item[columnKey]}</Cell>
            case "date":
                return <Cell>{item[columnKey]}</Cell>
            default:
                return <Cell>{item[columnKey]}</Cell>
        }
    }

    const isDuplicateHolidaySchedule = (language, date) => {
        return holidaySchedule.some(
            (ele) => ele.language === language && ele.date === date
        )
    }

    const closeAddTimeOutDialog = (
        isAddConfirmed,
        language,
        name,
        date,
        tz
    ) => {
        if (
            operation !== "Edit" &&
            isDuplicateHolidaySchedule(language, date)
        ) {
            notify(`${language} with ${date} already exist`, "error")
            return
        }
        setIsAddDialogOpen(false)
        if (isAddConfirmed) {
            setIsLoading(true)
            const urlencoded = new URLSearchParams()
            urlencoded.append("language", language)
            urlencoded.append("date", date)
            urlencoded.append("name", name)
            urlencoded.append("tz", tz)
            addHolidayScheduleAPI(ldap, urlencoded)
                .then((res) => {
                    getAllHolidaySchedules()
                    notify(res, "success")
                })
                .catch(() =>
                    notify("Error in Adding Holiday schedule", "error")
                )

            setIsLoading(false)
        }
    }

    const filterHolidayScheduleList = (value) => {
        if (value === "") {
            setFilteredHoliday(holidaySchedule)
        } else {
            const getFilteredData = filteredHoliday.filter((info) =>
                info.language.toLowerCase().includes(value.toLowerCase())
            )
            setFilteredHoliday(getFilteredData)
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (currentText !== null) {
                filterHolidayScheduleList(currentText)
            }
        }, 500)
        return () => clearTimeout(timer)
    }, [currentText])

    const filterHolidaySchedule = (value) => {
        setCurrentText(value)
    }

    // const closeErrorDialog = () => {
    //     setShowDuplicateError(false)
    // }

    return (
        <>
            {isLoading ? <div
                className={isLoading ? "display-block" : "display-none"}
                style={{
                    paddingTop: "10%",
                    paddingLeft: "45%",
                    position: "absolute",
                    zIndex: "10",
                }}
            >
               
                    <ProgressCircle
                        aria-label="Loading…"
                        isIndeterminate
                        size="L"
                    />
                
            </div>: null }

            <div align="center">
                <Heading variant="pageTitle">Holiday Schedule</Heading>
            </div>

            <div
                style={{
                    padding: "25px",
                    border: "0.8px solid",
                    borderRadius: "0.7em",
                    marginTop: "30px",
                    width: "65vw",
                    margin: "0 auto",
                }}
            >
                <Provider theme={lightTheme}>
                    <Flex
                        direction="row"
                        gap="size-100"
                        UNSAFE_className="searchMobile"
                    >
                        <SearchField
                            aria-label="search"
                            isQuiet
                            value={currentText}
                            onChange={filterHolidaySchedule}
                            onClear={() => setCurrentText("")}
                            width={"30%"}
                        />

                        {isAdmin && (
                            <ActionGroup
                                width={"80%"}
                                alignItems="end"
                                onAction={(value) => performAction(value, null)}
                                disabledKeys={
                                    selectedHoliday.size ||
                                    selectedHoliday == "all"
                                        ? []
                                        : ["bulkDelete"]
                                }
                            >
                                <Item key="add">+ Add</Item>
                                <Item key="bulkDelete">
                                    <Delete />
                                    <Text>Delete</Text>
                                </Item>
                            </ActionGroup>
                        )}
                    </Flex>

                    <Flex
                        width="100%"
                        height="100%"
                        direction="column"
                        gap="size-150"
                    >
                        <TableView
                            aria-label="holiday schedule"
                            selectionMode={"multiple"}
                            selectedKeys={selectedHoliday}
                            onSelectionChange={(item) => {
                                setSelectedHoliday(item)
                            }}
                            height={"45vh"}
                            renderEmptyState={renderEmptyState}
                            density="spacious"
                            overflowMode="wrap"
                            // disabledKeys={['1']}
                        >
                            <TableHeader
                                columns={isAdmin ? columnsAdmin : columns}
                            >
                                {(column) => (
                                    <Column
                                        key={column.key}
                                        align={
                                            column.key === "actions"
                                                ? "end"
                                                : "start"
                                        }
                                    >
                                        {column.name}
                                    </Column>
                                )}
                            </TableHeader>
                            <TableBody items={filteredHoliday}>
                                {(item) => (
                                    <Row key={`${item.language}:${item.date}`}>
                                        {(columnKey) =>
                                            getCell(columnKey, item)
                                        }
                                    </Row>
                                )}
                            </TableBody>
                        </TableView>
                    </Flex>
                </Provider>
                {isAddDialogOpen && (
                    <AddHolidaySchedule
                        isDialogOpened={isAddDialogOpen}
                        editData={editData.current}
                        operation={operation}
                        handleCloseDialog={(
                            isAddConfirmed,
                            language,
                            name,
                            date,
                            tz
                        ) =>
                            closeAddTimeOutDialog(
                                isAddConfirmed,
                                language,
                                name,
                                date,
                                tz
                            )
                        }
                    />
                )}
            </div>
        </>
    )
}

export default connect(mapStateToProps)(HolidaySchedule)
