import { CARE_PASSWORD, SERVER_URL, CARE_USERNAME } from '../../constants/constants';
import { get } from './methods';

/**
 * get All Agent Group List
 * @param {*} ldap 
 * @returns 
 */
export function getUserInfo(isLogin){
    return get({
        url: `${SERVER_URL}/care/v1/session/userInfo`,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
        isTextResponse: false,
        isLogin : isLogin
    });
}

export function logoutApi(){
    return get({
        url: `${SERVER_URL}/care/v1/session/invalidate`,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
        isTextResponse: false,
    });
}

export function alertTokenApi(){
    return get({
        url: `${SERVER_URL}/care/v1/session/token`,
        username: CARE_USERNAME,
        password: CARE_PASSWORD,
        isJsonResponse: true,
        isTextResponse: false,
    });
}

export function alertTokenInvalidateApi(ldap) {
    const queryParams = new URLSearchParams({ ldap }).toString();
    const url = `${SERVER_URL}/care/v1/session/token/invalidate?${queryParams}`;

    return fetch(url, {
        method: 'GET',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('AlertServiceToken')}`,
        },
    })
    .then((response) => response.json())
    .catch((err) => console.error(err));
}
