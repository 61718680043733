import React, { useEffect, useState, useRef } from "react"
import {
    getDnisMappinApi , addDnisMapingApi , deleteDnisApi
} from "../../../../utilities/api/phoenix-api"
import {
    Flex,
    lightTheme,
    Provider,
    SearchField,    
    ActionGroup,
    Item,
    Text,
    Content,
    IllustratedMessage,
    Heading,
    TableView,
    TableHeader,
    Column,
    TableBody,
    Cell,
    Row,
    ProgressCircle,
} from "@adobe/react-spectrum"
import Delete from "@spectrum-icons/workflow/Delete"
import Draw from "@spectrum-icons/workflow/Draw"
import { PheonixRoles, notify } from "../../../../constants/constants"
import { connect } from "react-redux"
import AddDnis from "./AddDnis"
import { renderEmptyState } from "../../../../utilities/components/CommonUseComponents"

function mapStateToProps(state) {
    return {
        user: state.login.userInfo,
        permissions:
            state.login.authorizedAccess != null
                ? state.login.authorizedAccess.permissions
                : [],
    }
}

const Dnis = (props) => {
    const [dnis, setDnis] = useState([])
    const [filteredDnis, setFilteredDnis] = useState([])
    // const [errorMessage, setErrorMessage] = useState("")
    const [currentText, setCurrentText] = useState(null)
    const [selectedDnis, setSelectedDnis] = useState(new Set())
    const [operation, setOperation] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false)
    // const [isdeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    const ldap = props?.user?.userid
    const isAdmin = props?.permissions?.includes(PheonixRoles.dnisAdmin)

    const columns = [
        { name: "DID/Phone Numbers", key: "did" },
        { name: "TFN", key: "tfn" },
        { name: "Region", key: "region" },
        { name: "Country", key: "country" },
        { name: "Operational Hours", key: "operationalHours" },
        { name: "Hub", key: "hub" },
        { name: "Language", key: "language" },
        { name: "BU", key: "bu" },
        { name: "TimeZone", key: "timezone" },
        { name: "Env", key: "env" },
        { name: "Language2", key: "language2" },
        { name: "CFN", key: "cfn" },
        { name: "Language3", key: "language3" },
        { name: "Holiday Schedule", key: "holidaySchedule" },
        { name: "Tier", key: "tier" },
    ]

    const columnsAdmin = [
        { name: "DID/Phone Numbers", key: "did" },
        { name: "TFN", key: "tfn" },
        { name: "Region", key: "region" },
        { name: "Country", key: "country" },
        { name: "Operational Hours", key: "operationalHours" },
        { name: "Hub", key: "hub" },
        { name: "Language", key: "language" },
        { name: "BU", key: "bu" },
        { name: "TimeZone", key: "timezone" },
        { name: "Env", key: "env" },
        { name: "Language2", key: "language2" },
        { name: "CFN", key: "cfn" },
        { name: "Language3", key: "language3" },
        { name: "Holiday Schedule", key: "holidaySchedule" },
        { name: "Tier", key: "tier" },
        { name: "Actions", key: "actions" },
    ]

    // const currentTimeoutSelected = useRef("")
    const editData = useRef(false)

    const getAllDnisMapping = () => {
        setIsLoading(true)
        getDnisMappinApi()
            .then((res) => {
                setDnis(res)
                setFilteredDnis(res);
                setIsLoading(false)
            })
            .catch(() => {
                console.log("Error in getting DNIS mapping")
            })
    }

    useEffect(() => {
        getAllDnisMapping()
    }, [])

    const deleteDnis = (ldap, name, multi) => {
        if (multi === "multi") {
            setIsLoading(true)
            const formatedData = name.join()
            const urlencoded = new URLSearchParams()
            urlencoded.append("did", formatedData)
            deleteDnisApi(ldap, urlencoded)
                .then((res) => {
                    notify(res, "success")
                    getAllDnisMapping()
                    setSelectedDnis(new Set())
                    setIsLoading(false)
                })
                .catch(() => {
                    notify("Error in Multi deleting Dnis")
                    setIsLoading(false)
                })
        } else {
            setIsLoading(true)
            const urlencoded = new URLSearchParams()
            urlencoded.append("did", name)
            deleteDnisApi(ldap, urlencoded)
                .then((res) => {
                    notify(res, "success")
                    getAllDnisMapping()
                    setIsLoading(false)
                })
                .catch(() => {
                    notify("Error in deleting Dnis")
                    setIsLoading(false)
                })
        }
    }

    const performAction = (value, item) => {
        switch (value) {
            case "add":
                editData.current = null
                setIsAddDialogOpen(true)
                setOperation("Add")
                break
            case "edit":
                 editData.current = dnis.find(
                     (val) => val.did == item
                 )
                 setIsAddDialogOpen(true)
                 setOperation("Edit")
                break
            case "delete":
                deleteDnis(ldap, item)
                // currentTimeoutSelected.current = item
                // setIsDeleteDialogOpen(true)
                break
            case "bulkDelete":
                const selectedOptions = Array.from(selectedDnis)
                deleteDnis(ldap, selectedOptions, "multi")
                // setIsDeleteDialogOpen(true)
                break
        }
    }

    const getCell = (columnKey, item) => {
        switch (columnKey) {
            case "actions":
                return (
                    <Cell>
                        <ActionGroup
                            onAction={(value) =>
                                performAction(value, item.did)
                            }                        >
                            <Item key="edit" >
                                <Draw />
                            </Item>
                            <Item key="delete">
                                <Delete />
                            </Item>
                        </ActionGroup>
                    </Cell>
                )
            case "language":
            case "language2":
            case "language3":
                return <Cell>{item[columnKey] === "" ?"-":item[columnKey] }</Cell>
            case "emergency":
                return <Cell>{item[columnKey]}</Cell>
            default:
                return <Cell>{item[columnKey]}</Cell>
        }
    }

    // const isDuplicateTimeoutName = (name) => {
    //     return timeOut.some((ele) => ele.timeoutName === name);
    // };

    const closeAddTimeOutDialog = (isAddConfirmed, body) => {
        // if(isDuplicateTimeoutName(timeoutName)){
        //     notify(`${timeoutName} already exist`,"error");
        //     return;
        // }
        setIsAddDialogOpen(false)
        if (isAddConfirmed) {
            setIsLoading(true)
            const urlencoded = new URLSearchParams()
            urlencoded.append("did", body.did)
            urlencoded.append("tfn", body.tfn)
            urlencoded.append("holidaySchedule", body.holidaySchedule)
            urlencoded.append("tier", body.tier)
            urlencoded.append("language", body.language)
            urlencoded.append("language2", body.language2)
            urlencoded.append("language3", body.language3)
            urlencoded.append("cfn", body.cfn)
            urlencoded.append("env", body.env)
            urlencoded.append("timezone", body.timezone)
            urlencoded.append("bu", body.bu)
            urlencoded.append("hub", body.hub)
            urlencoded.append("operationalHours", body.operationalHours)
            urlencoded.append("country", body.country)
            urlencoded.append("region", body.region)
            addDnisMapingApi(ldap, urlencoded)
                .then((res) => {
                    getAllDnisMapping()
                    notify(res, "success")
                })
                .catch(() => notify("Error in Adding Dnis", "error"))

            setIsLoading(false)
        }
    }

    
    const filterDnisList = (value) => {
        if(value === ""){
            setFilteredDnis(dnis)
        }else {
            const getFilteredData = filteredDnis.filter((val) =>
                val.did.toString().includes(value.toString())
            )
            setFilteredDnis(getFilteredData)
        } 
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if(currentText !== null){
                filterDnisList(currentText)
            }
        },500)
        return () => clearTimeout(timer)
    }, [currentText])

    const filterDnis = (value) => {
        setCurrentText(value)
    }

    return (
        <>
           {isLoading ? <div
                className={isLoading ? "display-block" : "display-none"}
                style={{
                    paddingTop: "10%",
                    paddingLeft: "45%",
                    position: "absolute",
                    zIndex:"10"
                }}
            >
               
                    <ProgressCircle
                        aria-label="Loading…"
                        isIndeterminate
                        size="L"
                    />
       
            </div> : null}

            <div align="center">
                <Heading variant="pageTitle">Phone Numbers</Heading>
            </div>

            <div
                style={{
                    padding: "25px",
                    border: "0.8px solid",
                    borderRadius: "0.7em",
                    marginTop: "30px",
                    width: "90vw",
                    margin: "0 auto",
                }}
            >
                <Provider theme={lightTheme}>
                    <Flex
                        direction="row"
                        gap="size-100"
                        UNSAFE_className="searchMobile"
                    >
                        <SearchField
                        aria-label="search"
                            value={currentText}
                            onChange={filterDnis}
                            isQuiet
                            onClear={() => setCurrentText("")}
                            width={"30%"}
                        />

                        {
                           isAdmin &&
                            <ActionGroup
                                width={"80%"}
                                alignItems="end"
                                onAction={(value) => performAction(value, null)}
                                disabledKeys={
                                    selectedDnis.size ||
                                    selectedDnis == "all"
                                        ? []
                                        : ["bulkDelete"]
                                }
                            >
                                <Item key="add">+ Add</Item>
                                <Item key="bulkDelete">
                                    <Delete />
                                    <Text>Delete</Text>
                                </Item>
                            </ActionGroup>
                        }
                    </Flex>

                    <Flex
                        width="100%"
                        height="100%"
                        direction="column"
                        gap="size-150"
                    >
                        <TableView
                            aria-label="Timeout"
                            selectionMode={"multiple"}
                            selectedKeys={selectedDnis}
                            onSelectionChange={setSelectedDnis}
                            height={"45vh"}
                            renderEmptyState={renderEmptyState}
                            density="spacious"
                            overflowMode="wrap"
                        >
                            <TableHeader columns={ (isAdmin)?columnsAdmin:columns}>
                                {(column) => (
                                    <Column
                                        key={column.key}
                                        minWidth={column.key==="operationalHours" ? "16%" : column.key==="did" ? "14%" : "10%"}
                                        align={column.key === "actions" ? "end" : "start"}
                                        // minwidth={column.key === "actions" ? "30%" : "auto"}
                                    >
                                        {column.name}
                                    </Column>
                                )}
                            </TableHeader>
                            <TableBody items={filteredDnis}>
                                {(item) => (
                                    <Row key={item.did}>
                                        {(columnKey) =>
                                            getCell(columnKey, item)
                                        }
                                    </Row>
                                )}
                            </TableBody>
                        </TableView>
                    </Flex>
                </Provider>

                {isAddDialogOpen && (
                    <AddDnis
                        isDialogOpened={isAddDialogOpen}
                        editData={editData.current}
                        operation={operation}
                        handleCloseDialog={(isAddConfirmed,body) =>
                            closeAddTimeOutDialog(isAddConfirmed, body)
                        }
                        
                    />
                )}
            </div>
        </>
    )
}

export default connect(mapStateToProps)(Dnis);