import React, { useEffect, useState, useRef } from "react"
import {
    getEmergencyApi,
    addEmergencyApi,
    deleteEmergencyApi,
} from "../../../../utilities/api/phoenix-api"
import {
    Flex,
    lightTheme,
    Provider,
    SearchField,
    ActionGroup,
    Item,
    Text,
    Content,
    IllustratedMessage,
    Heading,
    TableView,
    TableHeader,
    Column,
    TableBody,
    Cell,
    Row,
    ProgressCircle,
} from "@adobe/react-spectrum"
import Delete from "@spectrum-icons/workflow/Delete"
import Draw from "@spectrum-icons/workflow/Draw"
import { PheonixRoles, notify } from "../../../../constants/constants"
import { connect } from "react-redux"
import AddDxocEmergency from "./AddDxocEmergency"
import { renderEmptyState } from "../../../../utilities/components/CommonUseComponents"

function mapStateToProps(state) {
    return {
        user: state.login.userInfo,
        permissions:
            state.login.authorizedAccess != null
                ? state.login.authorizedAccess.permissions
                : [],
    }
}

const DxocEmergency = (props) => {
    const [emergency, setEmergency] = useState([])
    const [filteredEmergency, setFilteredEmergency] = useState([])
    // const [errorMessage, setErrorMessage] = useState("")
    const [currentText, setCurrentText] = useState(null)
    const [selectedEmergency, setSelectedEmergency] = useState(new Set())
    const [operation, setOperation] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false)
    // const [isdeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    const ldap = props?.user?.userid
    const isAdmin = props?.permissions?.includes(
        PheonixRoles.emergencyAdmin
    )

    const columns = [
        { name: "Language", key: "language" },
        { name: "Emergency", key: "emergency" },
    ]

    const columnsAdmin = [
        { name: "Language", key: "language" },
        { name: "Emergency", key: "emergency" },
        { name: "Actions", key: "actions" },
    ]

    // const currentTimeoutSelected = useRef("")
    const editData = useRef(false)

    const getAllEmergency = () => {
        setIsLoading(true)
        getEmergencyApi()
            .then((res) => {
                setEmergency(res)
                setFilteredEmergency(res)
                setIsLoading(false)
            })
            .catch(() => {
                console.log("Error in getting Emergency")
            })
    }

    useEffect(() => {
        getAllEmergency()
    }, [])

    const deleteEmergency = (ldap, name, multi) => {
        if (multi === "multi") {
            setIsLoading(true)
            const formatedData = name.join()
            const urlencoded = new URLSearchParams()
            urlencoded.append("languages", formatedData)
            deleteEmergencyApi(ldap, urlencoded)
                .then((res) => {
                    notify(res, "success")
                    getAllEmergency()
                    setSelectedEmergency(new Set())
                    setIsLoading(false)
                })
                .catch(() => {
                    notify("Error in Multi deleting timeout")
                    setIsLoading(false)
                })
        } else {
            setIsLoading(true)
            const urlencoded = new URLSearchParams()
            urlencoded.append("languages", name)
            deleteEmergencyApi(ldap, urlencoded)
                .then((res) => {
                    notify(res, "success")
                    getAllEmergency()
                    setIsLoading(false)
                })
                .catch(() => {
                    notify("Error in deleting Emergency")
                    setIsLoading(false)
                })
        }
    }

    const performAction = (value, item) => {
        //console.log({ value, item })
        switch (value) {
            case "add":
                editData.current = null
                setIsAddDialogOpen(true)
                setOperation("Add")
                break
            case "edit":
                editData.current = emergency.find((val) => val.language == item)
                setIsAddDialogOpen(true)
                setOperation("Edit")
                break
            case "delete":
                deleteEmergency(ldap, item)
                // currentTimeoutSelected.current = item
                // setIsDeleteDialogOpen(true)
                break
            case "bulkDelete":
                const selectedOptions = Array.from(selectedEmergency)
                deleteEmergency(ldap, selectedOptions, "multi")
                // setIsDeleteDialogOpen(true)
                break
        }
    }

    const getCell = (columnKey, item) => {
        switch (columnKey) {
            case "actions":
                return (
                    <Cell>
                        <ActionGroup
                            onAction={(value) =>
                                performAction(value, item.language)
                            }
                        >
                            <Item key="edit">
                                <Draw />
                            </Item>
                            <Item key="delete">
                                <Delete />
                            </Item>
                        </ActionGroup>
                    </Cell>
                )
            case "language":
                return <Cell>{item[columnKey]}</Cell>
            case "emergency":
                return <Cell>{item[columnKey]}</Cell>
            default:
                return <Cell>{item[columnKey]}</Cell>
        }
    }

    // const isDuplicateTimeoutName = (name) => {
    //     return timeOut.some((ele) => ele.timeoutName === name);
    // };

    const closeAddTimeOutDialog = (isAddConfirmed, language, emergency) => {
        // if(isDuplicateTimeoutName(timeoutName)){
        //     notify(`${timeoutName} already exist`,"error");
        //     return;
        // }
        setIsAddDialogOpen(false)
        if (isAddConfirmed) {
            setIsLoading(true)
            const urlencoded = new URLSearchParams()
            urlencoded.append("language", language)
            urlencoded.append("emergency", emergency)
            addEmergencyApi(ldap, urlencoded)
                .then((res) => {
                    getAllEmergency()
                    notify(res, "success")
                })
                .catch(() => notify("Error in Adding timeout", "error"))

            setIsLoading(false)
        }
    }

    const filterEmergencyList = (value) => {
        if (value === "") {
            setFilteredEmergency(emergency)
        } else {
            const getFilteredData = filteredEmergency.filter((val) =>
                val.language.toLowerCase().includes(value.toLowerCase())
            )
            setFilteredEmergency(getFilteredData)
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (currentText !== null) {
                filterEmergencyList(currentText)
            }
        }, 500)
        return () => clearTimeout(timer)
    }, [currentText])

    const searchEmergency = (value) => {
        setCurrentText(value)
    }

    return (
        <>
            {isLoading ? (
                <div
                    // className={isLoading ? "display-block" : "display-none"}
                    style={{
                        paddingTop: "10%",
                        paddingLeft: "45%",
                        position: "absolute",
                        zIndex: "10",
                    }}
                >
                    <ProgressCircle
                        aria-label="Loading…"
                        isIndeterminate
                        size="L"
                    />
                </div>
            ) : null}

            <div align="center">
                <Heading variant="pageTitle">EMERGENCY</Heading>
            </div>

            <div
                style={{
                    padding: "25px",
                    border: "0.8px solid",
                    borderRadius: "0.7em",
                    marginTop: "30px",
                    width: "56vw",
                    margin: "0 auto",
                }}
            >
                <Provider theme={lightTheme}>
                    <Flex
                        direction="row"
                        gap="size-100"
                        UNSAFE_className="searchMobile"
                    >
                        <SearchField
                            aria-label="search"
                            value={currentText}
                            onChange={searchEmergency}
                            isQuiet
                            onClear={() => setCurrentText("")}
                            width={"30%"}
                        />

                        {
                               isAdmin &&
                            <ActionGroup
                                width={"80%"}
                                alignItems="end"
                                onAction={(value) => performAction(value, null)}
                                disabledKeys={
                                    selectedEmergency.size ||
                                    selectedEmergency == "all"
                                        ? []
                                        : ["bulkDelete"]
                                }
                            >
                                <Item key="add">+ Add</Item>
                                <Item key="bulkDelete">
                                    <Delete />
                                    <Text>Delete</Text>
                                </Item>
                            </ActionGroup>
                        }
                    </Flex>

                    <Flex
                        width="100%"
                        height="100%"
                        direction="column"
                        gap="size-150"
                    >
                        <TableView
                            aria-label="Timeout"
                            selectionMode={"multiple"}
                            selectedKeys={selectedEmergency}
                            onSelectionChange={setSelectedEmergency}
                            height={"45vh"}
                            renderEmptyState={renderEmptyState}
                            density="spacious"
                            overflowMode="wrap"
                        >
                            <TableHeader
                                columns={isAdmin ? columnsAdmin : columns}
                            >
                                {(column) => (
                                    <Column
                                        key={column.key}
                                        align={
                                            column.key === "actions"
                                                ? "end"
                                                : "start"
                                        }
                                        // width={column.key === "timeoutName" ? "50%" : column.key === "actions" ? "23%" : "auto"}
                                    >
                                        {column.name}
                                    </Column>
                                )}
                            </TableHeader>
                            <TableBody items={filteredEmergency}>
                                {(item) => (
                                    <Row key={item.language}>
                                        {(columnKey) =>
                                            getCell(columnKey, item)
                                        }
                                    </Row>
                                )}
                            </TableBody>
                        </TableView>
                    </Flex>
                </Provider>

                {isAddDialogOpen && (
                    <AddDxocEmergency
                        isDialogOpened={isAddDialogOpen}
                        editData={editData.current}
                        operation={operation}
                        handleCloseDialog={(
                            isAddConfirmed,
                            language,
                            emergency
                        ) =>
                            closeAddTimeOutDialog(
                                isAddConfirmed,
                                language,
                                emergency
                            )
                        }
                    />
                )}
            </div>
        </>
    )
}

export default connect(mapStateToProps)(DxocEmergency)
