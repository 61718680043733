import React, { useEffect, useState } from "react"
import { Button, Well, Flex, View, Link } from "@adobe/react-spectrum"
import { Redirect } from "react-router-dom"
import { SERVER_URL, ALERT_SERVER_URL, notify } from "../constants/constants"
import { getUserInfo, alertTokenApi } from "../utilities/api/userInfo"
import { AmazonConnectFilled, Adobe } from "../utilities/components/Icons"
import { ButtonGroup } from "react-bootstrap"

const Login = () => {
    const [showLogin, setShowLogin] = useState(false)

    // useEffect(() => {
    //     getUserInfo(true)
    //         .then(() => {
    //             setShowLogin(true)
    //         })
    //         .catch((err) => {
    //             setShowLogin(false)
    //             console.log("Error in login ", err)
    //         })
    // }, [])

    const srsTokenMethod = async () => {
        try {
            const result = await alertTokenApi()
            localStorage.setItem("AlertServiceToken", result?.response)
        } catch (error) {
            console.error("Error fetching token:", error)
        }
    }

    const loginCarePortal = () => {
        try {
            srsTokenMethod()
            location.href = `${SERVER_URL}/care/v1/session/login`
        } catch (error) {
            console.error("Error logging in:", error)
        }
    }

    const loginAmazonConnect = () => {
        const { amazonConnectURL } = ALERT_SERVER_URL
        window.open(amazonConnectURL, "_blank")
    }

    return (
        <>
            <div style={{ float: "right", margin: "10px 10px 0px 0px" }}>
                <div>Useful Links</div>
                <a
                    href={
                        SERVER_URL === "https://dxoc-careserver-qe.adobe.io"
                            ? "https://chrome.google.com/webstore/detail/cjcabehflmjmkbenakenklgpppkinlad"
                            : SERVER_URL ===
                              "https://dxoc-careserver-stage.adobe.io"
                            ? "https://chromewebstore.google.com/detail/aicv3-stage/jehanfefllpkfacobldbpcmgidfihnjk"
                            : SERVER_URL === "https://dxoc-careserver.adobe.io"
                            ? "https://chromewebstore.google.com/detail/aicv3/njocfcegnjopnfajnnfaelebpcejagji"
                            : "https://chrome.google.com/webstore/detail/cjcabehflmjmkbenakenklgpppkinlad"
                    }
                    target="_blank"
                >
                    AIC v3 link
                </a>
            </div>
            <div
                className="login-flex"
                align="center"
                style={{ justifyContent: "center" }}
            >
                <>
                    {!showLogin ? (
                        <>
                            <Flex
                                alignItems={"center"}
                                direction={"row"}
                                gap={"size-200"}
                                rowGap={"size-100"}
                            >
                                <View>
                                    <ButtonGroup>
                                        <Button
                                            UNSAFE_className={"BoxyButton"}
                                            variant="primary"
                                            UNSAFE_style={{ padding: "30px" }}
                                            onClick={loginCarePortal}
                                        >
                                            <Adobe />
                                            <Button
                                                variant="cta"
                                                UNSAFE_className={"login-btn"}
                                                width={"129%"}
                                                onClick={loginCarePortal}
                                            >
                                                Care Portal Login{" "}
                                            </Button>
                                        </Button>
                                        <Button
                                            UNSAFE_className={"BoxyButton"}
                                            variant="primary"
                                            UNSAFE_style={{ padding: "30px" }}
                                            onClick={loginAmazonConnect}
                                        >
                                            <AmazonConnectFilled />
                                            <Button
                                                variant="cta"
                                                UNSAFE_className={"login-btn"}
                                                width={"129%"}
                                                onClick={loginAmazonConnect}
                                            >
                                                {" "}
                                                Amazon Connect UI Login{" "}
                                            </Button>
                                        </Button>
                                    </ButtonGroup>
                                </View>
                            </Flex>
                        </>
                    ) : (
                        <Redirect to="/menu" />
                    )}
                </>
            </div>
        </>
    )
}

export default Login
