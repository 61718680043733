import React from "react"
import {
    Accordion,
    AccordionItemVoiceCompliance,
    SecondLevelAccordian,
} from "../../../utilities/components/Accordion/Accordion"

const VoiceList = ({
    data,
    ActionProcess,
    deleteApi,
    selectedAcc,
    userid,
    handleFirstLeveEdit,
    getVoiceComplianceList,
    isAdmin,
    handleSubmit
}) => {
    return (
        <Accordion>
            {data && data?.map((item, outerIndex) =>
             (
                <SecondLevelAccordian
                    key={`${outerIndex}`}
                    allData={data}
                    item={item}
                    deleteApi={deleteApi}
                    ldap={userid}
                    handleFirstLeveEdit={handleFirstLeveEdit}
                    ActionProcess={ActionProcess}
                    getVoiceComplianceList={getVoiceComplianceList}
                    setSelectedAcc={selectedAcc}
                    isAdmin={isAdmin}
                    handleSubmit={handleSubmit}
                />
            ))}
        </Accordion>
    )
}

export default VoiceList
