import React, { useState, useEffect } from 'react';
import {
    Provider, lightTheme, Dialog, Button, ActionButton, DialogTrigger, Divider,
    Heading, Content, ButtonGroup, Form, TextField,TextArea
} from '@adobe/react-spectrum';

const AddServiceTest = (props) => {
    const [service, setservice] = useState('');
    const [endpoint, setendpoint] = useState('');
    const [expectedResponse, setexpectedResponse] = useState('');
    const [isserviceValid, setIsserviceValid] = useState(true);
    const [isendpointValid, setIsendpointValid] = useState(true);
    const [errorMessage, setErrorMessage] = useState('service is invalid');

    useEffect(() => {
        if (props.editData) {
            setservice(props.editData.service);
            setendpoint(props.editData.endpoint);
            setexpectedResponse(JSON.stringify(props.editData.expectedResponse));
        }
    }, []);

    const checkEndPointServiceValidation = (serviceData,endpointData) => {
        const matchFound = props.existingData.filter((data) => (data.service == serviceData && data.endpoint == endpointData));
        if(matchFound.length){
            setIsserviceValid(false);
            setErrorMessage('record already exists');
        }
    };

    useEffect(()=>{
        if(!props.editData){
            checkEndPointServiceValidation(service,endpoint);
        }
    },[service,endpoint]);

    const handlePhoneChange = ((value) => {
        setservice(value);
        setIsserviceValid(value && (/^[a-zA-Z0-9_-]{3,50}$/.test(value.trim())) ? true: false);
        if(value && !(/^[a-zA-Z0-9_-]{3,50}$/.test(value.trim()))){
            setErrorMessage('service is invalid');
        }
        // checkEndPointServiceValidation();
        console.log(isserviceValid);
    });

    const handelEndpointChanges = (value) =>{
        setendpoint(value.trim());
        setIsendpointValid(value && (/^[a-zA-Z0-9_-]{3,50}$/.test(value.trim())) ? true: false);
        // checkEndPointServiceValidation();
    };

    const onCloseHandler = () => {
        props.handleCloseDialog(false);
    };
    const onSaveHandler = () => {
        const contactData = {
            service: service,
            endpoint: endpoint,
            expectedResponse:expectedResponse
        };
        props.handleCloseDialog(true, contactData);
    };

    const checkJson = (value) => {
        try {
            const parsedValue = JSON.parse(value);
            if(!Object.keys(parsedValue).length){
                console.log({parsedValue});
                return false;
            }
        } catch (e) {
            return false;
        }
        return true;
    };

    return (

        <Provider theme={lightTheme}>
            <div>
                <DialogTrigger
                    type="modal"
                    mobileType="modal"
                    isOpen={props.isDialogOpened}
                    defaultOpen={false}>
                    <ActionButton isHidden isQuiet></ActionButton>
                    <Dialog size="M">
                        <Heading>{props.operation} Service Test Data</Heading>
                        <Divider />
                        <Content>
                            <Form isRequired>
                                {!service && <TextField label="Service" autoFocus
                                    value={service}
                                    errorMessage={isserviceValid ? '' : errorMessage}
                                    onChange={handlePhoneChange}
                                    isRequired
                                    isQuiet />}
                                {service && <TextField label="Service" autoFocus
                                    value={service}
                                    validationState={isserviceValid ? 'valid' : 'invalid'}
                                    errorMessage={isserviceValid ? '' : errorMessage}
                                    onChange={handlePhoneChange}
                                    isRequired
                                    isQuiet />}
                                <TextField label="Endpoint"
                                    value={endpoint}
                                    validationState={isendpointValid ? 'valid' : 'invalid'}
                                    errorMessage={isendpointValid ? '' : "Endpoint is required"}
                                    onChange={handelEndpointChanges}
                                    isRequired
                                    isQuiet />
                                <TextArea label="Expected Response"
                                    value={expectedResponse}
                                    validationState={(!expectedResponse.length ||(expectedResponse.length>=1 && checkJson(expectedResponse))) ? 'valid' : 'invalid'}
                                    errorMessage={checkJson(expectedResponse) ? '' : "Value isn't a valid json"}
                                    onChange={setexpectedResponse}
                                    isRequired
                                    />
                            </Form>
                        </Content>
                        <ButtonGroup>
                            <Button variant="secondary" onPress={onCloseHandler}>Cancel</Button>
                            <Button variant="primary" onPress={onSaveHandler} isDisabled={ !service || !isserviceValid || !endpoint || !expectedResponse || !checkJson(expectedResponse)}>Save</Button>
                        </ButtonGroup>
                    </Dialog>
                </DialogTrigger>
            </div>
        </Provider>
    );

};

export default AddServiceTest;