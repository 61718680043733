import React from "react"
import { Item, TabList, TabPanels, Tabs, Well } from "@adobe/react-spectrum"
import { connect } from "react-redux"
import AniByPass from "./AniByPass/AniByPass"
import HolidaySchedule from "./HolidaySchedule/HolidaySchedule"
import Timeout from "./Timeout/Timeout"
import DxocEmergency from "./Emergency/DxocEmergency"
import DxPrompts from "./DxPrompts/DxPrompts"
import Dnis from "./Dnis/Dnis"
import DidMagento from "./DidMagento/DidMagento"
import SpamCallersList from "./spamContactsDashboard/SpamCallersList"
import SrsMockData from "./SrsMockData/SrsMockData"
import GroupCallDuration from "./GroupCallDuration/GroupCallDuration"

function mapStateToProps(state) {
    //console.log(state.login)
    return {
        login: state.login,
        permissions:
            null != state.login.authorizedAccess
                ? state.login.authorizedAccess.permissions
                : [],
    }
}

const CommonConfig = (props) => {

    //console.log("COMMOM CONFIG" , props);
    return (
        <Well
            style={{
                minHeight: "75vh",
                marginRight: "1%",
                marginLeft: "1%",
            }}
        >
            {props?.permissions.length &&
            <Tabs aria-label="Audit Reports">
                <TabList>
                    {(props.permissions.includes("Timeout") ||
                        props.permissions.includes("Timeout Admin")) && (
                        <Item key="timeout">TIMEOUT</Item>
                    )}
                    {(props.permissions.includes("Ani Bypass") ||
                        props.permissions.includes("Ani Bypass Admin")) && (
                        <Item key="aniBypass">ANI BYPASS</Item>
                    )}
                    {(props.permissions.includes("Holiday Schedules") ||
                        props.permissions.includes(
                            "Holiday Schedules Admin"
                        )) && (
                        <Item key="holidaySchedules">HOLIDAY SCHEDULES</Item>
                    )}
                    {(props.permissions.includes("Emergency") ||
                        props.permissions.includes("Emergency Admin")) && (
                        <Item key="emergency">EMERGENCY</Item>
                    )}
                    {(props.permissions.includes("Dx Prompts") ||
                        props.permissions.includes("Dx Prompts Admin")) && (
                        <Item key="dxprompts">DX PROMPTS</Item>
                    )}
                    {(props.permissions.includes("Dnis") ||
                        props.permissions.includes(
                            "Dnis Admin"
                        )) && <Item key="dnis">PHONE NUMBERS</Item>}
                    {(props.permissions.includes("Did Magento") ||
                        props.permissions.includes(
                            "Did Magento Admin"
                        )) && <Item key="didmagento">DID MAGENTO</Item>}
                    {(props.permissions.includes("Srs Mock Data") ||
                        props.permissions.includes(
                            "Srs Mock Data Admin"
                        )) && <Item key="mockdata">SRS MOCK DATA</Item>}
                    {(props.permissions.includes("Spam Callers") ||
                        props.permissions.includes("Spam Callers Admin")) && (
                        <Item key="spamCallers">SPAM CALLERS</Item>
                    )}
                     {(props.permissions.includes("Operational Hours")) && (
                            <Item key="timezoneCallDuration">OPERATIONAL HOURS</Item>
                    )}
                </TabList>
                <TabPanels>
                    <Item key="aniBypass">
                        <AniByPass />
                    </Item>
                    <Item key="holidaySchedules">
                        <HolidaySchedule />
                    </Item>
                    <Item key="timeout">
                        <Timeout />
                    </Item>
                    <Item key="emergency">
                        <DxocEmergency />
                    </Item>
                    <Item key="dxprompts">
                        <DxPrompts />
                    </Item>
                    <Item key="dnis">
                        <Dnis />
                    </Item>
                    <Item key="didmagento">
                        <DidMagento />
                    </Item>
                    <Item key="mockdata">
                        <SrsMockData />
                    </Item>
                    <Item key="spamCallers">
                        <SpamCallersList />
                    </Item>
                    <Item key={"timezoneCallDuration"}>
                        <GroupCallDuration />
                    </Item>
                </TabPanels>
            </Tabs>}
        </Well>
    )
}

export default connect(mapStateToProps)(CommonConfig)
