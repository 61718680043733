import React, { useEffect, useState, useRef } from 'react';
import Delete from '@spectrum-icons/workflow/Delete';
import Draw from '@spectrum-icons/workflow/Draw';
import '../index.css';
import {
  Flex, lightTheme, Provider, SearchField, ActionGroup, Item, Text,
  Content, IllustratedMessage, Heading, TableView, TableHeader, Column,
  TableBody, Cell, Row, ProgressCircle
} from '@adobe/react-spectrum';
import DeleteConfirmation from './deleteDialog';
import AddTimezone from './AddTimezoneDialog';
import { connect } from 'react-redux';
import { getTimezones, saveTimezones } from '../utilities/api/admin-api';

function mapStateToProps(state) {
  return {
      user: state.login.userInfo,
  };
}

const TimezoneManagement = (props) => {
  const [allTimezones, setAllTimezones] = useState([]);
  const [filteredTimezones, setFilteredTimezones] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [currentText, setCurrentText] = useState('');
  const [selectedTimezones, setSelectedTimezones] = useState(new Set());
  const [operation, setOperation] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isdeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const columns = [
    { name: 'Abbreviation', key: 'abbr' },
    { name: 'Name', key: 'name' },
    { name: 'Actions', key: 'actions' }
  ];

  const currentTimezoneSelected = useRef('');
  const editData = useRef(false);

  function getAllTimezones() {
    setIsLoading(true);
    getTimezones("timezones")
      .then((timezone) => {
        setIsLoading(false);
        setAllTimezones(timezone);
        setFilteredTimezones(timezone)
      }).catch((error) => {
        setIsLoading(false);
        console.log(error)
        setErrorMessage('Error occured!');
      })
  }

  useEffect(() => {
    getAllTimezones();
  }, []);

  const filterFunction = (value) => {
    const filteredTimezone = allTimezones.filter(timezone =>
      timezone.abbr?.includes(value.toLowerCase()) ||
      timezone.name?.includes(value.toLowerCase())
    )
    setFilteredTimezones(filteredTimezone)
  }

  useEffect(()=>{
    const timeout = setTimeout(() => {
      if(isLoading)
        filterFunction(currentText);
    }, 500);
    return ()=> clearTimeout(timeout);
},[currentText]);



  const filterTimezones = (value) => {
    setCurrentText(value);
  }


  const performAction = (value, abbr) => {
    switch (value) {
      case 'add':
        editData.current = null;
        setIsAddDialogOpen(true);
        setOperation("Add")
        break;
      case 'edit':
        editData.current = allTimezones.find((t) => t.abbr == abbr);
        setIsAddDialogOpen(true);
        setOperation("Edit")
        break;
      case 'delete':
        currentTimezoneSelected.current = abbr;
        setIsDeleteDialogOpen(true);
        break;
      case 'bulkDelete':
        setIsDeleteDialogOpen(true);
        break;
    }
  }

  const updateTimezonesInDB = () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("data", JSON.stringify(allTimezones));
    saveTimezones(urlencoded, "timezones",props.user.userid)
    .then((timezone) => {
      setIsLoading(false);
      setAllTimezones(timezone);
      setFilteredTimezones(timezone)
    }).catch((error) => {
      setIsLoading(false);
      console.log(error)
      setErrorMessage('Error occured!');
    })
  }

  function deleteTimezones(timezonesToDelete) {
    setIsLoading(true);

    timezonesToDelete.forEach((timezoneAbbr) => {
      const index = allTimezones.findIndex(t => t.abbr == timezoneAbbr);
      allTimezones.splice(index, 1);
    })
    setSelectedTimezones(new Set());
    setFilteredTimezones(allTimezones)
    updateTimezonesInDB();
    setIsLoading(false);

  }

  const closeDeleteDialog = (isDeletedConfirmed) => {
    setIsDeleteDialogOpen(false);
    if (isDeletedConfirmed) {
      let timezonesToDelete = [currentTimezoneSelected.current];
      if (selectedTimezones == 'all') {
        timezonesToDelete = allTimezones.map(t => t.abbr)
      } else if (!currentTimezoneSelected.current && selectedTimezones.size > 0) {
        timezonesToDelete = [...selectedTimezones];
      }
      deleteTimezones(timezonesToDelete);
    }
  }

  const closeAddTimezoneDialog = (isAddConfirmed, updatedTimezone, oldTimezone) => {
    setIsAddDialogOpen(false);
    if (isAddConfirmed) {
      setIsLoading(true);
      var index = -1

      if (operation == 'Add') {
        index = allTimezones.findIndex(t => t.abbr == updatedTimezone.abbr);

        if (index > -1) {
          setErrorMessage('Timezone already exists');
          window.scrollTo(0, document.body.scrollHeight);
          setTimeout(() => {
            setErrorMessage("");
          }, 4000);
        }
        else {
          allTimezones.push(updatedTimezone);
          updateTimezonesInDB();
        }

      }
      if (operation == "Edit") {
        index = allTimezones.findIndex(t => t.abbr == oldTimezone.abbr);
        allTimezones.splice(index, 1, updatedTimezone);
        updateTimezonesInDB();
      }

      setIsLoading(false);
    }
  }
  const getCell = (columnKey, item) => {
    switch (columnKey) {
      case 'actions':
        return (
          <Cell>
            <ActionGroup onAction={(value) => performAction(value, item.abbr)}>
              <Item key="edit">
                <Draw />
              </Item>
              <Item key="delete">
                <Delete />
              </Item>
            </ActionGroup>
          </Cell>
        );
      default:
        return (
          <Cell>{item[columnKey]}</Cell>
        );
    }
  }

  function renderEmptyState() {
    return (
      <IllustratedMessage>
        <Heading>No results</Heading>
        <Content>No results found</Content>
      </IllustratedMessage>
    );
  }


  return (
    <>

      {isLoading ? <div className={isLoading ? 'display-block' : 'display-none'}
        style={{ paddingTop: '10%', paddingLeft: '45%', position: 'absolute', zIndex: '10' }}
      >
      <ProgressCircle aria-label="Loading…" isIndeterminate size='L' />
      </div> : null}

      <div align="center" style={{ paddingBottom: '20px' }}>
        <Heading variant="pageTitle">Timezones</Heading>
      </div>

      <div
        style={{
          padding: '25px',
          border: '0.8px solid',
          borderRadius: '0.7em',
          marginTop: '30px',
          width: '50%',
          margin: 'auto'
        }}
      >

        <Provider theme={lightTheme}>
          <Flex direction="row" gap="size-100" UNSAFE_className="searchMobile">
            <SearchField isQuiet
              onClear={() => setCurrentText('')}
              onChange={filterTimezones}
              aria-label="Search"
              width={'40%'}
            />

            <ActionGroup width={'80%'} alignItems="end" onAction={(value) => performAction(value, null)} disabledKeys={(selectedTimezones.size || selectedTimezones == 'all') ? [] : ['bulkDelete']}>
              <Item key="add">+ Add</Item>
              <Item key="bulkDelete" ><Delete /><Text>Delete</Text></Item>
            </ActionGroup>
          </Flex>

          <Flex width="100%" height="100%" direction="column" gap="size-150">
            <TableView aria-label="spam contacts"
              selectionMode="multiple"
              selectedKeys={selectedTimezones}
              onSelectionChange={setSelectedTimezones}
              renderEmptyState={renderEmptyState}
              minHeight={'45vh'}
              density="spacious"
            >
              <TableHeader columns={columns}>
                {(column) => (
                  <Column key={column.key} align={column.key === 'actions' ? 'end' : 'start'}>
                    {column.name}
                  </Column>
                )}
              </TableHeader>
              <TableBody items={filteredTimezones}>
                {item => (
                  <Row key={item.abbr}>
                    {(columnKey) => getCell(columnKey, item)}
                  </Row>
                )}
              </TableBody>
            </TableView>
          </Flex>
        </Provider>
      </div>


      {errorMessage && (<div
        style={{
          fontSize: 'large', color: 'red', marginTop: '1%',
        }}
        align="center"
      >
        {' '}
        <b> {errorMessage}</b>
      </div>)}

      {isdeleteDialogOpen &&
        <DeleteConfirmation
          isDialogOpened={isdeleteDialogOpen}
          handleCloseDialog={(isDeletedConfirmed) => closeDeleteDialog(isDeletedConfirmed)}
        />
      }

      {isAddDialogOpen &&
        <AddTimezone
          isDialogOpened={isAddDialogOpen}
          editData={editData.current}
          operation={operation}
          handleCloseDialog={(isAddConfirmed, updatedTimezone, oldTimezone) => closeAddTimezoneDialog(isAddConfirmed, updatedTimezone, oldTimezone)}
        />
      }
    </>
  );
}

export default connect(mapStateToProps)(TimezoneManagement);
